// DivisionAdminContent.jsx

import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Dialog } from '@headlessui/react';
import { protectedResources } from '../authConfig';
import {
  ExclamationTriangleIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

export default function DivisionAdminContent({ organizationData, orgId }) {
  const { instance, accounts } = useMsal();

  const [divisions, setDivisions] = useState([]);
  const [selectedSignupCode, setSelectedSignupCode] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState({
    name: 'All',
    division_id: null,
    users: [],
  });
  const [currentDivisionAdmins, setCurrentDivisionAdmins] = useState([]);
  const [nonAdminUsers, setNonAdminUsers] = useState([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Signup code modal state
  const [isSignupCodeModalOpen, setIsSignupCodeModalOpen] = useState(false);
  const [isDeleteSignupCodeModalOpen, setIsDeleteSignupCodeModalOpen] = useState(false);
  const [expiresAtOption, setExpiresAtOption] = useState('30'); // Default to 30 days
  const [codeValue, setCodeValue] = useState('');
  const [signupCodes, setSignupCodes] = useState([]);
  const [isLoadingSignupCodes, setIsLoadingSignupCodes] = useState(false);

  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  // Tab state
  const [activeTab, setActiveTab] = useState('users'); // 'users' or 'signupCodes'

  // Fetch and set divisions
  useEffect(() => {
    const divisionsList = [
      { name: 'All', division_id: null, users: [] },
      ...organizationData.map((division) => ({
        name: division.division,
        division_id: division.division_id, // Ensure division_id exists
        users: division.users,
      })),
    ];
    setDivisions(divisionsList);

    // Set default selected division
    setSelectedDivision(divisionsList[0]);
  }, [organizationData]);

  // Update users and fetch signup codes when selectedDivision changes
  useEffect(() => {
    if (selectedDivision && selectedDivision.division_id !== null) {
      const admins = selectedDivision.users.filter(
        (user) => user.admin_status === 'DIVISION_ADMIN'
      );
      const nonAdmins = selectedDivision.users.filter(
        (user) => user.admin_status !== 'DIVISION_ADMIN'
      );
      setCurrentDivisionAdmins(admins);
      setNonAdminUsers(nonAdmins);
    } else {
      setCurrentDivisionAdmins([]);
      setNonAdminUsers([]);
    }

    if (orgId) {
      // Fetch signup codes for the selected division or organization
      fetchSignupCodes();
    } else {
      console.warn('orgId is undefined; fetchSignupCodes not called.');
    }
  }, [selectedDivision, orgId]);

  // Function to fetch signup codes
  const fetchSignupCodes = async () => {

    setIsLoadingSignupCodes(true);
    if (accounts.length > 0) {
      try {
        const request = {
          scopes: protectedResources.apiGetSignupCodesForEntity.scopes,
          account: accounts[0],
        };
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;
  
        const requestBody = {
          code_type:
            selectedDivision.division_id !== null
              ? 'DIVISION_CODE'
              : 'ORGANIZATION_CODE',
          org_id: orgId,
          division_id: selectedDivision.division_id,
        };
  
        console.log(requestBody);
  
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        };
  
        const response = await fetch(
          protectedResources.apiGetSignupCodesForEntity.endpoint,
          requestOptions
        );
        const data = await response.json();
        setSignupCodes(data.signup_codes);
  
        } catch (error) {
          console.error('Failed to fetch signup codes', error);
        } finally {
          setIsLoadingSignupCodes(false);
        }
      } else {
        setIsLoadingSignupCodes(false);
    }
  };

  const assignDivisionAdmin = async (user) => {
    // Implement the API call to assign division admin role
    console.log('orgId in assignDivisionAdmin:', orgId);
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiAssignDivisionAdmin.scopes,
        account: accounts[0],
      };
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actor_id: user.actor_id,
          division_id: selectedDivision.division_id,
          org_id: orgId,
        }),
      };

      const response = await fetch(
        protectedResources.apiAssignDivisionAdmin.endpoint,
        requestOptions
      );
      if (response.ok) {
        // Update local state
        setCurrentDivisionAdmins([...currentDivisionAdmins, user]);
        setNonAdminUsers(
          nonAdminUsers.filter((u) => u.actor_id !== user.actor_id)
        );
        setIsAssignModalOpen(false);
      } else {
        // Handle error
        console.error('Failed to assign division admin');
      }
    }
  };

  const unassignDivisionAdmin = async (user) => {
    // Implement the API call to assign division admin role
    console.log('orgId in assignDivisionAdmin:', orgId);
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiUnassignDivisionAdmin.scopes,
        account: accounts[0],
      };
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actor_id: user.actor_id,
          org_id: orgId,
        }),
      };

      const response = await fetch(
        protectedResources.apiUnassignDivisionAdmin.endpoint,
        requestOptions
      );
      if (response.ok) {
        // Update local state
        setCurrentDivisionAdmins(
          currentDivisionAdmins.filter((u) => u.actor_id !== user.actor_id)
        );
        setNonAdminUsers([...nonAdminUsers, user]);
        setIsUnassignModalOpen(false);
      } else {
        // Handle error
        console.error('Failed to unassign division admin');
      }
    }
  };

  const createSignupCode = async () => {
    // Implement the API call to create a signup code
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiCreateSignupCode.scopes,
        account: accounts[0],
      };
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      // Calculate expires_at
      let expiresAtISO = null;
      if (expiresAtOption !== 'never') {
        const daysToAdd = parseInt(expiresAtOption, 10);
        const expiresAtDate = new Date();
        expiresAtDate.setDate(expiresAtDate.getDate() + daysToAdd);
        expiresAtISO = expiresAtDate.toISOString();
      }

      console.log("orgId in createSignupCode:", orgId);

      const requestBody = {
        org_id: orgId,
        division_id:
          selectedDivision.division_id !== null
            ? selectedDivision.division_id
            : null,
        expires_at: expiresAtISO,
        code_value: codeValue || null,
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        protectedResources.apiCreateSignupCode.endpoint,
        requestOptions
      );
      if (response.ok) {
        // Handle success
        setIsSignupCodeModalOpen(false);
        // Refresh the signup codes list
        fetchSignupCodes();
        // Optionally, display a success message or the created code
      } else {
        // Handle error
        console.error('Failed to create signup code');
      }
    }
  };

  const deleteSignupCode = async (codeValue) => {
    // Implement the API call to create a signup code
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiDeleteSignupCode.scopes,
        account: accounts[0],
      };
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestBody = {
        code_value: codeValue,
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        protectedResources.apiDeleteSignupCode.endpoint,
        requestOptions
      );
      if (response.ok) {
        // Handle success
        setIsDeleteSignupCodeModalOpen(false);
        // Refresh the signup codes list
        fetchSignupCodes();
        // Optionally, display a success message or the created code
      } else {
        // Handle error
        console.error('Failed to create signup code');
      }
    }
  };

  return (
    <div className="w-full max-w-6xl p-6">
      <div className="mb-6">
        <h2 className="text-2xl font-semibold">Division Admin</h2>
      </div>

      {/* Divisions List */}
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-2">Divisions</h3>
        <div className="flex flex-wrap gap-2">
          {divisions.map((division) => (
            <button
              key={division.division_id}
              onClick={() => setSelectedDivision(division)}
              className={`px-4 py-2 rounded-md ${
                selectedDivision?.division_id === division.division_id
                  ? 'bg-indigo-900 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-indigo-700 hover:text-white'
              }`}
            >
              {division.name}
            </button>
          ))}
        </div>
      </div>

      {/* Tabs */}
      <div className="mb-4">
        <div className="flex border-b border-gray-200">
          <button
            onClick={() => setActiveTab('users')}
            className={`px-4 py-2 -mb-px text-sm font-medium border-b-2 ${
              activeTab === 'users'
                ? 'border-indigo-800 text-indigo-900'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Users & Admins
          </button>
          <button
            onClick={() => setActiveTab('signupCodes')}
            className={`ml-4 px-4 py-2 -mb-px text-sm font-medium border-b-2 ${
              activeTab === 'signupCodes'
                ? 'border-indigo-800 text-indigo-900'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Signup Codes
          </button>
        </div>
      </div>

      {activeTab === 'users' && (
        <>
          {/* Users in Selected Division */}
          {selectedDivision && selectedDivision.division_id !== null ? (
            <div>
              <h3 className="text-xl font-semibold mb-6">
                Users in {selectedDivision.name}
              </h3>

              {/* Current Division Admins */}
              {currentDivisionAdmins.length > 0 && (
                <div className="mb-8">
                  <h4 className="text-lg font-semibold mb-2">
                    Current Division Admins
                  </h4>
                  <table className="min-w-full bg-white border border-gray-200 rounded-md">
                    <thead>
                      <tr>
                        <th className="py-3 px-4 text-left bg-gray-50">Name</th>
                        <th className="py-3 px-4 text-left bg-gray-50">Email</th>
                        <th className="py-3 px-4 bg-gray-50"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDivisionAdmins.map((admin, idx) => (
                        <tr
                          key={admin.actor_id}
                          className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        >
                          <td className="py-3 px-4">{admin.full_name}</td>
                          <td className="py-3 px-4">{admin.email}</td>
                          <td className="py-3 px-4 text-right">
                            <button
                              onClick={() => {
                                setSelectedAdmin(admin);
                                setIsUnassignModalOpen(true);
                              }}
                              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                            >
                              Remove Admin
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Non-Admin Users */}
              <div>
                <h4 className="text-lg font-semibold mb-2">Users</h4>
                <table className="min-w-full bg-white border border-gray-200 rounded-md">
                  <thead>
                    <tr>
                      <th className="py-3 px-4 text-left bg-gray-50">Name</th>
                      <th className="py-3 px-4 text-left bg-gray-50">Email</th>
                      <th className="py-3 px-4 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {nonAdminUsers.map((user, idx) => (
                      <tr
                        key={user.actor_id}
                        className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        <td className="py-3 px-4">{user.full_name}</td>
                        <td className="py-3 px-4">{user.email}</td>
                        <td className="py-3 px-4 text-right">
                          <button
                            onClick={() => {
                              setSelectedUser(user);
                              setIsAssignModalOpen(true);
                            }}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                          >
                            Assign as Admin
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {nonAdminUsers.length === 0 && (
                  <div className="mt-4 flex items-center text-gray-500">
                    <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
                    <span>No users available to assign.</span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <h3 className="text-xl font-semibold mb-6">No Division Selected</h3>
              <p className="text-gray-700">
                Please select a division to view and manage its users.
              </p>
            </div>
          )}

          {/* Assign Division Admin Modal */}
          <Dialog
            open={isAssignModalOpen}
            onClose={() => setIsAssignModalOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
                <Dialog.Title className="text-lg font-semibold mb-4">
                  Confirm Assignment
                </Dialog.Title>
                <p>
                  Are you sure you want to assign{' '}
                  <span className="font-medium">{selectedUser?.full_name}</span> as
                  a Division Admin?
                </p>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setIsAssignModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => assignDivisionAdmin(selectedUser)}
                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          {/* Unassign Division Admin Modal */}
          <Dialog
            open={isUnassignModalOpen}
            onClose={() => setIsUnassignModalOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
                <Dialog.Title className="text-lg font-semibold mb-4">
                  Confirm Removal
                </Dialog.Title>
                <p>
                  Are you sure you want to remove{' '}
                  <span className="font-medium">{selectedAdmin?.full_name}</span> as
                  a Division Admin?
                </p>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setIsUnassignModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => unassignDivisionAdmin(selectedAdmin)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Remove Admin
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </>
      )}

      {activeTab === 'signupCodes' && (
        <div>
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-semibold">
              Signup Codes for{' '}
              {selectedDivision.division_id !== null
                ? selectedDivision.name
                : 'Entire Organization'}
            </h3>
            <button
              onClick={() => setIsSignupCodeModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Create Signup Code
            </button>
          </div>
          

          {isLoadingSignupCodes ? (
            <div className="loadingOverlay">
                <div className="spinner"></div>
                <div className="loadingMessage">Loading signup codes...</div>
            </div>
          ) : (   
          <>
          {/* Signup Codes Table */}
          <table className="min-w-full bg-white border border-gray-200 rounded-md">
            <thead>
              <tr>
                <th className="py-3 px-4 text-left bg-gray-50">Code</th>
                <th className="py-3 px-4 text-left bg-gray-50">Created At</th>
                <th className="py-3 px-4 text-left bg-gray-50">Expires At</th>
                <th className="py-3 px-4 text-left bg-gray-50">Num Uses</th>
                <th className="py-3 px-4 text-left bg-gray-50">Status</th>
              </tr>
            </thead>
            <tbody>
              {signupCodes.map((code, idx) => (
                <tr
                  key={code.code}
                  className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td className="py-3 px-4">{code.code}</td>
                  <td className="py-3 px-4">
                    {new Date(code.created_at).toLocaleString()}
                  </td>
                  <td className="py-3 px-4">
                    {code.expires_at
                      ? new Date(code.expires_at).toLocaleString()
                      : 'Never'}
                  </td>
                  <td className="py-3 px-4">{code.num_uses}</td>
                  <td className="py-3 px-4">
                    {code.is_expired ? 'Expired' : 'Active'}
                  </td>
                  <td className="py-3 px-4 text-right">
                    <button
                      onClick={() => {
                        setSelectedSignupCode(code);
                        setIsDeleteSignupCodeModalOpen(true);
                    }}
                      className="text-red-600 hover:text-red-800"
                    >
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Delete code</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {signupCodes.length === 0 && (
            <div className="mt-4 flex items-center text-gray-500">
              <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
              <span>No signup codes available.</span>
            </div>
          )}
          </>
          )}

          {/* Create Signup Code Modal */}
          <Dialog
            open={isSignupCodeModalOpen}
            onClose={() => setIsSignupCodeModalOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-md rounded bg-white p-6">
                <Dialog.Title className="text-lg font-semibold mb-4">
                  Create Signup Code
                </Dialog.Title>
                <p className="mb-4">
                  You are creating a signup code for{' '}
                  <span className="font-medium">
                    {selectedDivision.division_id !== null
                      ? `Division: ${selectedDivision.name}`
                      : 'Entire Organization'}
                  </span>
                  .
                </p>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Expires In
                    </label>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <input
                          id="30days"
                          name="expires_at"
                          type="radio"
                          value="30"
                          checked={expiresAtOption === '30'}
                          onChange={() => setExpiresAtOption('30')}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="30days"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          30 Days
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="60days"
                          name="expires_at"
                          type="radio"
                          value="60"
                          checked={expiresAtOption === '60'}
                          onChange={() => setExpiresAtOption('60')}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="60days"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          60 Days
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="90days"
                          name="expires_at"
                          type="radio"
                          value="90"
                          checked={expiresAtOption === '90'}
                          onChange={() => setExpiresAtOption('90')}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="90days"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          90 Days
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="never"
                          name="expires_at"
                          type="radio"
                          value="never"
                          checked={expiresAtOption === 'never'}
                          onChange={() => setExpiresAtOption('never')}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="never"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Never
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Code Value (Optional)
                    </label>
                    <input
                      type="text"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={codeValue}
                      onChange={(e) => setCodeValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setIsSignupCodeModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={createSignupCode}
                    className="px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
                  >
                    Create Code
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          {/* Delete Signup Code Modal */}
          <Dialog
            open={isDeleteSignupCodeModalOpen}
            onClose={() => setIsDeleteSignupCodeModalOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
                <Dialog.Title className="text-lg font-semibold mb-4">
                  Confirm Removal
                </Dialog.Title>
                <p>
                  Are you sure you want to delete this code?
                </p>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteSignupCodeModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteSignupCode(selectedSignupCode.code)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Delete Code
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>  
        </div>
      )}
    </div>
  );
}
