// components/ActionIcon.jsx  

import React from 'react';
import {
    BellAlertIcon,
    ChatBubbleLeftEllipsisIcon,
    ExclamationTriangleIcon,
    ShieldExclamationIcon,
    PencilSquareIcon,
    CommandLineIcon,
    UserIcon,
    EnvelopeIcon,
    ChartBarIcon,
} from '@heroicons/react/24/outline';

const ActionIcon = ({ action }) => {
    let icon;
    let colorClass;

    switch (action) {
        case 'push_notification':
            icon = <BellAlertIcon className="h-5 w-5" />;
            colorClass = 'text-blue-500';
            break;
        case 'highlighted_insertion':
            icon = <PencilSquareIcon className="h-5 w-5" />;
            colorClass = 'text-purple-500';
            break;
        case 'macro':
            icon = <CommandLineIcon className="h-5 w-5" />;
            colorClass = 'text-indigo-500';
            break;
        case 'macro_with_user_input':
            icon = <UserIcon className="h-5 w-5" />;
            colorClass = 'text-pink-500';
            break;
        case 'message':
            icon = <EnvelopeIcon className="h-5 w-5" />;
            colorClass = 'text-orange-500';
            break;
        case 'risk_score':
            icon = <ChartBarIcon className="h-5 w-5" />;
            colorClass = 'text-teal-500';
            break;
        default:
            icon = <PencilSquareIcon className="h-5 w-5" />;
            colorClass = 'text-gray-500';
    }

    return <div className={`${colorClass}`}>{icon}</div>;
};

export default ActionIcon;  