import { protectedResources } from '../authConfig';

// Function to save custom MDM template
export const saveCustomMdmTemplate = async (instance, accounts, selectedGroupId, templateContent) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiSetCustomMdmTemplate.scopes,
      account: accounts[0],
    };

    const response = await instance.acquireTokenSilent(request);
    const bearer = `Bearer ${response.accessToken}`;

    const options = {
      method: 'POST',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group_id: parseInt(selectedGroupId, 10),
        template_content: templateContent,
      }),
    };

    const endpoint = protectedResources.apiSetCustomMdmTemplate.endpoint;
    const resp = await fetch(endpoint, options);

    if (!resp.ok) {
      throw new Error(`HTTP error! status: ${resp.status}`);
    }

    const data = await resp.json();
    return data;
  } catch (error) {
    console.error('saveCustomMdmTemplate error:', error);
    throw error;
  }
};
