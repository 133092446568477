// GlobalAdminDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { protectedResources } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import GlobalUserManagement from '../components/GlobalUserManagement';
import GlobalKPIs from '../components/GlobalKPIs';
import { UserIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const GlobalAdminDashboard = () => {
  const { instance, accounts } = useMsal();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedOrgName, setSelectedOrgName] = useState(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);

  const [isUserManagementModalOpen, setIsUserManagementModalOpen] = useState(false);
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false); // Add state for KPIs modal
  const [notification, setNotification] = useState(null);


  const handleSelect = (org_id, org_name) => {
    setSelectedOrg(org_id);
    setSelectedOrgName(org_name);
    navigate(`/organization-admin-dashboard`, { state: { org_id: org_id, org_name: org_name } });
  };

  const fetchOrgs = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiListOrgs.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        protectedResources.apiListOrgs.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();

      // Sort organizations alphabetically by org_name
      const sortedOrganizations = data.orgs.sort((a, b) =>
        a.org_name.localeCompare(b.org_name)
      );
      setOrganizations(sortedOrganizations);
    }
  };

  useEffect(() => {
    fetchOrgs();
  }, []);

  // Notification effect
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex items-center justify-center mt-24">
        <div className="p-8 rounded-lg shadow-md text-center bg-gray-200">
          <h1 className="text-2xl font-bold mb-6">Global Admin Dashboard</h1>
          <div className="mb-6">
            <select
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                handleSelect(selectedOption.value, selectedOption.text);
              }}
              value={selectedOrg || ''}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select an organization</option>
              {organizations.map(org => (
                <option key={org.org_id} value={org.org_id}>
                  {org.org_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setIsUserManagementModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <UserIcon className="h-5 w-5 mr-2" />
              User Management
            </button>
            <button
              onClick={() => setIsKPIModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <ChartBarIcon className="h-5 w-5 mr-2" />
              View Global KPIs
            </button>
          </div>
        </div>
      </div>
      {/* Notification */}
      {notification && (
        <div
          className={`fixed bottom-4 left-4 bg-${notification.type === 'success' ? 'green' : 'red'
            }-500 text-white px-4 py-2 rounded-md`}
        >
          {notification.message}
        </div>
      )}
      {/* Global User Management Modal */}
      <GlobalUserManagement
        isOpen={isUserManagementModalOpen}
        onClose={() => setIsUserManagementModalOpen(false)}
        setNotification={setNotification}
      />

      {/* Global KPIs Modal */}
      <GlobalKPIs
        isOpen={isKPIModalOpen}
        onClose={() => setIsKPIModalOpen(false)}
      />
    </div>
  );
};

export default GlobalAdminDashboard;
