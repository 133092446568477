import React from 'react';
import TipTapEditor from './TipTap';

import { TrashIcon } from '@heroicons/react/24/outline';

export default function MdmTabContent({
  content,
  setContent,
  charCount,
  setCharCount,
  label,
  maxChars,
  onRemoveExample,
}) {
  // Helper function to calculate text length
  const getTextContentLength = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent || '';
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length > maxChars ? maxChars : textContent.length;
  };

  const handleContentChange = (updatedContent) => {
    setContent(updatedContent);
    setCharCount(getTextContentLength(updatedContent));
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {label}
        </h2>
        {onRemoveExample && (
          <button
            onClick={onRemoveExample}
            className="text-gray-500 hover:text-red-600 focus:outline-none"
            title="Remove Example"
            aria-label="Remove Example"
          >
            <TrashIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        )}
      </div>
      <p className="text-sm text-gray-500 mb-2">
        {label === 'MDM Template'
          ? 'Enter your MDM template below.'
          : 'Provide an example of how your MDM should look.'}
      </p>
      <div className="mt-2 mb-4">
        <div className="relative">
          <div className="rounded-lg shadow-sm ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <div className="p-4">
              <TipTapEditor
                mdm={content}
                updateMDM={handleContentChange}
                saveDocument={() => {}}
              />
            </div>
          </div>
          <div className="mt-2 flex justify-end">
            <span className="text-sm text-gray-500">
              {charCount}/{maxChars}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
} 