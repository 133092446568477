// src/pages/Subscribe.js

import React from "react";
import { useLocation } from "react-router-dom";
import { Fieldset, Legend, FieldGroup } from "../components/catalyst/fieldset";
import { Button } from "../components/catalyst/button";
import { useNavigate } from "react-router-dom";

const Subscribe = () => {
  const location = useLocation();
  const actorInfo = location.state?.actorInfo;
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="max-w-lg w-full bg-white shadow-lg rounded-lg pl-12 pr-12 pt-8">
        <div className="mb-12">
          <img
            src="/cleo-logo.png"
            alt="Cleo Logo"
            style={{ maxWidth: "30px", marginBottom: "20px" }}
          />
          <Fieldset>
            <Legend className="text-black pb-2" style={{ fontSize: "24px" }}>
              {actorInfo.subscription_type === 'FREE_TRIAL' 
                ? 'Upgrade your Subscription' 
                : actorInfo.subscription_type === 'NO_SUBSCRIPTION' 
                ? 'Account Inactive' 
                : 'Thanks for using Cleo!'}
            </Legend>
            <FieldGroup className="flex flex-col items-center">
              <p className="text-black mb-4 text-center">
                {actorInfo.subscription_type === 'FREE_TRIAL' 
                  ? "Hope you've enjoyed using Cleo so far! Click the button below to upgrade your subscription."
                  : actorInfo.subscription_type === 'NO_SUBSCRIPTION' 
                  ? "Your account has become inactive! Click the button below to subscribe to Cleo Health."
                  : "You're already a paid user of Cleo. We appreciate you! Email support@cleo-ai.co with any questions or comments on your experience with the app so far."}
              </p>
              <a
                href={actorInfo.subscription_type === 'FREE_TRIAL' || actorInfo.subscription_type === 'NO_SUBSCRIPTION' 
                  ? actorInfo.stripe_portal_url 
                  : ''}
                target={actorInfo.subscription_type === 'FREE_TRIAL' || actorInfo.subscription_type === 'NO_SUBSCRIPTION' 
                  ? "_blank" 
                  : "_self"}
                rel="noopener noreferrer"
                className="self-center mt-8 w-3/4 bg-indigo-900 text-white text-center py-2 px-4 rounded"
                style={{ textDecoration: 'none' }}
                onClick={actorInfo.subscription_type !== 'FREE_TRIAL' && actorInfo.subscription_type !== 'NO_SUBSCRIPTION' 
                  ? () => navigate('/er-dashboard') 
                  : null}
              >
                {actorInfo.subscription_type === 'FREE_TRIAL' || actorInfo.subscription_type === 'NO_SUBSCRIPTION' 
                  ? "Upgrade Your Subscription" 
                  : "Back to Dashboard"}
              </a>
            </FieldGroup>
          </Fieldset>
        </div>
        <p className="text-center text-black mb-4">
          Click here to view our
          <a
            className="text-indigo-900"
            href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Terms of Use{" "}
          </a>
          and
          <a
            className="text-indigo-900"
            href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Subscribe;