// interventionApi.js  

import { protectedResources } from '../authConfig';
import { callApiWithToken } from './apiUtils';

// Get interventions for a group  
export const getGroupInterventions = async (instance, accounts, groupId) => {
    if (!groupId) {
        throw new Error('Group ID is required');
    }

    const endpoint = protectedResources.apiGetGroupInterventions.endpoint;
    const scopes = protectedResources.apiGetGroupInterventions.scopes;
    const body = { group_id: groupId };

    return callApiWithToken(instance, accounts, endpoint, 'POST', body, scopes);
};

// Upsert an intervention  
export const upsertGroupIntervention = async (instance, accounts, interventionData) => {
    const endpoint = protectedResources.apiUpsertGroupIntervention.endpoint;
    const scopes = protectedResources.apiUpsertGroupIntervention.scopes;
    return callApiWithToken(instance, accounts, endpoint, 'POST', interventionData, scopes);
};

// Delete an intervention  
export const deleteIntervention = async (instance, accounts, interventionId) => {
    if (!interventionId) {
        throw new Error('Intervention ID is required');
    }
    const endpoint = protectedResources.apiDeleteIntervention.endpoint;
    const scopes = protectedResources.apiDeleteIntervention.scopes;
    const body = { intervention_id: interventionId };
    return callApiWithToken(instance, accounts, endpoint, 'POST', body, scopes);
};  