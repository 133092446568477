// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-organizationchart .p-organizationchart-line-down {
    border-left: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-right {
    border-left: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

/* Center the caret icon within the toggler circle */
.p-organizationchart .p-node-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Optional: Adjust the size of the toggler circle if needed */
.p-organizationchart .p-node-toggler {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

/* Optional: Adjust the size of the caret icon if needed */
.p-organizationchart .p-node-toggler .pi {
    font-size: 1rem;
    /* Adjust the font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/styles/OrganizationStructure.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,+BAA+B;IAC/B,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA,oDAAoD;AACpD;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA,8DAA8D;AAC9D;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA,0DAA0D;AAC1D;IACI,eAAe;IACf,mCAAmC;AACvC","sourcesContent":[".p-organizationchart .p-organizationchart-line-down {\n    border-left: 1px solid #e5e7eb;\n    border-color: #e5e7eb;\n}\n\n.p-organizationchart .p-organizationchart-line-left {\n    border-right: 1px solid #e5e7eb;\n    border-color: #e5e7eb;\n}\n\n.p-organizationchart .p-organizationchart-line-right {\n    border-left: 1px solid #e5e7eb;\n    border-color: #e5e7eb;\n}\n\n.p-organizationchart .p-organizationchart-line-top {\n    border-top: 1px solid #e5e7eb;\n    border-color: #e5e7eb;\n}\n\n/* Center the caret icon within the toggler circle */\n.p-organizationchart .p-node-toggler {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n/* Optional: Adjust the size of the toggler circle if needed */\n.p-organizationchart .p-node-toggler {\n    width: 24px;\n    height: 24px;\n    border-radius: 50%;\n}\n\n/* Optional: Adjust the size of the caret icon if needed */\n.p-organizationchart .p-node-toggler .pi {\n    font-size: 1rem;\n    /* Adjust the font size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
