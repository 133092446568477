// src/components/TransferPatientModal.jsx

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faChevronRight,
    faCheckCircle,
    faExclamationTriangle,
    faSpinner,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

function TransferPatientModal({
    isOpen,
    onClose,
    stay,
    divisionActors,
    isLoading,
    instance,
    accounts,
    protectedResources,
    navigate,
}) {
    // State variables
    const [selectedActor, setSelectedActor] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [transferError, setTransferError] = useState(null);
    const [summaryContent, setSummaryContent] = useState('');
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [isSavingSummary, setIsSavingSummary] = useState(false);
    const [summaryError, setSummaryError] = useState(null);
    const [summarySuccessMessage, setSummarySuccessMessage] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Fetch existing summary when modal opens
    useEffect(() => {
        if (isOpen) {
            // Reset states when modal opens
            resetStates();
            setSummaryContent(stay?.transfer_summary || '');
            // fetchExistingSummary();
        }
    }, [isOpen]);

    // Function to reset states
    const resetStates = () => {
        setSelectedActor(null);
        setIsProcessing(false);
        setTransferError(null);
        setSummaryContent('');
        setIsSummaryLoading(false);
        setIsSavingSummary(false);
        setSummaryError(null);
        setSummarySuccessMessage(null);
        setHasUnsavedChanges(false);
    };

    // Function to fetch existing transfer summary
    // const fetchExistingSummary = async () => {
    //     if (accounts.length > 0 && stay) {
    //         try {
    //             const request = {
    //                 scopes: protectedResources.apiGetTransferSummary.scopes,
    //                 account: accounts[0],
    //             };

    //             const response = await instance.acquireTokenSilent(request);
    //             const token = response.accessToken;

    //             const requestOptions = {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             };

    //             const endpoint = `${protectedResources.apiGetTransferSummary.endpoint}?inpatient_stay_id=${stay.inpatient_id}`;

    //             const fetchResponse = await fetch(endpoint, requestOptions);
    //             const data = await fetchResponse.json();

    //             if (fetchResponse.ok && data.transfer_summary) {
    //                 setSummaryContent(data.transfer_summary);
    //                 // No unsaved changes since we just loaded the saved summary
    //                 setHasUnsavedChanges(false);
    //             } else {
    //                 // No existing summary
    //                 setSummaryContent('');
    //                 setHasUnsavedChanges(false);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching transfer summary:', error);
    //             setSummaryError('Error fetching existing transfer summary.');
    //         }
    //     }
    // };

    // Handle modal close with unsaved changes check
    const handleClose = () => {
        if (hasUnsavedChanges) {
            const confirmClose = window.confirm('You have unsaved changes. Are you sure you want to close?');
            if (!confirmClose) {
                return;
            }
        }
        onClose();
    };

    // Handle AI summary generation
    const handleGenerateAISummary = async () => {
        setIsSummaryLoading(true);
        setSummaryError(null);
        try {
            const request = {
                scopes: protectedResources.apiGenerateTransferSummary.scopes,
                account: accounts[0],
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inpatient_stay_id: stay.inpatient_id,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }),
            };

            const fetchResponse = await fetch(protectedResources.apiGenerateTransferSummary.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (fetchResponse.ok && data.summary) {
                setSummaryContent(data.summary);
                setHasUnsavedChanges(true);
            } else {
                throw new Error(data.detail || 'Failed to generate AI summary.');
            }
        } catch (error) {
            console.error('Error generating AI summary:', error);
            setSummaryError(error.message || 'An error occurred while generating the AI summary.');
        } finally {
            setIsSummaryLoading(false);
        }
    };

    // Handle saving the summary
    const handleSaveSummary = async () => {
        if (!summaryContent.trim()) {
            setSummaryError('Transfer summary cannot be empty.');
            return;
        }
        setIsSavingSummary(true);
        setSummaryError(null);
        try {
            const request = {
                scopes: protectedResources.apiSetTransferSummary.scopes,
                account: accounts[0],
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inpatient_stay_id: stay.inpatient_id,
                    transfer_summary: summaryContent,
                }),
            };

            const fetchResponse = await fetch(protectedResources.apiSetTransferSummary.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (fetchResponse.ok && data.success) {
                setSummarySuccessMessage('Transfer summary saved successfully.');
                setHasUnsavedChanges(false);
            } else {
                throw new Error(data.detail || 'Failed to save transfer summary.');
            }
        } catch (error) {
            console.error('Error saving transfer summary:', error);
            setSummaryError(error.message || 'An error occurred while saving the transfer summary.');
        } finally {
            setIsSavingSummary(false);
        }
    };

    // Handle deleting the summary
    const handleDeleteSummary = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete the transfer summary?');
        if (!confirmDelete) {
            return;
        }
        setSummaryContent('');
        setHasUnsavedChanges(false);
        setSummarySuccessMessage('Transfer summary deleted.');

    };

    // Handle transferring the patient
    const handleTransferPatient = async () => {
        if (hasUnsavedChanges) {
            alert('Please save or delete the transfer summary before transferring the patient.');
            return;
        }
        if (!selectedActor) {
            setTransferError('Please select a provider to transfer the patient.');
            return;
        }
        setIsProcessing(true);
        setTransferError(null);
        try {
            const request = {
                scopes: protectedResources.apiTransferPatient.scopes,
                account: accounts[0],
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inpatient_stay_id: stay.inpatient_id,
                    new_provider_id: selectedActor.actor_id,
                }),
            };

            const fetchResponse = await fetch(protectedResources.apiTransferPatient.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (!fetchResponse.ok || !data.success) {
                throw new Error(data.detail || 'Failed to transfer patient.');
            }

            // Transfer successful
            alert('Patient transferred successfully.');
            onClose();
        } catch (error) {
            console.error('Error transferring patient:', error);
            setTransferError(error.message || 'An error occurred while transferring the patient.');
        } finally {
            setIsProcessing(false);
        }
    };

    // Handle summary content change
    const handleSummaryChange = (e) => {
        setSummaryContent(e.target.value);
        setHasUnsavedChanges(true);
    };

    return (
        isOpen && (
            <div
                className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
                role="dialog"
                aria-modal="true"
            >
                <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                    {/* Modal Header */}
                    <div className="flex justify-between items-center p-4 border-b">
                        <h2 className="text-2xl font-semibold text-indigo-900">Generate Summary / Transfer Patient</h2>
                        <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                        </button>
                    </div>

                    {/* Modal Content */}
                    <div className="p-4">
                        {/* Transfer Summary Section */}
                        <div className="mb-4">
                            <h3 className="text-lg font-medium text-gray-800">Transfer Summary for {stay?.patient_name}:</h3>
                            <textarea
                                className={`w-full p-4 border rounded-md focus:outline-none ${isSummaryLoading ? 'bg-gray-100 cursor-not-allowed' : 'focus:ring-2 focus:ring-indigo-500'
                                    } text-gray-800`}
                                style={{ height: '16rem' }}
                                placeholder="Enter transfer summary here..."
                                value={summaryContent}
                                onChange={handleSummaryChange}
                                disabled={isSummaryLoading}
                            />
                            <div className="flex justify-between items-center mt-4">
                                <div className="flex space-x-2">
                                    <button
                                        onClick={handleGenerateAISummary}
                                        className={`px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition flex items-center ${isSummaryLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={isSummaryLoading}
                                    >
                                        {isSummaryLoading ? (
                                            <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                        ) : (
                                            <></>
                                        )}
                                        {summaryContent ? 'Regenerate AI Summary' : 'Generate AI Summary'}
                                    </button>
                                    {summaryContent && (
                                        <button
                                            onClick={handleDeleteSummary}
                                            className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition flex items-center ${isSavingSummary ? 'opacity-50 cursor-not-allowed' : ''
                                                }`}
                                            disabled={isSavingSummary}
                                        >
                                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                            Delete Summary
                                        </button>
                                    )}
                                </div>
                                <button
                                    onClick={handleSaveSummary}
                                    className={`px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition flex items-center ${isSavingSummary ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    disabled={isSavingSummary}
                                >
                                    {isSavingSummary ? (
                                        <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                    ) : (
                                        <></>
                                    )}
                                    Save Summary
                                </button>
                            </div>
                            {/* Display summary error or success messages */}
                            {summaryError && (
                                <div className="flex items-center text-red-500 mt-4">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                    <span>{summaryError}</span>
                                </div>
                            )}
                            {summarySuccessMessage && (
                                <div className="flex items-center text-green-600 mt-4">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                    <span>{summarySuccessMessage}</span>
                                </div>
                            )}
                        </div>

                        {/* Provider Selection Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-800 mb-4">Select a Provider to Transfer To:</h3>
                            {isLoading ? (
                                <p className="text-gray-500">Loading providers...</p>
                            ) : divisionActors.length === 0 ? (
                                <p className="text-red-500">No other providers available</p>
                            ) : (
                                <div className="space-y-2 max-h-64 overflow-y-auto">
                                    {divisionActors.map((actor) => (
                                        <button
                                            key={actor.actor_id}
                                            className={`w-full flex justify-between items-center p-2 border rounded-md ${selectedActor?.actor_id === actor.actor_id
                                                ? 'bg-indigo-100 border-indigo-500'
                                                : 'hover:bg-gray-100'
                                                }`}
                                            onClick={() => {
                                                setSelectedActor(actor);
                                                setTransferError(null);
                                            }}
                                        >
                                            <div>
                                                <p className="font-semibold text-gray-800">{actor.full_name}</p>
                                                <p className="text-sm text-gray-500">{actor.email}</p>
                                            </div>
                                            <FontAwesomeIcon icon={faChevronRight} className="text-indigo-500" />
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Display transfer error message */}
                        {transferError && (
                            <div className="flex items-center text-red-500 mb-4">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                <span>{transferError}</span>
                            </div>
                        )}
                        {hasUnsavedChanges && (
                            <p className="text-sm text-red-500 mt-2">
                                Please save or delete the transfer summary before transferring the patient.
                            </p>
                        )}

                        {/* Transfer Button */}
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleClose}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition"
                                disabled={isProcessing}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleTransferPatient}
                                className={`px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition flex items-center ${isProcessing || hasUnsavedChanges ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                disabled={isProcessing || hasUnsavedChanges}
                                title={
                                    hasUnsavedChanges
                                        ? 'Please save or delete the transfer summary before transferring the patient.'
                                        : ''
                                }
                            >
                                {isProcessing ? (
                                    <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                ) : (
                                    <></>
                                )}
                                Transfer Patient
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default TransferPatientModal;
