import React, { useState } from "react";
import { protectedResources } from "../authConfig";

import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { diffWords } from "diff";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

function AdminDashboardInner() {
  const { instance, accounts } = useMsal();
  const [orgName, setOrgName] = useState("");
  const [orgSpecialty, setOrgSpecialty] = useState("");
  const [ehrName, setEhrName] = useState("");
  const [orgEhrId, setOrgEhrId] = useState("");
  const [fullName, setFullName] = useState("");
  const [actorOrgId, setActorOrgId] = useState("");
  //const [orgId, setOrgId] = useState('');
  const [actorEhrId, setActorEhrId] = useState("");
  const [response, setResponse] = useState(null);
  const [b2cUserId, setB2cUserId] = useState("");
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [singleEmail, setSingleEmail] = useState("");
  const [singleOrgId, setSingleOrgId] = useState("");
  const [singleDivision, setSingleDivision] = useState("");
  const [singleSignUpWave, setSingleSignUpWave] = useState("");
  const [singleFirstName, setSingleFirstName] = useState("");
  const [singleLastName, setSingleLastName] = useState("");

  const [email, setEmail] = useState("");
  const [addOrgId, setAddOrgId] = useState("");

  const [actors, setActors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedActor, setSelectedActor] = useState(null);
  const [macros, setMacros] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [actorsFetched, setActorsFetched] = useState(false);
  const [expandedDocument, setExpandedDocument] = useState(null);
  const [showModifiedOnly, setShowModifiedOnly] = useState(false);
  const [showWithFeedbackOnly, setShowWithFeedbackOnly] = useState(false);
  const [showWithMDMOnly, setShowWithMDMOnly] = useState(false);
  const [documentsWithRating, setDocumentsWithRating] = useState([]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 864e5).toISOString().split('T')[0]); // 864e5 is the number of milliseconds in a day
  const [selectedRating, setSelectedRating] = useState("all");
  const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const addUserToOrg = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiAddUserToOrg.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: email, org_id: addOrgId }),
      };

      const fetchResponse = await fetch(
        protectedResources.apiAddUserToOrg.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();
      setResponse(data);
    }
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const text = e.target.result;
      const data = text.split("\n").map((row) => {
        const [email, org_id] = row.split(",");
        return { email: email.trim(), org_id: parseInt(org_id.trim(), 10) };
      });

      // Filter out any rows that don't parse correctly
      const validData = data.filter(
        (entry) => entry.email && !isNaN(entry.org_id)
      );

      setFile(validData);
    };

    reader.readAsText(file);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log(
        `Selected file: ${selectedFile.name}, Type: ${selectedFile.type}, Size: ${selectedFile.size} bytes`
      );
      setFile(selectedFile); // Directly store the File object
    } else {
      console.log("No file selected.");
    }
  };

  const uploadEmails = async () => {
    if (!file) {
      console.error("Upload attempt without a file.");
      alert("Please select a file first!");
      return;
    }

    if (accounts.length === 0) {
      console.error("No accounts available for authentication.");
      alert("No authenticated account found.");
      return;
    }

    setUploading(true); // Start uploading
    const request = {
      scopes: protectedResources.apiUploadEmails.scopes,
      account: accounts[0],
    };

    try {
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const formData = new FormData();
      formData.append("file", file);

      // Logging FormData for debugging
      console.log("FormData Contents:");
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value.name}, size: ${value.size}`);
      }

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type' is not manually set to allow browser to handle multipart boundary
        },
        body: formData,
      };

      // Log request options without sensitive data
      console.log("Request headers:", requestOptions.headers);
      console.log(
        "Request endpoint:",
        protectedResources.apiUploadEmails.endpoint
      );

      const fetchResponse = await fetch(
        protectedResources.apiUploadEmails.endpoint,
        requestOptions
      );
      console.log("HTTP Response Status:", fetchResponse.status);

      if (!fetchResponse.ok) {
        const responseText = await fetchResponse.text();
        console.error("Upload failed:", responseText);
        throw new Error("Upload failed: " + responseText);
      }

      const data = await fetchResponse.json();
      setResponse(data);
      console.log("Upload successful:", data);
    } catch (error) {
      console.error("Failed to upload file:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setUploading(false); // End uploading
    }
  };

  const registerSingleEmail = async () => {
    if (!singleEmail || !singleOrgId || !singleFirstName || !singleLastName) {
      alert(
        "Please ensure both email, organization ID, first name, and last name are provided."
      );
      return;
    }

    if (accounts.length === 0) {
      alert("No authenticated account found.");
      return;
    }

    // Set up the authorization request for MSAL
    const request = {
      scopes: protectedResources.apiUploadSingleEmail.scopes, // Ensure this scope is configured in your auth settings
      account: accounts[0], // Assumes the user's first account is the appropriate one
    };

    try {
      // Acquiring token silently
      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      // Preparing the HTTP request headers and body
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: singleEmail,
          org_id: parseInt(singleOrgId, 10),
          division: singleDivision,
          sign_up_wave: singleSignUpWave,
          first_name: singleFirstName,
          last_name: singleLastName,
        }),
      };

      // Executing the fetch API call to the backend endpoint
      const fetchResponse = await fetch(
        protectedResources.apiUploadSingleEmail.endpoint,
        requestOptions
      );

      if (!fetchResponse.ok) {
        // Check if the request was unsuccessful
        throw new Error(
          "Failed to register email: " + (await fetchResponse.text())
        );
      }

      const data = await fetchResponse.json();
      setResponse(data); // Assuming you have a state variable to store response
      console.log("Email registered successfully:", data);

      // Optionally clear the input fields after successful registration
      setSingleEmail("");
      setSingleOrgId("");
      setSingleDivision("");
      setSingleSignUpWave("");
    } catch (error) {
      console.error("Error during single email registration:", error);
      alert("Error during registration. Please try again.");
    }
  };

  const createOrg = async () => {
    console.log("Create org");
    if (accounts.length > 0) {
      console.log("Create org");
      const request = {
        scopes: protectedResources.apiCreateOrg.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          org_name: orgName,
          org_specialty: orgSpecialty,
          ehr_name: ehrName,
          org_ehr_id: orgEhrId,
        }),
      };

      const fetchResponse = await fetch(
        protectedResources.apiCreateOrg.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();
      //setOrgId(data.org_id);
      setResponse(data);
    }
  };
  const updateActor = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiUpdateActor.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          full_name: fullName,
          org_id: actorOrgId,
          actor_ehr_id: actorEhrId,
        }),
      };

      const fetchResponse = await fetch(
        `${protectedResources.apiUpdateActor.endpoint}${b2cUserId}`,
        requestOptions
      );
      const data = await fetchResponse.json();
      setResponse(data);
    }
  };
  const listOrgs = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiListOrgs.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        protectedResources.apiListOrgs.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();
      setResponse(data);
    }
  };

  const searchActors = (query) => {
    setSearchQuery(query);
  };

  const selectActor = (actor) => {
    setSelectedActor(actor);
    fetchDocuments(actor.actor_id);
  };

  const fetchDocuments = async (actorId) => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiListDocuments.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        `${protectedResources.apiListDocuments.endpoint}?actor_id=${actorId}`,
        requestOptions
      );
      const data = await fetchResponse.json();
      setDocuments(
        Array.isArray(data.documents)
          ? data.documents.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          : []
      );
    }
  };

  const fetchDocumentsWithRating = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiGetDocumentsWithRating.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        `${protectedResources.apiGetDocumentsWithRating.endpoint}?start_date=${startDate}&end_date=${endDate}`,
        requestOptions
      );
      const data = await fetchResponse.json();
      console.log(data);
      setDocumentsWithRating(
        Array.isArray(data.documents)
          ? data.documents.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          : []
      );
    }
  };

  const toggleDocumentDetails = (documentId) => {
    setExpandedDocument(expandedDocument === documentId ? null : documentId);
  };

  const filteredActors = actors.filter((actor) =>
    actor.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredDocuments = documentsWithRating.filter(doc => {
    if (selectedRating === "all") return true;
    return doc.note_rating === selectedRating;
  });

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const filterModifiedDocuments = (doc) => {
    const fields = ["hpi", "ros", "physical_exam", "reevaluation", "mdm"];
    return fields.some(
      (field) =>
        doc[field] !== doc[`originally_generated_${field}`] &&
        doc[field] !== "" &&
        doc[`originally_generated_${field}`] !== "" &&
        doc[field] !== ""
    );
  };

  const documentsToDisplay = documents
    .filter((doc) => !showModifiedOnly || filterModifiedDocuments(doc))
    .filter((doc) => !showWithFeedbackOnly || doc.note_feedback)
    .filter((doc) => !showWithMDMOnly || doc.mdm);

  const highlightDifferences = (original, current) => {
    const diff = diffWords(original || "", current || "");
    return diff.map((part, index) => {
      const className = part.added
        ? "bg-green-100"
        : part.removed
          ? "bg-red-100 line-through"
          : "";
      return (
        <span key={index} className={className}>
          {part.value}
        </span>
      );
    });
  };

  const highlightDifferencesHTML = (original, current) => {
    const diff = diffWords(original || "", current || "");
    let result = '';

    diff.forEach(part => {
      if (part.added) {
        result += `<span class="bg-green-100">${part.value}</span>`;
      } else if (part.removed) {
        result += `<span class="bg-red-100 line-through">${part.value}</span>`;
      } else {
        result += part.value;
      }
    });

    return DOMPurify.sanitize(result);
  };

  const listActors = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: protectedResources.apiListActors.scopes,
        account: accounts[0],
      };

      const tokenResponse = await instance.acquireTokenSilent(request);
      const token = tokenResponse.accessToken;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const fetchResponse = await fetch(
        protectedResources.apiListActors.endpoint,
        requestOptions
      );
      const data = await fetchResponse.json();
      setActors(data.actors);
      setActorsFetched(true);
    }
  };


  function Accordion({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="bg-white shadow-md rounded-lg mb-4">
        <button
          className="w-full text-left font-semibold py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded-t-lg"
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
        </button>
        {isOpen && (
          <div className="p-4">
            {children}
          </div>
        )}
      </div>
    );
  }

  function CustomInstructionsDisplay({ actor }) {
    const instructions = [
      { field: 'custom_instructions_hpi', title: 'HPI' },
      { field: 'custom_instructions_ros', title: 'ROS' },
      { field: 'custom_instructions_physical_exam', title: 'Physical Exam' },
      { field: 'custom_instructions_reevaluation', title: 'Reevaluation' }
    ];

    return (
      <div className="grid gap-4">
        {instructions.map(({ field, title }) => (
          actor[field] && (
            <div key={field} className="bg-white shadow rounded-lg p-4">
              <h3 className="font-semibold text-lg mb-2">{title} Custom Instructions</h3>
              <div className="text-gray-600 whitespace-pre-wrap">{actor[field]}</div>
            </div>
          )
        ))}
      </div>
    );
  }



  function DocumentDetails({ doc }) {
    const [openSections, setOpenSections] = useState({
      noteFeedback: true,
      transcript: true,
      hpi: true,
      ros: true,
      physicalExam: true,
      reevaluation: true,
      mdm: true,
      subjective: true,
      objective: true,
      assessmentAndPlan: true,
      dischargeSummary: true,
      externalNote: true
    });

    const toggleSection = (section) => {
      setOpenSections(prev => ({
        ...prev,
        [section]: !prev[section]
      }));
    };

    const renderHeader = (sectionName, displayName) => {
      const isOpen = openSections[sectionName];
      return (
        <div className="cursor-pointer flex justify-between items-center bg-gray-200 p-2 rounded-md hover:bg-gray-300"
          onClick={() => toggleSection(sectionName)}>
          <strong>{displayName}</strong>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      );
    };

    return (
      <div className="mt-2 p-2 bg-gray-50 border border-gray-200 rounded">
        {renderHeader('noteFeedback', 'Note Feedback')}
        {openSections.noteFeedback && <div className="whitespace-pre-wrap">{doc.note_feedback}</div>}

        {renderHeader('transcript', 'Transcript')}
        {openSections.transcript && <div className="whitespace-pre-wrap">{doc.transcript}</div>}

        <div className="grid mt-2">
          {(doc.hospitalist_document_type === null || doc.hospitalist_document_type === 'ADMISSION_NOTE') && (
            <>
              {renderHeader('hpi', 'HPI')}
              {openSections.hpi && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_hpi, doc.hpi)}</div>}

              {renderHeader('ros', 'ROS')}
              {openSections.ros && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_ros, doc.ros)}</div>}

              {renderHeader('physicalExam', 'Physical Exam')}
              {openSections.physicalExam && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_physical_exam, doc.physical_exam)}</div>}

              {renderHeader('reevaluation', 'Reevaluation')}
              {openSections.reevaluation && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_reevaluation, doc.reevaluation)}</div>}
            </>
          )}

          {doc.hospitalist_document_type === 'PROGRESS_NOTE' && (
            <>
              {renderHeader('subjective', 'Subjective')}
              {openSections.subjective && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_subjective, doc.subjective)}</div>}

              {renderHeader('objective', 'Objective')}
              {openSections.objective && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_objective, doc.objective)}</div>}

              {renderHeader('assessmentAndPlan', 'Assessment and Plan')}
              {openSections.assessmentAndPlan && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_assessment_and_plan, doc.assessment_and_plan)}</div>}
            </>
          )}

          {doc.hospitalist_document_type === 'DISCHARGE_SUMMARY' && (
            <>
              {renderHeader('dischargeSummary', 'Discharge Summary')}
              {openSections.dischargeSummary && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_hospitalist_discharge_summary, doc.hospitalist_discharge_summary)}</div>}
            </>
          )}

          {doc.hospitalist_document_type === 'EXTERNAL_NOTE' && (
            <>
              {renderHeader('externalNote', 'External Note')}
              {openSections.externalNote && <div className="whitespace-pre-wrap">{highlightDifferences(doc.originally_generated_external_note, doc.hospitalist_external_note)}</div>}
            </>
          )}


          {renderHeader('mdm', 'Medical Decision-Making')}
          {openSections.mdm && (
            <div
              id="mdm-content"
              className="whitespace-pre-wrap"
            >
              {parse(highlightDifferencesHTML(doc.originally_generated_mdm, doc.mdm))}
            </div>
          )}
        </div>
      </div>
    );
  }








  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>

      {/* Organization Management Section */}
      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">Manage Organization</h2>
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
          placeholder="Organization Name"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={orgSpecialty}
          onChange={(e) => setOrgSpecialty(e.target.value)}
          placeholder="Organization Specialty"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={ehrName}
          onChange={(e) => setEhrName(e.target.value)}
          placeholder="EHR Name"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={orgEhrId}
          onChange={(e) => setOrgEhrId(e.target.value)}
          placeholder="Organization EHR ID"
        />
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1 w-full"
          onClick={createOrg}
        >
          Create Organization
        </button>
      </div>

      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">Bulk Upload Emails</h2>
        <input
          className="border p-2 m-1"
          type="file"
          onChange={handleFileChange}
        />
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1"
          onClick={uploadEmails}
          disabled={!file}
        >
          Upload Emails
        </button>
        {uploading && <p>Uploading...</p>}
        {response && <div>Response: {JSON.stringify(response)}</div>}
      </div>

      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">Register Single Email</h2>
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleEmail}
          onChange={(e) => setSingleEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleOrgId}
          onChange={(e) => setSingleOrgId(e.target.value)}
          placeholder="Organization ID"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleDivision}
          onChange={(e) => setSingleDivision(e.target.value)}
          placeholder="Division"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleSignUpWave}
          onChange={(e) => setSingleSignUpWave(e.target.value)}
          placeholder="Sign Up Wave"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleFirstName}
          onChange={(e) => setSingleFirstName(e.target.value)}
          placeholder="First Name"
        />
        <input
          className="border p-2 m-1 w-full"
          type="text"
          value={singleLastName}
          onChange={(e) => setSingleLastName(e.target.value)}
          placeholder="Last Name"
        />
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1 w-full"
          onClick={registerSingleEmail}
        >
          Register Email
        </button>
      </div>

      {/* Response and Actions Section */}
      {response && (
        <div className="p-4 bg-gray-100 rounded">
          Response: {JSON.stringify(response)}
        </div>
      )}
      <div className="mt-4">
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1"
          onClick={listOrgs}
        >
          List Organizations
        </button>
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1"
          onClick={listActors}
        >
          List Actors
        </button>
      </div>

      {/* Search Users Section */}
      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">Search Users</h2>
        <button
          className="bg-indigo-900 rounded-md text-white p-2 m-1"
          onClick={listActors}
        >
          Fetch Users
        </button>
        {actorsFetched && (
          <div>
            <input
              className="border p-2 m-1 w-full"
              type="text"
              value={searchQuery}
              onChange={(e) => searchActors(e.target.value)}
              placeholder="Search by user name"
            />
            <ul className="list-none p-0">
              {filteredActors.map((actor) => (
                <li
                  key={actor.actor_id}
                  onClick={() => selectActor(actor)}
                  className="cursor-pointer p-2 border-b border-gray-200 hover:bg-gray-100"
                >
                  <span className="font-bold">{actor.full_name}</span>
                  <span className="text-sm text-gray-600">
                    {" "}
                    ({actor.email}, {actor.org_id})
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* <Accordion title="User Macros">
        <MacroDisplay macros={macros} />
      </Accordion> */}
      {/* Display Selected User's Documents */}
      {selectedActor && (
        <div className="mb-4 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-3">
            {selectedActor.full_name}'s Information
          </h2>
          <div className="mb-4">
            <p><strong>Email:</strong> {selectedActor.email}</p>
            <p><strong>Specialty:</strong> {selectedActor.actor_specialty || 'Not specified'}</p>
          </div>

          <Accordion title="Custom Instructions">
            <CustomInstructionsDisplay actor={selectedActor} />
          </Accordion>
          <h3 className="text-lg font-semibold mb-3">
            {selectedActor.full_name}'s Documents
          </h3>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={showModifiedOnly}
              onChange={() => setShowModifiedOnly(!showModifiedOnly)}
            />
            <span className="ml-2">Show only modified documents</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={showWithFeedbackOnly}
              onChange={() => setShowWithFeedbackOnly(!showWithFeedbackOnly)}
            />
            <span className="ml-2">Show only documents with note feedback</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={showWithMDMOnly}
              onChange={() => setShowWithMDMOnly(!showWithMDMOnly)}
            />
            <span className="ml-2">Show only documents with MDM</span>
          </label>
          <ul className="list-none p-0">
            {documentsToDisplay.map((doc) => (
              <li
                key={doc.document_id}
                className="p-2 border-b border-gray-200"
              >
                <div
                  className="cursor-pointer hover:bg-gray-100 p-2"
                  onClick={() => {
                    toggleDocumentDetails(doc.document_id); // Log the document details
                  }}
                >
                  <div>
                    <strong>Patient Name:</strong> {doc.patient_name}
                  </div>
                  <div>
                    <strong>Chief Complaint:</strong> {doc.procedure_description}
                  </div>
                  <div>
                    <strong>Document Status:</strong> {doc.document_status}
                  </div>
                  <div>
                    <strong>Document Type:</strong> {doc.hospitalist_document_type || 'ER Note'}
                  </div>
                  <div>
                    <strong>Created At:</strong> {formatDate(doc.created_at)}
                  </div>

                  <div className="text-sm space-y-1">
                    {doc.macro_ambiently_inserted && (
                      <span className="bg-blue-100 text-blue-500 font-semibold py-1 px-2 rounded-full">
                        Macro Ambiently Inserted
                      </span>
                    )}
                    {doc.macro_manually_inserted && (
                      <span className="bg-green-100 text-green-500 font-semibold py-1 px-2 rounded-full">
                        Macro Manually Inserted
                      </span>
                    )}
                    {doc.smart_chart_used && (
                      <span className="bg-indigo-100 text-indigo-900 font-semibold py-1 px-2 rounded-full">
                        Smart Chart Global
                      </span>
                    )}
                    {doc.smart_chart_used_hpi && (
                      <span className="bg-yellow-100 text-yellow-500 font-semibold py-1 px-2 rounded-full">
                        Smart Chart HPI
                      </span>
                    )}
                    {doc.smart_chart_used_ros && (
                      <span className="bg-orange-100 text-orange-500 font-semibold py-1 px-2 rounded-full">
                        Smart Chart ROS
                      </span>
                    )}
                    {doc.smart_chart_used_physical_exam && (
                      <span className="bg-teal-100 text-teal-500 font-semibold py-1 px-2 rounded-full">
                        Smart Chart Physical
                      </span>
                    )}
                    {doc.smart_chart_used_reevaluation && (
                      <span className="bg-pink-100 text-pink-500 font-semibold py-1 px-2 rounded-full">
                        Smart Chart Reevaluation
                      </span>
                    )}
                    {doc.smart_chart_used_mdm && (
                      <span className="bg-red-100 text-red-500 font-semibold py-1 px-2 rounded-full">
                        Smart Chart MDM
                      </span>
                    )}
                  </div>
                </div>
                {expandedDocument === doc.document_id && (
                  <DocumentDetails doc={doc} />
                )}

              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">
          Fetch Documents with Note Rating
        </h2>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Start Date:
          </label>
          <input
            type="date"
            className="border p-2 w-full"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            End Date:
          </label>
          <input
            type="date"
            className="border p-2 w-full"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <button
          className="bg-indigo-900 rounded-md text-white p-2 w-full"
          onClick={fetchDocumentsWithRating}
        >
          Fetch Documents
        </button>
      </div>

      <div className="mb-4 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-3">Filter by Note Rating</h2>
        <div className="flex justify-between">
          {["all", "good", "medium", "bad"].map(rating => (
            <button
              key={rating}
              className={`p-2 rounded ${selectedRating === rating ? 'bg-indigo-900 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => setSelectedRating(rating)}
            >
              {rating.charAt(0).toUpperCase() + rating.slice(1)}
            </button>
          ))}
        </div>
      </div>

      {filteredDocuments.length > 0 && (
        <div className="mb-4 bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-3">Documents with Note Rating</h2>
          <ul className="list-none p-0">
            {filteredDocuments.map((doc) => (
              <li key={doc.document_id} className="p-2 border-b border-gray-200">
                <div
                  className="cursor-pointer hover:bg-gray-100 p-2"
                  onClick={() => {
                    toggleDocumentDetails(doc.document_id);
                  }}
                >
                  <div>
                    <strong>Note Rating:</strong> {doc.note_rating}
                  </div>
                  <div>
                    <strong>Actor Id:</strong> {doc.actor_id}
                  </div>
                  <div>
                    <strong>Patient Name:</strong> {doc.patient_name}
                  </div>
                  <div>
                    <strong>Chief Complaint:</strong> {doc.procedure_description}
                  </div>
                  <div>
                    <strong>Created At:</strong> {formatDate(doc.created_at)}
                  </div>
                </div>
                {expandedDocument === doc.document_id && (
                  <DocumentDetails doc={doc} />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

    </div>
  );
}
const ErrorComponent = ({ error }) => {
  const navigate = useNavigate();

  if (error.message.includes("user_cancelled")) {
    // Redirect to the home page
    navigate("/");
    return null;
  } else {
    // Handle other types of errors
    return <div>Error: {error.message}</div>;
  }
};

const AdminDashboard = () => {
  const authRequest = {
    scopes: protectedResources.apiCreateOrg.scopes,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
    >
      <AdminDashboardInner />
    </MsalAuthenticationTemplate>
  );
};

export default AdminDashboard;
