// MDMSettings.jsx

import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { Switch } from '@headlessui/react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../contexts/AuthProvider';
import { protectedResources } from '../authConfig';
import TipTapEditor from '../components/TipTap';
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import MdmStyleOptions from './MdmStyleOptions';
import MdmToggles from './MdmToggles';
import MdmTabContent from './MdmTabContent';
import { getAvailableGroups, getGroupMdmTemplate } from '../api/groupApi';
import SelectGroupDropdown from './SelectGroupDropdown';
import { saveCustomMdmTemplate } from '../api/mdm';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MdmSettings({ groupId, onSuccessfulSave, isModal }) {
  const { instance, accounts } = useMsal();
  const { actorInfo, isLoading: isActorLoading, updateActorInfo } = useAuth();

  const [alwaysGenerateMdm, setAlwaysGenerateMdm] = useState(actorInfo?.always_generate_mdm || false);
  const [alwaysRegenerateChart, setAlwaysRegenerateChart] = useState(actorInfo?.regenerate_chart_every_encounter || false);

  // State for the free-text block
  const [instructions, setInstructions] = useState('');
  const [charCount, setCharCount] = useState(0);
  // State for template and examples
  const [templateContent, setTemplateContent] = useState('');
  const [exampleContents, setExampleContents] = useState(['']); // Initialize with one empty example
  const [templateCharCount, setTemplateCharCount] = useState(0);
  const [exampleCharCounts, setExampleCharCounts] = useState([0]);
  const maxChars = 8000;
  const maxExamples = 3; // Maximum number of examples allowed

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // State variable for instructions to send
  const [instructionsToSend, setInstructionsToSend] = useState('');


  const [isLoading, setIsLoading] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState('');
  const [availableGroups, setAvailableGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(groupId || null);

  const updateMDMTemplate = (content) => {
    setInstructions(content);

    // Calculate character count based on text content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    setCharCount(textContent.length > maxChars ? maxChars : textContent.length);
  };

  useEffect(() => {
    // Combine examples with separators
    const combinedExamples = exampleContents
      .map((content) => content?.trim() || '')
      .filter(Boolean)
      .join('\n\n<!--EXAMPLE_SEPARATOR-->\n\n');

    let combinedInstructions = (templateContent || '').trim();

    if (combinedExamples) {
      combinedInstructions += '\n\n<!--SECTION_SEPARATOR-->\n\n';
      combinedInstructions += '<p>Use the below example(s) to guide your output style.</p>\n\n';
      combinedInstructions += combinedExamples;
    }

    setInstructionsToSend(combinedInstructions);
  }, [templateContent, exampleContents]);




  useEffect(() => {
    if (actorInfo && !isActorLoading) {
      const customInstructions = actorInfo.custom_instructions_mdm || '';
      // Split the customInstructions using <!--SECTION_SEPARATOR-->
      const [templateSection, examplesSection] = customInstructions.split('<!--SECTION_SEPARATOR-->');

      const template = templateSection?.trim() || '';
      let examples = [];

      if (examplesSection) {
        // Remove the additional text from the examples section
        const examplesContent = examplesSection.replace(/<p>Use the below example\(s\) to guide your output style\.<\/p>/i, '').trim();
        // Split the examplesContent using <!--EXAMPLE_SEPARATOR-->
        examples = examplesContent
          .split('<!--EXAMPLE_SEPARATOR-->')
          .map((example) => example.trim())
          .filter(Boolean);
      }

      setTemplateContent(template);
      setExampleContents(examples.length > 0 ? examples : []);
      // Update character counts
      setTemplateCharCount(getTextContentLength(template));
      setExampleCharCounts(examples.map(getTextContentLength));
    }
  }, [actorInfo, isActorLoading]);



  // Helper function to get text content length
  const getTextContentLength = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent || '';
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length > maxChars ? maxChars : textContent.length;
  };


  // Radio button options for MDM Style
  const mdmStyleOptions = ['Billing-focused', 'Narrative', 'Both'];

  // Descriptions for MDM Styles
  const mdmStyleDescriptions = {
    'Billing-focused': 'Generate an MDM section that satisfies billing requirements.',
    'Narrative': 'Generate a narrative MDM section focusing on clinical reasoning.',
    'Both': 'Include both billing-focused and narrative MDM sections.',
  };

  // State for selected MDM Style
  const [selectedMdmStyle, setSelectedMdmStyle] = useState(null);

  // Toggles
  const [includeSuggestionsInBrackets, setIncludeSuggestionsInBrackets] = useState(false);
  const [onlyIncludeVerbalized, setOnlyIncludeVerbalized] = useState(false);

  // Instruction Mappings
  const MDM_STYLE_INSTRUCTIONS = {
    'Billing-focused': 'Generate an MDM section focusing on billing requirements.',
    'Narrative': 'Generate a narrative MDM section highlighting clinical reasoning.',
    'Both': 'Generate both billing-focused and narrative MDM sections.',
  };

  const TOGGLE_INSTRUCTIONS = {
    includeSuggestionsInBrackets: 'Include suggestions in brackets.',
    onlyIncludeVerbalized: 'Only include information explicitly verbalized by the provider.',
  };




  // Helper function to extract free-text instructions
  const extractFreeTextInstructions = (customInstructions) => {
    let remainingInstructions = customInstructions;

    Object.values(MDM_STYLE_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    Object.values(TOGGLE_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    return remainingInstructions.trim();
  };

  const addExample = () => {
    if (exampleContents.length < maxExamples) {
      setExampleContents([...exampleContents, '']);
      setExampleCharCounts([...exampleCharCounts, 0]);
      setSelectedTabIndex(exampleContents.length + 1); // +1 because Template is at index 0
    }
  };

  const removeExample = (index) => {
    const newContents = [...exampleContents];
    const newCharCounts = [...exampleCharCounts];
    newContents.splice(index, 1);
    newCharCounts.splice(index, 1);
    setExampleContents(newContents);
    setExampleCharCounts(newCharCounts);

    // Adjust selectedTabIndex if necessary
    if (selectedTabIndex > index + 1) {
      setSelectedTabIndex(selectedTabIndex - 1);
    } else if (selectedTabIndex === index + 1) {
      setSelectedTabIndex(0); // Go back to Template tab if current example is removed
    }
  };

  const updateExampleContent = (index, content) => {
    const newContents = [...exampleContents];
    newContents[index] = content;
    setExampleContents(newContents);
    const newCharCounts = [...exampleCharCounts];
    newCharCounts[index] = getTextContentLength(content);
    setExampleCharCounts(newCharCounts);
  };


  // Load existing settings from actorInfo
  useEffect(() => {
    if (actorInfo && !isActorLoading) {
      // Load custom instructions
      const customInstructions = actorInfo.custom_instructions_mdm || '';
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize MDM Style
      const mdmStyle = mdmStyleOptions.find((option) =>
        customInstructions.includes(MDM_STYLE_INSTRUCTIONS[option])
      );
      setSelectedMdmStyle(mdmStyle || null);

      // Initialize Toggles
      setIncludeSuggestionsInBrackets(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.includeSuggestionsInBrackets)
      );
      setOnlyIncludeVerbalized(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.onlyIncludeVerbalized)
      );
    }
  }, [actorInfo, isActorLoading]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (accounts.length === 0) {
      console.error('No accounts found');
      return;
    }
    if (!selectedGroupId) {
      alert('Please select a group first');
      return;
    }
    setIsLoading(true);
    try {
      // Call the save function with the necessary parameters
      await saveCustomMdmTemplate(instance, accounts, selectedGroupId, instructionsToSend);

      // Reload settings after save
      const customInstructions = instructionsToSend;
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize MDM Style
      const mdmStyle = mdmStyleOptions.find((option) =>
        customInstructions.includes(MDM_STYLE_INSTRUCTIONS[option])
      );
      setSelectedMdmStyle(mdmStyle || null);

      // Initialize Toggles
      setIncludeSuggestionsInBrackets(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.includeSuggestionsInBrackets)
      );
      setOnlyIncludeVerbalized(
        customInstructions.includes(TOGGLE_INSTRUCTIONS.onlyIncludeVerbalized)
      );
    } catch (error) {
      console.error('Error updating actor:', error);
    }
    setIsLoading(false);
  };

  // Fetch available groups with both name and ID
  useEffect(() => {
    async function fetchGroups() {
      try {
        const groups = await getAvailableGroups(instance, accounts);
        setAvailableGroups(groups);

        if (selectedGroupId) {
          const matchedGroup = groups.find((group) => group.group_id === selectedGroupId);
          if (matchedGroup) {
            setSelectedGroup(matchedGroup);
          } else {
            alert('You do not have access to this group.');
            // Optionally close the modal or redirect  
          }
        } else if (groups.length > 0) {
          setSelectedGroup(groups[0]);
          setSelectedGroupId(groups[0].group_id);
        } else {
          setSelectedGroup(null);
          setSelectedGroupId(null);
        }
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    }
    fetchGroups();
  }, [instance, accounts, selectedGroupId]);

  useEffect(() => {
    async function fetchGroupMdmTemplate() {
      if (selectedGroupId) {
        try {
          const mdmTemplate = await getGroupMdmTemplate(instance, accounts, selectedGroupId);
          // Split the template using <!--SECTION_SEPARATOR-->
          const [templateSection, examplesSection] = (mdmTemplate || '').split('<!--SECTION_SEPARATOR-->');

          const template = templateSection?.trim() || '';
          let examples = [];

          if (examplesSection) {
            // Remove the additional text from the examples section
            const examplesContent = examplesSection
              .replace(/<p>Use the below example\(s\) to guide your output style\.<\/p>/i, '')
              .trim();
            // Split the examplesContent using <!--EXAMPLE_SEPARATOR-->
            examples = examplesContent
              .split('<!--EXAMPLE_SEPARATOR-->')
              .map((example) => example.trim())
              .filter(Boolean);
          }

          setTemplateContent(template);
          setExampleContents(examples.length > 0 ? examples : ['']);
          // Update character counts
          setTemplateCharCount(getTextContentLength(template));
          setExampleCharCounts(examples.map(getTextContentLength));
        } catch (error) {
          console.error('Failed to fetch MDM template:', error);
          setTemplateContent('');
          setExampleContents(['']);
          setTemplateCharCount(0);
          setExampleCharCounts([0]);
        }
      } else {
        setTemplateContent('');
        setExampleContents(['']);
        setTemplateCharCount(0);
        setExampleCharCounts([0]);
      }
    }
    fetchGroupMdmTemplate();
  }, [selectedGroupId, instance, accounts]);

  // Add a new handler for group selection
  const handleGroupChange = (newGroup) => {
    setSelectedGroup(newGroup);
    setSelectedGroupId(newGroup.group_id);
  };

  if (isActorLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className={isModal ? '' : 'container mx-auto px-4'}>
        <div className="flex items-center justify-between mt-8 mb-6">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            MDM Configuration
          </h1>
          <button
            onClick={handleSave}
            disabled={isLoading}
            className={classNames(
              'inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600',
              isLoading
                ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                : 'bg-indigo-800 text-white hover:bg-indigo-900'
            )}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>


        {/* Render Tabs for Template and Examples */}
        <div className="bg-white shadow-lg ring-1 ring-gray-900/5 sm:rounded-xl p-6">
          {/* Heading and Controls */}
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              MDM Instructions
            </h2>
            <div className="flex items-center space-x-4">
              {/* Pass availableGroups to SelectGroupDropdown */}
              <SelectGroupDropdown
                selectedGroup={selectedGroup}
                setSelectedGroup={handleGroupChange}
                availableGroups={availableGroups}
              />
              {/* Existing Add Example Button */}
              {exampleContents.length < maxExamples && (
                <button
                  onClick={addExample}
                  className="inline-flex items-center px-3 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  aria-label="Add Example"
                >
                  <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  Add Example
                </button>
              )}
            </div>
          </div>

          <Tab.Group selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
            <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
              {/* Template Tab */}
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full py-2.5 text-sm font-medium text-gray-700 rounded-lg',
                    selected
                      ? 'bg-white shadow'
                      : 'text-gray-500 hover:bg-white/[0.12] hover:text-gray-700'
                  )
                }
              >
                Template
              </Tab>
              {/* Example Tabs */}
              {exampleContents.map((_, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    classNames(
                      'w-full py-2.5 text-sm font-medium text-gray-700 rounded-lg',
                      selected
                        ? 'bg-white shadow'
                        : 'text-gray-500 hover:bg-white/[0.12] hover:text-gray-700'
                    )
                  }
                >
                  Example {index + 1}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-4">
              {/* Template Tab */}
              <Tab.Panel>
                <MdmTabContent
                  content={templateContent}
                  setContent={setTemplateContent}
                  charCount={templateCharCount}
                  setCharCount={setTemplateCharCount}
                  label="MDM Template"
                  maxChars={maxChars}
                />
              </Tab.Panel>
              {/* Example Tabs */}
              {exampleContents.map((content, index) => (
                <Tab.Panel key={index}>
                  <MdmTabContent
                    content={content}
                    setContent={(updatedContent) => updateExampleContent(index, updatedContent)}
                    charCount={exampleCharCounts[index]}
                    setCharCount={(count) => {
                      const newCharCounts = [...exampleCharCounts];
                      newCharCounts[index] = count;
                      setExampleCharCounts(newCharCounts);
                    }}
                    label={`Example ${index + 1}`}
                    maxChars={maxChars}
                    onRemoveExample={() => removeExample(index)}
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
}
