// CodeMirrorEditor.jsx
import React, { useEffect, useRef } from 'react';
import CodeMirror from 'codemirror';

// Import CodeMirror CSS
import 'codemirror/lib/codemirror.css';

// Import necessary addons and modes
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/mode/simple'; // Import simple mode addon
import 'codemirror/mode/markdown/markdown';

// Import custom CSS for highlighting
import '../styles/CodeMirrorEditor.css';

const CodeMirrorEditor = ({ textContent, updateTextContent, saveDocument }) => {
    const editorRef = useRef(null);
    const editorInstanceRef = useRef(null);
    const isUpdating = useRef(false);
    const contentLoaded = useRef(false);

    useEffect(() => {
        console.log('CodeMirrorEditor mounted');
        console.log('Initial textContent:', textContent);

        // Initialize CodeMirror
        const editor = CodeMirror(editorRef.current, {
            value: textContent || '',
            mode: 'mipsMode',
            lineNumbers: false,
            lineWrapping: true,
            autofocus: false,
        });

        editorInstanceRef.current = editor;

        // Define custom highlighting for MIPS text
        CodeMirror.defineSimpleMode('mipsMode', {
            start: [
                {
                    regex: /(MIPS|CEDR):.*$/,
                    token: 'mips-highlight',
                    next: 'afterMips',
                },
                {
                    regex: /.+/,
                    token: null,
                },
            ],
            afterMips: [
                {
                    regex: /.+/,
                    token: 'mips-highlight',
                    next: 'start',
                },
            ],
        });
        // Set contentLoaded to true if textContent is already available
        if (textContent !== undefined && textContent !== null) {
            contentLoaded.current = true;
            console.log('Content loaded on mount');
        } else {
            console.log('Content not loaded on mount');
        }

        // Event handler functions
        const onChangeHandler = (instance) => {
            console.log('onChangeHandler called');
            console.log('isUpdating:', isUpdating.current, 'contentLoaded:', contentLoaded.current);

            if (!isUpdating.current && contentLoaded.current) {
                const newValue = instance.getValue();
                console.log('User input detected, newValue:', newValue);
                updateTextContent(newValue);
            } else {
                console.log('Change ignored due to isUpdating or content not loaded');
            }
        };

        const onBlurHandler = () => {
            console.log('onBlurHandler called');
            console.log('editorInstanceRef.current:', editorInstanceRef.current, 'contentLoaded:', contentLoaded.current);

            if (editorInstanceRef.current && contentLoaded.current) {
                const currentContent = editorInstanceRef.current.getValue();
                console.log('Saving document with content:', currentContent);
                saveDocument('reeval', currentContent);
            } else {
                console.log('Save ignored due to editorInstanceRef.current being null or content not loaded');
            }
        };

        // Add event listeners
        editor.on('change', onChangeHandler);
        editor.on('blur', onBlurHandler);

        // Clean up on unmount
        return () => {
            console.log('CodeMirrorEditor unmounting');
            if (editorInstanceRef.current) {
                editorInstanceRef.current.off('change', onChangeHandler);
                editorInstanceRef.current.off('blur', onBlurHandler);
                editorInstanceRef.current.getWrapperElement().remove();
                editorInstanceRef.current = null;
            }
        };
    }, []); // Empty dependency array ensures this runs once on mount

    // Update the editor's content when textContent prop changes
    useEffect(() => {
        console.log('useEffect for textContent change called');
        console.log('Current textContent:', textContent);
        console.log('editorInstanceRef.current:', editorInstanceRef.current);

        if (
            editorInstanceRef.current &&
            editorInstanceRef.current.getValue() !== textContent
        ) {
            console.log('Updating editor content from textContent');
            isUpdating.current = true; // Set the flag to prevent 'change' handler from updating textContent
            editorInstanceRef.current.setValue(textContent || '');
            isUpdating.current = false; // Reset the flag
            contentLoaded.current = true; // Set contentLoaded to true after initial load
        } else {
            console.log('Editor content is already up to date or editor not initialized');
        }
    }, [textContent]);

    return (
        <div className="w-full h-full">
            <div ref={editorRef} className="h-full" />
        </div>
    );
};

export default CodeMirrorEditor;
