import React from 'react';
import {
    FingerPrintIcon,
    CommandLineIcon,
} from '@heroicons/react/24/outline';

const DifferentialDiagnosisIcon = () => (
    <div className="relative flex items-center justify-center">
        {/* Main analysis/examination icon */}
        <FingerPrintIcon className="h-8 w-8 text-gray-800" />
        {/* Branching paths - positioned with absolute positioning */}
        <CommandLineIcon className="h-5 w-5 text-gray-600 absolute top-0 left-8 rotate-45" />
        <CommandLineIcon className="h-5 w-5 text-gray-600 absolute bottom-0 left-8 -rotate-45" />
    </div>
);

export default DifferentialDiagnosisIcon;