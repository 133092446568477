// src/pages/Home.js

import React, { useEffect, useState, useCallback } from "react";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/catalyst/button";
import { Fieldset, Legend, FieldGroup } from "../components/catalyst/fieldset";
import { protectedResources } from "../authConfig";
import NoteDashboard from "./NoteDashboard";
import { usePostHog } from "posthog-js/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/browser";

export const Home = () => {
  const posthog = usePostHog();
  const { instance, accounts, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [showBanner, setShowBanner] = useState(true);

  const loadingMessage =
    inProgress === InteractionStatus.Logout
      ? "Securely Logging Out..."
      : "Securely Logging In...";

  const fetchUserInfo = useCallback(async () => {
    setIsLoading(true); // Start loading
    if (accounts.length > 0) {
      try {
        const request = {
          scopes: protectedResources.apiGetMyInfo.scopes,
          account: accounts[0],
        };

        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const fetchResponse = await fetch(
          protectedResources.apiGetMyInfo.endpoint,
          requestOptions
        );
        const actorInfo = await fetchResponse.json();
        const actorSpecialty = actorInfo.actor_specialty;
        const actorStatus = actorInfo.actor_status; // New line

        posthog?.identify(actorInfo.actor_uuid, {
          email: activeAccount.idTokenClaims?.emails?.[0],
          name: activeAccount.idTokenClaims?.name,
        });

        // Add Sentry identification
        Sentry.setUser({
          id: actorInfo.actor_uuid,
          email: actorInfo.email
        });

        window.Appcues.identify(actorInfo.actor_uuid, {
          email: actorInfo.email,
          name: actorInfo.full_name,
          org_id: actorInfo.org_id,
          sign_up_wave: actorInfo.sign_up_wave,
          subscription_type: actorInfo.subscription_type,
          actor_specialty: actorInfo.actor_specialty,
        });

        window.Canny('identify', {
          appID: '669537b36dd307ebca975ed7',
          user: {
            email: actorInfo.email,
            name: actorInfo.full_name,
            id: actorInfo.actor_uuid,
          },
        });

        // New condition to check actorStatus
        if (actorInfo.admin_status == 'GLOBAL_ADMIN') {
          navigate("/global-admin-dashboard", { state: { actorInfo: actorInfo } });
        } else if (actorStatus === "INACTIVE") {
          console.log("Actor is inactive");
          navigate("/subscribe", { state: { actorInfo: actorInfo } });
        } else if (!actorInfo.accepted_most_recent_terms || !actorInfo.accepted_most_recent_privacy_policy) {
          navigate("/accept-policies", { state: { actorInfo: actorInfo } });
        } else if (actorInfo.org_id === 5) {
          if (actorSpecialty === "HOSPITALIST") {
            navigate("/hospitalist-inpatient-dashboard", { state: { actorInfo: actorInfo } });
          } else if (posthog.isFeatureEnabled('ask_carepoint_location')) {
            // do something
            const loggedRecently = await checkLoginLocationRecently(token);
            if (!loggedRecently) {
              console.log("Actor has not logged a location in the last 24 hours");
              // Handle the case where the actor has not logged a location recently
              navigate("/carepoint-location-survey", { state: { actorInfo: actorInfo } });
            } else {
              console.log("Actor has logged a location in the last 24 hours");
              navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
            }
          } else {
            navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
          }

        } else if (
          actorSpecialty === "EMERGENCY_PHYSICIAN" ||
          actorSpecialty === "ADVANCED_PRACTICE_PROVIDER" ||
          actorSpecialty === "EM_RESIDENT"
        ) {
          navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
        } else if (
          actorSpecialty === "HOSPITALIST"
        ) {
          navigate("/hospitalist-inpatient-dashboard", { state: { actorInfo: actorInfo } });
        } else if (actorSpecialty === "AVIATION_STUDENT") {
          navigate("/aviation-analytics-hub");
        } else {
          console.error("User does not have proper login credentials.");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user info:", error);
        // Log out the user from MSAL
        await instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false); // Stop loading if no accounts
    }
  }, [instance, accounts, navigate, setIsLoading, posthog, activeAccount]);

  useEffect(() => {
    if (activeAccount) {
      fetchUserInfo();
    }
  }, [activeAccount, fetchUserInfo]);

  const checkLoginLocationRecently = async (token) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),  // Empty object as the request payload
    };

    try {
      const response = await fetch(
        protectedResources.apiHasLoggedLocationRecently.endpoint,
        requestOptions
      );
      const data = await response.json();
      return data.answer;  // Assuming the response model has 'answer' field
    } catch (error) {
      console.error("Error checking login location:", error);
      return false;
    }
  };


  const handleLogin = () => {
    instance.loginRedirect().catch((error) => {
      console.error("Login error:", error);
    });
  };

  return (
    <>
      {/* {showBanner && (
        <div className="flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm leading-6 text-white">
            <strong className="font-semibold">Maintenance Notice</strong>
            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
              <circle r={1} cx={1} cy={1} />
            </svg>
            Cleo's web app will be undergoing scheduled maintenance on Sunday, July 28th at 7AM CT.
            <br />
            While we do not anticipate any outages, if you experience any issues accessing https://app.cleohealth.io you can access your documentation on https://app.cleo-ai.co.
          </p>
          <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={() => setShowBanner(false)}>
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      )} */}
      {isLoading && (
        <div className="loadingOverlay">
          <div className="spinner"></div>
          <div className="loadingMessage">{loadingMessage}</div>
        </div>
      )}
      {!activeAccount && !isLoading && (
        <div className="flex items-center justify-center min-h-screen">
          <div className="max-w-lg w-full bg-white shadow-lg rounded-lg pl-12 pr-12 pt-8">
            <div className="mb-12">
              <img
                src="/cleo-logo.png"
                alt="Cleo Logo"
                style={{ maxWidth: "60px", marginBottom: "20px" }}
              />
              <Fieldset>
                <Legend
                  className="text-black pb-2"
                  style={{ fontSize: "24px" }}
                >
                  Sign in to your account
                </Legend>
                <FieldGroup className="flex flex-col items-center">
                  <button
                    onClick={handleLogin}
                    className="self-center mt-8 w-3/4 bg-indigo-900 hover:bg-indigo-900 text-white rounded-md py-1.5 px-4"
                  >
                    Sign In
                  </button>
                </FieldGroup>
              </Fieldset>
            </div>
            <p className="text-center text-black mb-4">
              Click here to view our
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Terms of Use{" "}
              </a>
              and
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      )}
      <AuthenticatedTemplate>
        {activeAccount && !isLoading && <NoteDashboard />}
      </AuthenticatedTemplate>
    </>
  );
};

export default Home;
