import React from 'react';

export default function MdmStyleOptions({ selectedMdmStyle, setSelectedMdmStyle, mdmStyleOptions, mdmStyleDescriptions }) {
  return (
    <fieldset className="mb-6">
      <legend className="text-base font-semibold text-gray-900">MDM Style</legend>
      <p className="text-sm text-gray-500 mb-4">Choose the style for your MDM generation.</p>
      <div className="space-y-4">
        {mdmStyleOptions.map((option) => (
          <div key={option} className="flex items-center">
            <input
              id={option}
              name="mdmStyle"
              type="radio"
              value={option}
              checked={selectedMdmStyle === option}
              onChange={() => setSelectedMdmStyle(option)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-600 border-gray-300"
            />
            <label htmlFor={option} className="ml-3 block text-sm font-medium text-gray-700">
              {option}
            </label>
            <p className="ml-7 text-sm text-gray-500">{mdmStyleDescriptions[option]}</p>
          </div>
        ))}
      </div>
    </fieldset>
  );
} 