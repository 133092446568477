// DdxSettings.jsx

import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../contexts/AuthProvider';
import { protectedResources } from '../authConfig';

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import SettingsChatbot from './SettingsChatbot';

import { useFeatureFlagEnabled } from 'posthog-js/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DdxSettings({ onSuccessfulSave }) {
  const { instance, accounts } = useMsal();
  const { actorInfo, isLoading: isActorLoading, updateActorInfo } = useAuth();
  const isDdxChatbotEnabled = useFeatureFlagEnabled('ddx_chatbot');

  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const [allowOverride, setAllowOverride] = useState(false);

  // State for the free-text block
  const [instructions, setInstructions] = useState('');
  const [charCount, setCharCount] = useState(0);
  const maxChars = 2000;

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target.value.slice(0, maxChars);
    setInstructions(input);
    setCharCount(input.length);
  };

  const mdmOptions = [
    'Verbalized and conservative DDx',
    'Only verbalized DDx',
    'No DDx',
  ];

  const mdmDescriptions = {
    'Verbalized and conservative DDx':
      'Cleo will include mentioned diagnoses and a few closely related conditions.',
    'Only verbalized DDx':
      'Cleo will only include diagnoses explicitly mentioned by the provider.',
    'No DDx': 'Cleo will not generate any differential diagnosis.',
  };

  const [mdmSettings, setMdmSettings] = useState({
    'Verbalized and conservative DDx': false,
    'Only verbalized DDx': false,
    'No DDx': false,
  });

  const handleDdxSettingChange = (option) => {
    setMdmSettings({
      'Verbalized and conservative DDx': false,
      'Only verbalized DDx': false,
      'No DDx': false,
      [option]: true,
    });
  };

  const ddxFormattingOptions = [
    {
      name: 'Free-text paragraph',
      description: 'Display the differential diagnosis as a free-text paragraph.',
    },
    {
      name: 'Comma-separated list',
      description: 'Display the differential diagnosis as a comma-separated list.',
    },
    {
      name: 'Bulleted list with explanations',
      description: 'Display the differential diagnosis as a bulleted list with explanations.',
    },
  ];

  // Initialize with null to allow zero selected options
  const [selectedDdxFormatting, setSelectedDdxFormatting] = useState(null);

  // Instruction Mappings
  const AI_PARTICIPATION_INSTRUCTIONS = {
    'Full DDx':
      "Include a comprehensive differential diagnosis based on the patient's presentation and provider's assessment. If you have already done so, do not make any changes.",
    'Verbalized and conservative DDx':
      'Revisit your list of differential diagnoses and ensure they only include diagnoses that are extremely reasonable. Do NOT cast a wide net, be conservative.',
    'Only verbalized DDx':
      'Include only diagnoses explicitly mentioned by the provider in the transcript or documentation.',
    'No DDx':
      'Remove this section entirely and replace it with the string "No DDx" in your response.',
  };

  const DDX_FORMATTING_INSTRUCTIONS = {
    'Free-text paragraph': 'Format the differential diagnosis as a free-text paragraph.',
    'Comma-separated list': 'Format the differential diagnosis as a comma-separated list.',
    'Bulleted list with explanations':
      'Format the differential diagnosis as a bulleted list with explanations.',
  };

  // State variable for instructions to send
  const [instructionsToSend, setInstructionsToSend] = useState('');

  // Update instructionsToSend whenever any of the components change
  useEffect(() => {
    // Get the selected AI Participation options
    const selectedAIParticipation = mdmOptions.filter((option) => mdmSettings[option]);

    // Get the corresponding instructions
    const aiInstructions = selectedAIParticipation.map(
      (option) => AI_PARTICIPATION_INSTRUCTIONS[option]
    );

    // Get the selected DDx Formatting instruction
    const ddxInstruction = selectedDdxFormatting
      ? DDX_FORMATTING_INSTRUCTIONS[selectedDdxFormatting.name]
      : '';

    // Combine instructions
    const combinedInstructions = [instructions, ...aiInstructions, ddxInstruction]
      .filter(Boolean)
      .join(' ');

    setInstructionsToSend(combinedInstructions);
  }, [instructions, mdmSettings, selectedDdxFormatting]);

  // Helper function to extract free-text instructions
  const extractFreeTextInstructions = (customInstructions) => {
    let remainingInstructions = customInstructions;

    Object.values(AI_PARTICIPATION_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    Object.values(DDX_FORMATTING_INSTRUCTIONS).forEach((instr) => {
      remainingInstructions = remainingInstructions.replace(instr, '');
    });

    return remainingInstructions.trim();
  };

  // Load existing settings from actorInfo
  useEffect(() => {
    if (actorInfo && !isActorLoading) {
      // Load custom instructions
      const customInstructions = actorInfo.custom_instructions_ddx || '';
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize AI Participation settings
      const aiInstructionsIncluded = mdmOptions.filter((option) =>
        customInstructions.includes(AI_PARTICIPATION_INSTRUCTIONS[option])
      );
      setMdmSettings((prevSettings) => {
        const newSettings = { ...prevSettings };
        mdmOptions.forEach((option) => {
          newSettings[option] = aiInstructionsIncluded.includes(option);
        });
        return newSettings;
      });

      // Initialize DDx Formatting settings
      const ddxFormat = ddxFormattingOptions.find((option) =>
        customInstructions.includes(DDX_FORMATTING_INSTRUCTIONS[option.name])
      );
      if (ddxFormat) {
        setSelectedDdxFormatting(ddxFormat);
      } else {
        setSelectedDdxFormatting(null);
      }
    }
  }, [actorInfo, isActorLoading]);

  const updateActor = async (fieldToUpdate, newValue) => {
    try {
      const request = {
        scopes: protectedResources.apiUpdateActorSettings.scopes,
        account: accounts[0],
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          field: fieldToUpdate,
          value: newValue,
        }),
      };

      await fetch(protectedResources.apiUpdateActorSettings.endpoint, requestOptions);
      updateActorInfo(fieldToUpdate, newValue);
    } catch (error) {
      console.error('Error updating actor:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (accounts.length === 0) {
      console.error('No accounts found');
      return;
    }
    setIsLoading(true);
    try {
      await updateActor('custom_instructions_ddx', instructionsToSend);
      onSuccessfulSave &&
        onSuccessfulSave('Differential Diagnosis Settings saved successfully');

      // Reload settings after save
      const customInstructions = instructionsToSend;
      const freeTextInstructions = extractFreeTextInstructions(customInstructions);
      setInstructions(freeTextInstructions);
      setCharCount(freeTextInstructions.length);

      // Initialize AI Participation settings
      const aiInstructionsIncluded = mdmOptions.filter((option) =>
        customInstructions.includes(AI_PARTICIPATION_INSTRUCTIONS[option])
      );
      setMdmSettings((prevSettings) => {
        const newSettings = { ...prevSettings };
        mdmOptions.forEach((option) => {
          newSettings[option] = aiInstructionsIncluded.includes(option);
        });
        return newSettings;
      });

      // Initialize DDx Formatting settings
      const ddxFormat = ddxFormattingOptions.find((option) =>
        customInstructions.includes(DDX_FORMATTING_INSTRUCTIONS[option.name])
      );
      if (ddxFormat) {
        setSelectedDdxFormatting(ddxFormat);
      } else {
        setSelectedDdxFormatting(null);
      }
    } catch (error) {
      console.error('Error updating actor:', error);
    }
    setIsLoading(false);
  };

  if (isActorLoading) {
    return (
      <div className="loading-spinner">
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between mt-8 mb-6">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Differential Diagnosis Configuration
        </h1>
        <button
          onClick={handleSave}
          disabled={isLoading}
          className={classNames(
            'inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600',
            isLoading ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-indigo-800 text-white hover:bg-indigo-900'
          )}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </div>
      <div className="bg-white shadow-lg ring-1 ring-gray-900/5 sm:rounded-xl p-6">
        {/* Allow users to override settings toggle */}
        {/* <div className="flex items-center mb-6 border-b border-gray-200 pb-3">
          <span className="text-md font-medium text-gray-900 mr-8">
            Allow users to override settings
          </span>
          <Switch
            checked={allowOverride}
            onChange={setAllowOverride}
            className={classNames(
              allowOverride ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 items-center rounded-full'
            )}
          >
            <span
              className={classNames(
                allowOverride ? 'translate-x-6' : 'translate-x-1',
                'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
              )}
            />
          </Switch>
        </div> */}

        {/* Free-Text Block Section */}
        <div className="mt-2">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">
            Coach Cleo Health
          </h2>
          <p className="text-md text-gray-500 mb-2">
            Give Cleo specific instructions for constructing your DDx.
          </p>
          <div className="mt-2">
            <div className="relative">
              <div className="overflow-hidden rounded-lg shadow-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <textarea
                  rows={5}
                  value={instructions}
                  onChange={handleInputChange}
                  className="block w-full resize-none border-0 bg-transparent py-1.5 pr-24 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Enter custom instructions..."
                />
                <div className="absolute bottom-0 right-0 p-2 flex items-center">
                  <span className="text-sm text-gray-500 mr-2">
                    {charCount}/{maxChars}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* AI Involvement Section */}
        <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
          AI Involvement
        </h2>
        <div className="space-y-6">
          {mdmOptions.map((option) => (
            <div key={option} className="flex items-start">
              <Switch
                checked={mdmSettings[option]}
                onChange={() => handleDdxSettingChange(option)}
                className={classNames(
                  mdmSettings[option] ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 items-center rounded-full'
                )}
              >
                <span
                  className={classNames(
                    mdmSettings[option] ? 'translate-x-6' : 'translate-x-1',
                    'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                  )}
                />
              </Switch>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">{option}</p>
                <p className="text-sm text-gray-500">{mdmDescriptions[option]}</p>
              </div>
            </div>
          ))}
        </div>

        {/* DDx Formatting Section */}
        <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
          DDx Formatting
        </h2>
        <div className="space-y-4">
          {ddxFormattingOptions.map((option) => (
            <div
              key={option.name}
              className={classNames(
                'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                selectedDdxFormatting && selectedDdxFormatting.name === option.name
                  ? 'bg-indigo-50 border-indigo-200'
                  : 'border-gray-200'
              )}
              onClick={() => {
                if (selectedDdxFormatting && selectedDdxFormatting.name === option.name) {
                  setSelectedDdxFormatting(null);
                } else {
                  setSelectedDdxFormatting(option);
                }
              }}
            >
              <span
                className={classNames(
                  selectedDdxFormatting && selectedDdxFormatting.name === option.name
                    ? 'bg-indigo-600 border-transparent'
                    : 'bg-white border-gray-300',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                )}
                aria-hidden="true"
              >
                {selectedDdxFormatting && selectedDdxFormatting.name === option.name && (
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                )}
              </span>
              <div className="ml-3 flex flex-col">
                <span className="block text-sm font-medium text-gray-900">
                  {option.name}
                </span>
                <span className="block text-sm text-gray-500">
                  {option.description}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Floating Chatbot Icon */}
        {isDdxChatbotEnabled && (
          <>
        <button
          onClick={() => setIsChatbotOpen(true)}
          className="fixed bottom-20 right-6 z-50 bg-indigo-900 text-white rounded-full p-4 shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
        >
          <ChatBubbleLeftEllipsisIcon className="h-8 w-8" aria-hidden="true" />
        </button>

        {isChatbotOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-30">
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-4xl mx-auto h-[90vh] overflow-hidden">
              {/* Chatbot Interface */}
              <SettingsChatbot
                onClose={() => setIsChatbotOpen(false)}
                onApplyCustomInstruction={(feedback) => {
                  setInstructions((prevInstructions) => {
                    const newInstructions = prevInstructions ? `${prevInstructions}\n${feedback}` : feedback;
                    // Update character count
                    setCharCount(newInstructions.length);
                    return newInstructions.slice(0, maxChars); // Ensure it doesn't exceed maxChars
                  });
                }}
                chatbotType = 'ddx'
              />
            </div>
          </div>
        )}
        </>
        )}

      </div>
    </>
  );
}
