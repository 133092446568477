import React, { useState, useEffect, useCallback } from 'react';

import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../../authConfig';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

export const OrganizationMacrosPage = ({ onSuccessfulSave }) => {
  // State variables
  const { actorInfo } = useAuth();
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const [macroId, setMacroId] = useState(null);
  const [macroTitle, setMacroTitle] = useState('');
  const [macroCommand, setMacroCommand] = useState('');
  const [macroContent, setMacroContent] = useState('');
  const [macroSection, setMacroSection] = useState('reevaluation');
  const [isLoading, setIsLoading] = useState(false);
  const [macroList, setMacroList] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [isEditable, setIsEditable] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMacroId, setSelectedMacroId] = useState(null);
  const [isShareable, setIsShareable] = useState(true);

  const handleTokenExpiration = useCallback((error) => {
    if (error instanceof InteractionRequiredAuthError) {
      instance.logoutRedirect();
    } else {
      console.error('Error:', error);
    }
  }, [instance]);

  const upsertMacro = async () => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiUpsertOrganizationMacro.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          macro_id: macroId,
          title: macroTitle,
          command: macroCommand,
          content: macroContent,
          section: macroSection,
          is_shareable_within_org: isShareable,
          org_id: actorInfo.org_id
        })
      };

      const fetchResponse = await fetch(protectedResources.apiUpsertOrganizationMacro.endpoint, requestOptions);
      const data = await fetchResponse.json();

      if (data.success) {
        await getMacros();
        setSelectedMacroId(data.macro_id);
        onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
      } else {
        console.error('Error upserting macro:', data.error);
      }

    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error updating document:', error);
    }
    setIsLoading(false);
  };

  const deleteMacro = async () => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiDeleteMacro.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ macro_id: macroId })
      };

      const fetchResponse = await fetch(protectedResources.apiDeleteMacro.endpoint, requestOptions);
      const data = await fetchResponse.json();

      if (data.success) {
        resetForm();
        await getMacros();
        onSuccessfulSave('Macro deleted successfully');
      } else {
        console.error('Error deleting macro:', data.error);
      }
    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error deleting document:', error);
    }
    setIsLoading(false);
  };

  const handleMacroClick = (macro) => {
    setMacroId(macro.macro_id);
    setMacroTitle(macro.title);
    setMacroCommand(macro.command);
    setMacroContent(macro.content);
    setMacroSection(macro.section || 'reevaluation');
    setSelectedMacroId(macro.macro_id);
    setIsShareable(macro.is_shareable_within_org);
    setIsEditable(false);
  };

  const getMacros = useCallback(async () => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiGetOrganizationMacros.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ org_id: actorInfo.org_id })
      };

      const fetchResponse = await fetch(protectedResources.apiGetOrganizationMacros.endpoint, requestOptions);
      const data = await fetchResponse.json();

      if (data.macros && typeof data.macros === 'object') {
        const macros = Object.values(data.macros).map(macro => ({
          macro_id: macro.macro_id,
          title: macro.title,
          command: macro.command,
          content: macro.content,
          section: macro.section,
          is_shareable_within_org: macro.is_shareable_within_org,
          created_at: macro.created_at,
          updated_at: macro.updated_at
        }));
        setMacroList(macros);
      } else {
        console.error('Unexpected macros data structure:', data);
        setMacroList([]);
      }

    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error fetching macros:', error);
    }
    setIsLoading(false);
  }, [instance, accounts, actorInfo.org_id]);

  const isDuplicateTitle = (title) => {
    return macroList.some(macro => macro.title.toLowerCase() === title.toLowerCase());
  };

  const handleSaveClick = () => {
    if (!areFieldsFilled()) {
      alert("Please fill in all the required fields.");
      return;
    }

    if (selectedMacroId === null && isDuplicateTitle(macroTitle)) {
      alert("A macro with this title already exists. Please use a different title.");
      return;
    }

    upsertMacro();
    setIsEditable(false);
  };

  const areFieldsFilled = () => {
    return macroTitle.trim() !== '' && macroCommand.trim() !== '' && macroContent.trim() !== '';
  };

  const handleDeleteClick = () => {
    deleteMacro();
  };

  const goBack = () => {
    navigate('/er-dashboard');
  };

  const handleNewMacro = () => {
    resetForm();
  };

  const resetForm = () => {
    setMacroId(null);
    setMacroTitle('');
    setMacroCommand('');
    setMacroContent('');
    setMacroSection('reevaluation');
    setIsShareable(true);
    setSelectedMacroId(null);
    setIsEditable(true);
  };

  useEffect(() => {
    const loading = inProgress === InteractionStatus.Startup ||
      inProgress === InteractionStatus.HandleRedirect ||
      inProgress === InteractionStatus.Login ||
      inProgress === InteractionStatus.Logout;

    setIsLoading(loading);
    const timeoutId = setTimeout(() => {
      getMacros();
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [getMacros, inProgress]);

  return (
    <>
      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Division Macro Settings</h1>
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="flex">
            {/* Left Column */}
            <div className="w-1/3 pr-4">
              <button
                className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                onClick={handleNewMacro}>
                + New Empty Macro
              </button>
              <input
                type="text"
                placeholder="Search macros"
                className="mt-2 mb-4 p-2 border border-gray-300 rounded w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
                {isLoading ? (
                  <div className="flex justify-center items-center h-full">
                    <div className="spinner"></div>
                  </div>
                ) : macroList.length > 0 ? (
                  <ul className="space-y-0">
                    {macroList
                      .filter(macro => macro.title.toLowerCase().includes(searchQuery.toLowerCase()))
                      .map((m) => (
                        <li key={m.macro_id}
                          className={`cursor-pointer text-zinc-700 hover:bg-gray-100 p-2 ${m.macro_id === selectedMacroId ? 'bg-gray-200' : ''} border-b border-gray-200`}
                          onClick={() => handleMacroClick(m)}>
                          {m.title}
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p className="text-center text-gray-500 py-4">No macros found</p>
                )}
              </div>
            </div>

            {/* Right Column */}
            <div className="w-2/3 px-4">
              <div className="space-y-6">
                {/* Title */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                  <textarea
                    name="macroTitle"
                    className={`mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${selectedMacroId !== null && !isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : ''}`}
                    rows="1"
                    placeholder="Enter title here"
                    value={macroTitle}
                    onChange={(e) => setMacroTitle(e.target.value)}
                    disabled={selectedMacroId !== null && !isEditable}
                    required
                  />
                </div>

                {/* Command */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">When I say this during my patient encounter...</label>
                  <textarea
                    name="macroCommand"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    rows="2"
                    placeholder="Enter command here"
                    value={macroCommand}
                    onChange={(e) => setMacroCommand(e.target.value)}
                    required
                  />
                </div>

                {/* Content */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">Insert this content...</label>
                  <textarea
                    name="macroContent"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    rows="8"
                    placeholder="Enter content here"
                    value={macroContent}
                    onChange={(e) => setMacroContent(e.target.value)}
                    required
                  />
                </div>

                {/* Section */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">Into this section of my note...</label>
                  <select
                    name="macroSection"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={macroSection || 'reevaluation'}
                    onChange={(e) => setMacroSection(e.target.value)}
                  >
                    <option value="hpi">HPI</option>
                    <option value="ros">Review of Systems</option>
                    <option value="physical_exam">Physical Exam</option>
                    <option value="reevaluation">Evaluations</option>
                    <option value="mdm">MDM</option>
                  </select>
                </div>

                <div className="flex justify-between mt-4">
                  <button
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                    onClick={handleDeleteClick}>
                    Delete
                  </button>
                  <button
                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                    onClick={handleSaveClick}
                    disabled={!areFieldsFilled()}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationMacrosPage;