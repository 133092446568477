import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserCheck,
    faHandshake,
    faClock,
    faCommentDots,
    faUserFriends,
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

function PatientExperienceModal({ isOpen, onClose, criteriaData }) {
    const {
        acknowledgedPatient,
        introducedThemselves,
        providedDuration,
        providedExplanation,
        managedUpCareTeam,
    } = criteriaData;

    const criteriaList = [
        {
            title: 'Acknowledged Patient',
            description: 'You acknowledged and greeted the patient.',
            met: acknowledgedPatient,
            icon: faUserCheck,
        },
        {
            title: 'Introduced Yourself',
            description: 'You clearly stated your name and / or role in the care team.',
            met: introducedThemselves,
            icon: faHandshake,
        },
        {
            title: 'Provided Duration',
            description: 'You informed the patient about expected wait times and next steps.',
            met: providedDuration,
            icon: faClock,
        },
        {
            title: 'Provided Explanation',
            description: 'You explained procedures and treatments in patient-friendly terms.',
            met: providedExplanation,
            icon: faCommentDots,
        },
        {
            title: 'Managed Up Care Team',
            description: 'You referenced other care team members and their roles positively.',
            met: managedUpCareTeam,
            icon: faUserFriends,
        },
    ];

    const completedCriteria = criteriaList.filter((item) => item.met).length;
    const completionPercentage = (completedCriteria / criteriaList.length) * 100;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Patient Experience Feedback"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] max-w-[90vw]"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            style={{
                content: {
                    border: 'none',
                    background: 'none',
                    padding: 0,
                }
            }}
        >
            <div className="p-6 bg-white rounded-lg shadow-lg">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-indigo-900">Patient Experience Feedback</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-2xl" />
                    </button>
                </div>
                {/* Add disclaimer text */}
                <div className="mb-4">
                    <p className="text-sm text-gray-500 italic">
                        AI feedback - not a definitive assessment of communication.
                    </p>
                </div>
                <div className="mb-6">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                        <div
                            className="bg-green-500 h-4 rounded-full"
                            style={{ width: `${completionPercentage}%` }}
                        ></div>
                    </div>
                    <p className="text-sm text-gray-600 mt-2">
                        {completedCriteria} out of {criteriaList.length} criteria met.
                    </p>
                </div>
                <div className="space-y-4">
                    {criteriaList.map((criterion, index) => (
                        <div key={index} className="flex items-center">
                            <div className="mr-4">
                                <FontAwesomeIcon icon={criterion.icon} className="text-2xl text-indigo-900" />
                            </div>
                            <div className="flex-1">
                                <h3 className="text-lg font-semibold">{criterion.title}</h3>
                                <p className="text-sm text-gray-600">{criterion.description}</p>
                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon={criterion.met ? faCheckCircle : faTimesCircle}
                                    className={`text-2xl ${criterion.met ? 'text-green-500' : 'text-red-500'}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-6 text-right">
                    <button
                        onClick={onClose}
                        className="bg-indigo-900 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
                    >
                        Done
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default PatientExperienceModal;
