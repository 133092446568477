import React from 'react';

export default function SelectGroupDropdown({ selectedGroup, setSelectedGroup, availableGroups }) {
  return (
    <div>
      <label htmlFor="group-select" className="block text-sm font-medium text-gray-700">
        Select Group
      </label>
      <select
        id="group-select"
        name="group-select"
        value={selectedGroup?.group_id || ''}
        onChange={(e) => {
          const selected = availableGroups.find(group => group.group_id.toString() === e.target.value);
          setSelectedGroup(selected);
        }}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
                   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option value="">Select a group</option>
        {availableGroups.map((group) => (
          <option key={group.group_id} value={group.group_id}>
            {group.name}
          </option>
        ))}
      </select>
    </div>
  );
} 