// TimeSelector.jsx

import React from 'react';

function TimeSelector({
    selectedRange,
    onChange,
    granularity,
    onGranularityChange,
    smoothing,
    onSmoothingChange,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
}) {
    const options = ['Last Week', 'Last Month', 'Last Year'];
    const granularityOptions = ['Daily', 'Weekly', 'Monthly', 'Cumulative'];

    return (
        <div className="flex flex-col md:flex-row md:items-center md:space-x-6 space-y-4 md:space-y-0">
            {/* Time Range Selector */}
            <div className="flex items-center space-x-2">
                {options.map((option) => (
                    <button
                        key={option}
                        onClick={() => onChange(option)}
                        className={`px-4 py-2 rounded-full text-sm font-medium focus:outline-none transition-colors ${selectedRange === option
                            ? 'bg-indigo-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                            }`}
                    >
                        {option}
                    </button>
                ))}
            </div>

            {/* Custom Date Range Pickers */}
            {selectedRange === 'Custom Range' && (
                <div className="flex items-center space-x-2">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => onStartDateChange(e.target.value)}
                        className="p-2 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <span className="text-gray-600">to</span>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => onEndDateChange(e.target.value)}
                        className="p-2 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
            )}

            {/* Granularity Selector */}
            <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-600">Granularity:</span>
                {granularityOptions.map((option) => (
                    <button
                        key={option}
                        onClick={() => onGranularityChange(option.toLowerCase())}
                        className={`px-3 py-1 rounded-full text-sm font-medium focus:outline-none transition-colors ${granularity === option.toLowerCase()
                            ? 'bg-indigo-600 text-white'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                            }`}
                    >
                        {option}
                    </button>
                ))}
            </div>

            {/* Smoothing Toggle */}
            {/* <div className="flex items-center space-x-2">
                <label htmlFor="smoothing" className="text-sm text-gray-600">
                    Smoothing
                </label>
                <input
                    id="smoothing"
                    type="checkbox"
                    checked={smoothing}
                    onChange={(e) => onSmoothingChange(e.target.checked)}
                    className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
            </div> */}
        </div>
    );
}

export default TimeSelector;
