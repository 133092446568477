// GroupSignupCodes.jsx

import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Dialog } from '@headlessui/react';
import { protectedResources } from '../authConfig';
import { ExclamationTriangleIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

const GroupSignupCodes = ({ groupId, groupName, isOpen, onClose, setNotification }) => {
    const { instance, accounts } = useMsal();

    const [expiresAtOption, setExpiresAtOption] = useState('30'); // Default to 30 days
    const [codeValue, setCodeValue] = useState('');
    const [signupCodes, setSignupCodes] = useState([]);
    const [isLoadingSignupCodes, setIsLoadingSignupCodes] = useState(false);
    const [selectedSignupCode, setSelectedSignupCode] = useState(null);
    const [isDeleteSignupCodeModalOpen, setIsDeleteSignupCodeModalOpen] = useState(false);
    const subscriptionOptions = [
        { value: 'FREE_TRIAL', label: 'Individual Invoice' },
        { value: 'MONTHLY', label: 'Group Invoice' },
    ];
    const [subscriptionType, setSubscriptionType] = useState('FREE_TRIAL');

    const getSubscriptionLabel = (value) => {
        const option = subscriptionOptions.find(opt => opt.value === value);
        return option ? option.label : value;
    };


    // Fetch signup codes when the component mounts or groupId changes
    useEffect(() => {
        if (groupId && isOpen) {
            fetchSignupCodes();
        }
        // Reset state when modal is closed
        if (!isOpen) {
            resetState();
        }
    }, [groupId, isOpen]);

    const resetState = () => {
        setExpiresAtOption('30');
        setCodeValue('');
        setSignupCodes([]);
        setIsLoadingSignupCodes(false);
        setSelectedSignupCode(null);
        setIsDeleteSignupCodeModalOpen(false);
    };

    // Function to fetch signup codes
    const fetchSignupCodes = async () => {
        setIsLoadingSignupCodes(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetSignupCodesForEntity.scopes,
                    account: accounts[0],
                };
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestBody = {
                    code_type: 'GROUP_CODE',
                    group_id: groupId,
                };

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                };

                const response = await fetch(
                    protectedResources.apiGetSignupCodesForEntity.endpoint,
                    requestOptions
                );
                const data = await response.json();
                setSignupCodes(data.signup_codes);
            } catch (error) {
                console.error('Failed to fetch signup codes', error);
            } finally {
                setIsLoadingSignupCodes(false);
            }
        } else {
            setIsLoadingSignupCodes(false);
        }
    };

    // Function to create a new signup code
    const createSignupCode = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiCreateSignupCode.scopes,
                account: accounts[0],
            };
            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            // Calculate expires_at
            let expiresAtISO = null;
            if (expiresAtOption !== 'never') {
                const daysToAdd = parseInt(expiresAtOption, 10);
                const expiresAtDate = new Date();
                expiresAtDate.setDate(expiresAtDate.getDate() + daysToAdd);
                expiresAtISO = expiresAtDate.toISOString();
            }

            const requestBody = {
                group_id: groupId,
                expires_at: expiresAtISO,
                code_value: codeValue || null,
                subscription_type: subscriptionType,
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(
                protectedResources.apiCreateSignupCode.endpoint,
                requestOptions
            );
            if (response.ok) {
                // Handle success
                // Refresh the signup codes list
                fetchSignupCodes();
                // Optionally, display a success message
                if (setNotification) {
                    setNotification({ type: 'success', message: 'Signup code created successfully.' });
                }
            } else {
                // Handle error
                console.error('Failed to create signup code');
            }
        }
    };

    // Function to delete a signup code
    const deleteSignupCode = async (codeValue) => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiDeleteSignupCode.scopes,
                account: accounts[0],
            };
            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const requestBody = {
                code_value: codeValue,
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            };

            const response = await fetch(
                protectedResources.apiDeleteSignupCode.endpoint,
                requestOptions
            );
            if (response.ok) {
                // Handle success
                setIsDeleteSignupCodeModalOpen(false);
                // Refresh the signup codes list
                fetchSignupCodes();
                // Optionally, display a success message
                if (setNotification) {
                    setNotification({ type: 'success', message: 'Signup code deleted successfully.' });
                }
            } else {
                // Handle error
                console.error('Failed to delete signup code');
            }
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto w-full max-w-4xl bg-white rounded shadow-lg p-6">
                    <Dialog.Title className="text-lg font-bold mb-4">
                        Manage Signup Codes
                    </Dialog.Title>
                    <p className="mb-4">
                        You are managing signup codes for the group{' '}
                        <span className="font-medium">{groupName}</span>.
                    </p>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">
                                Expires In
                            </label>
                            <div className="space-y-2">
                                {['30', '60', '90', 'never'].map((value) => (
                                    <div className="flex items-center" key={value}>
                                        <input
                                            id={`expires_${value}`}
                                            name="expires_at"
                                            type="radio"
                                            value={value}
                                            checked={expiresAtOption === value}
                                            onChange={() => setExpiresAtOption(value)}
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                            htmlFor={`expires_${value}`}
                                            className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                            {value === 'never' ? 'Never' : `${value} Days`}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">
                                Subscription Type
                            </label>
                            <select
                                value={subscriptionType}
                                onChange={(e) => setSubscriptionType(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            >
                                {subscriptionOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">
                                Code Value (Optional)
                            </label>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md"
                                value={codeValue}
                                onChange={(e) => setCodeValue(e.target.value)}
                            />
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={createSignupCode}
                                className="px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
                            >
                                Create Code
                            </button>
                        </div>
                    </div>
                    {/* Display list of existing signup codes */}
                    <div className="mt-6">
                        {isLoadingSignupCodes ? (
                            <div className="flex items-center">
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                                Loading signup codes...
                            </div>
                        ) : (
                            <>
                                {signupCodes && signupCodes.length > 0 ? (
                                    <table className="min-w-full bg-white border border-gray-200 rounded-md">
                                        <thead>
                                            <tr>
                                                <th className="py-3 px-4 text-left bg-gray-50">Code</th>
                                                <th className="py-3 px-4 text-left bg-gray-50">Created At</th>
                                                <th className="py-3 px-4 text-left bg-gray-50">Expires At</th>
                                                <th className="py-3 px-4 text-left bg-gray-50">Num Uses</th>
                                                <th className="py-3 px-4 text-left bg-gray-50">Subscription Type</th>
                                                <th className="py-3 px-4 text-left bg-gray-50">Status</th>
                                                <th className="py-3 px-4 bg-gray-50"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {signupCodes.map((code, idx) => (
                                                <tr
                                                    key={code.code}
                                                    className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                                >
                                                    <td className="py-3 px-4">{code.code}</td>
                                                    <td className="py-3 px-4">
                                                        {new Date(code.created_at).toLocaleString()}
                                                    </td>
                                                    <td className="py-3 px-4">
                                                        {code.expires_at
                                                            ? new Date(code.expires_at).toLocaleString()
                                                            : 'Never'}
                                                    </td>
                                                    <td className="py-3 px-4">{code.num_uses}</td>
                                                    <td className="py-3 px-4">{getSubscriptionLabel(code.subscription_type)}</td>
                                                    <td className="py-3 px-4">
                                                        {code.is_expired ? 'Expired' : 'Active'}
                                                    </td>
                                                    <td className="py-3 px-4 text-right">
                                                        <button
                                                            onClick={() => {
                                                                setSelectedSignupCode(code);
                                                                setIsDeleteSignupCodeModalOpen(true);
                                                            }}
                                                            className="text-red-600 hover:text-red-800"
                                                        >
                                                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                            <span className="sr-only">Delete code</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="mt-4 flex items-center text-gray-500">
                                        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
                                        <span>No signup codes available.</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                        >
                            Close
                        </button>
                    </div>
                </Dialog.Panel>
            </div>

            {/* Delete Signup Code Confirmation Modal */}
            <Dialog
                open={isDeleteSignupCodeModalOpen}
                onClose={() => setIsDeleteSignupCodeModalOpen(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
                        <Dialog.Title className="text-lg font-semibold mb-4">
                            Confirm Deletion
                        </Dialog.Title>
                        <p>
                            Are you sure you want to delete the signup code{' '}
                            <span className="font-medium">{selectedSignupCode?.code}</span>?
                        </p>
                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                onClick={() => setIsDeleteSignupCodeModalOpen(false)}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => deleteSignupCode(selectedSignupCode.code)}
                                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                            >
                                Delete Code
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Dialog>
    );
};

export default GroupSignupCodes;