// RecordingManager.js
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

class RecordingManager {
    constructor() {
        this.recognizers = {};
        this.isRecording = {};
        this.currentRecordingSection = null;
        this.transcriptions = {};
        this.callbacks = {};
        this.shouldSendTranscription = {};
    }

    startRecording(section, subscriptionKey, serviceRegion, onTranscriptionComplete) {
        if (this.isRecording[section] && this.recognizers[section]) {
            // Already recording
            return;
        }

        if (this.currentRecordingSection && this.currentRecordingSection !== section) {
            // Another section is recording
            console.log(`Cannot start recording for ${section} because ${this.currentRecordingSection} is recording.`);
            return;
        }

        const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

        this.transcriptions[section] = ''; // Reset transcription

        recognizer.recognized = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                this.transcriptions[section] += ' ' + e.result.text;
            } else if (e.result.reason === sdk.ResultReason.NoMatch) {
            }
        };

        recognizer.sessionStopped = (s, e) => {
            const transcription = this.transcriptions[section].trim();

            // Check if we should send the transcription
            if (this.shouldSendTranscription[section]) {
                if (transcription) {
                    if (onTranscriptionComplete) {
                        try {
                            onTranscriptionComplete(section, transcription);
                        } catch (error) {
                        }
                    }
                } else {
                    console.log(`No transcription for ${section}`);
                }
            } else {
                console.log(`Transcription will not be sent for ${section} as per flag.`);
            }

            // Clean up
            recognizer.close();
            this.recognizers[section] = null;
            this.isRecording[section] = false;
            this.currentRecordingSection = null;
            delete this.shouldSendTranscription[section]; // Clean up the flag
        };


        recognizer.canceled = (s, e) => {
            console.error(`CANCELED (${section}): Reason=${e.reason}`);
            if (e.reason === sdk.CancellationReason.Error) {
                console.error(`CANCELED (${section}): ErrorCode=${e.errorCode}`);
                console.error(`CANCELED (${section}): ErrorDetails=${e.errorDetails}`);
            }
            recognizer.stopContinuousRecognitionAsync();
            this.recognizers[section] = null;
            this.isRecording[section] = false;
            this.currentRecordingSection = null;
        };

        recognizer.startContinuousRecognitionAsync(
            () => {
                console.log(`Recognition started for ${section}.`);
                this.isRecording[section] = true;
                this.recognizers[section] = recognizer;
                this.currentRecordingSection = section;
            },
            (err) => {
                console.error(`Error starting recognition for ${section}:`, err);
                this.isRecording[section] = false;
                this.currentRecordingSection = null;
            }
        );
    }

    stopRecording(section, shouldSendTranscription = true) {
        if (this.isRecording[section] && this.recognizers[section]) {
            console.log(`Stopping recognition for ${section}.`);
            // Store the flag indicating whether to send the transcription
            this.shouldSendTranscription[section] = shouldSendTranscription;

            this.recognizers[section].stopContinuousRecognitionAsync(
                () => {
                    console.log(`StopContinuousRecognitionAsync successful for ${section}.`);
                },
                (err) => {
                    console.error(`Error stopping recognition for ${section}:`, err);
                }
            );
        } else {
            console.log(`No active recognizer to stop for ${section}.`);
        }
    }

    stopAllRecordings() {
        for (const section in this.isRecording) {
            if (this.isRecording[section]) {
                this.stopRecording(section, false); // Do not send transcription
            }
        }
    }

}

const recordingManager = new RecordingManager();
export default recordingManager;
