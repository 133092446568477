// SettingsChatbot.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../contexts/AuthProvider';
import { protectedResources } from '../authConfig';
import { PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react'; // For modal dialogs

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SettingsChatbot({ onClose, onApplyCustomInstruction, chatbotType }) {
  const { instance, accounts } = useMsal();
  const { actorInfo, isLoading } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);

  // State for confirmation modal
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  // State for feedback modal
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  const sendMessage = async () => {
    if (!input.trim()) return;

    // Construct the message history by mapping messages to the required format
    const messageHistory = messages.map((msg) => ({
      sender: msg.sender === 'bot' ? 'model' : msg.sender,
      text: msg.text,
    }));

    // The current message is the user's input
    const currentMessage = input;

    // Add the user's message to messages state
    const userMessage = { sender: 'user', text: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsSending(true);
    setIsTyping(true);

    let apiResource = protectedResources.apiChatbotAddMessage;
    if (chatbotType === "critical_care") {
      apiResource = protectedResources.apiChatbotAnalyzeCriticalCare;
    }

    try {
      const request = {
        scopes: apiResource.scopes,
        account: accounts[0],
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const res = await fetch(apiResource.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_history: messageHistory,
          current_message: currentMessage,
        }),
      });

      const data = await res.json();

      // Simulate typing delay
      setTimeout(() => {
        const botMessage = { sender: 'bot', text: data.model_response };
        setMessages((prev) => [...prev, botMessage]);
        setIsTyping(false);
        setIsSending(false);
      }, 1000); // Adjust delay as needed
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
      setIsTyping(false);
      setIsSending(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  // Handle close button click
  const handleCloseClick = () => {
    setIsConfirmationOpen(true);
  };

  // Handle "Yes" in confirmation modal
  const handleConfirmYes = async () => {
    setIsConfirmationOpen(false);
    setIsFeedbackLoading(true);
    setIsFeedbackModalOpen(true);

    // Prepare message history for the API
    const messageHistory = messages.map((msg) => ({
      sender: msg.sender === 'bot' ? 'model' : msg.sender,
      text: msg.text,
    }));

    try {
      const request = {
        scopes: protectedResources.apiChatbotActionFeedback.scopes,
        account: accounts[0],
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const res = await fetch(protectedResources.apiChatbotActionFeedback.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_history: messageHistory,
        }),
      });

      const data = await res.json();

      setFeedbackContent(data.model_response);
      setIsFeedbackLoading(false);
    } catch (error) {
      console.error('Error fetching feedback:', error);
      setIsFeedbackLoading(false);
      setFeedbackContent('An error occurred while generating feedback.');
    }
  };

  // Handle "No" in confirmation modal
  const handleConfirmNo = () => {
    setIsConfirmationOpen(false);
    // Reset the chatbot state
    resetChatbotState();
    // Close the chatbot modal
    onClose();
  };

  // Handle applying custom instruction
  const applyCustomInstruction = (feedback) => {
    setIsFeedbackModalOpen(false);
    // Call the function passed from parent to update instructions
    onApplyCustomInstruction(feedback);
    // Reset the chatbot state
    resetChatbotState();
    // Close the chatbot modal
    onClose();
  };

  // Handle closing the feedback modal without applying
  const handleFeedbackModalClose = () => {
    setIsFeedbackModalOpen(false);
    // Reset the chatbot state
    resetChatbotState();
    // Close the chatbot modal after feedback is viewed
    onClose();
  };

  // Function to reset the chatbot state
  const resetChatbotState = () => {
    setMessages([]);
    setInput('');
    setIsSending(false);
    setIsTyping(false);
    setFeedbackContent('');
  };

  return (
    <div className="flex flex-col h-full">
      {/* Chat Header */}
      <div className="py-4 px-6 bg-white shadow-sm flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-gray-900">Chat with Cleo</h2>
        <button
          onClick={handleCloseClick}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto px-6 py-4 bg-gray-50 border-t border-gray-200">
        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={classNames(
              msg.sender === 'user' ? 'justify-end' : 'justify-start',
              'flex mb-4'
            )}
          >
            <div
              className={classNames(
                msg.sender === 'user'
                  ? 'bg-indigo-900 text-white'
                  : 'bg-gray-200 text-gray-900',
                'px-4 py-2 rounded-lg max-w-md'
              )}
            >
              {msg.text}
            </div>
          </div>
        ))}

        {/* Typing Indicator */}
        {isTyping && (
          <div className="flex justify-start mb-4">
            <div className="bg-gray-200 text-gray-900 px-4 py-2 rounded-lg max-w-md">
              <TypingIndicator />
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Input Box */}
      <div className="px-6 py-4 bg-white border-t border-gray-200">
        <div className="flex items-center">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            rows={1}
            placeholder="Type your message..."
            className="flex-1 resize-none border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-900"
          />
          <button
            onClick={sendMessage}
            disabled={isSending}
            className={classNames(
              'ml-4 inline-flex items-center justify-center p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-900',
              isSending
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-900 hover:bg-indigo-700 text-white'
            )}
          >
            <PaperAirplaneIcon className="h-5 w-5 transform rotate-45" />
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Dialog
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen px-4">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-sm w-full">
            <div className="p-6">
              <Dialog.Title className="text-lg font-medium text-gray-900">
                Turn Conversation into Actionable Feedback?
              </Dialog.Title>
              <div className="mt-4">
                <p className="text-sm text-gray-500">
                  Would you like to turn this conversation into actionable feedback?
                </p>
              </div>

              <div className="mt-6 flex justify-end space-x-2">
                <button
                  onClick={handleConfirmNo}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
                >
                  No
                </button>
                <button
                  onClick={handleConfirmYes}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-900 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Feedback Modal */}
      <Dialog
        open={isFeedbackModalOpen}
        onClose={handleFeedbackModalClose}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen px-4">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full">
            <div className="p-6">
              <Dialog.Title className="text-lg font-medium text-gray-900">
                Actionable Feedback
              </Dialog.Title>

              {isFeedbackLoading ? (
                <div className="mt-4 flex justify-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="mt-4">
                  <p className="text-sm text-gray-700 whitespace-pre-wrap">
                    {feedbackContent}
                  </p>
                </div>
              )}

              <div className="mt-6 flex justify-end space-x-2">
                <button
                  onClick={handleFeedbackModalClose}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={() => applyCustomInstruction(feedbackContent)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-900 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none"
                >
                  Apply Custom Instruction
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

// Typing Indicator Component
function TypingIndicator() {
  return (
    <div className="flex space-x-1">
      <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce"></div>
      <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce delay-75"></div>
      <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce delay-150"></div>
    </div>
  );
}

// Loading Spinner Component
function LoadingSpinner() {
  return (
    <svg
      className="animate-spin h-8 w-8 text-indigo-900"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v8H4z"
      ></path>
    </svg>
  );
}
