// components/WhenToApplyIcon.jsx  

import React from 'react';
import { GlobeAltIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';

const WhenToApplyIcon = ({ whenToApply }) => {
    let icon;
    let colorClass;

    if (whenToApply.toLowerCase() === 'global') {
        icon = <GlobeAltIcon className="h-5 w-5" />;
        colorClass = 'text-indigo-500';
    } else {
        icon = <ClipboardDocumentIcon className="h-5 w-5" />;
        colorClass = 'text-gray-500';
    }

    return <div className={`${colorClass}`}>{icon}</div>;
};

export default WhenToApplyIcon;  