import { useState, useEffect, useCallback, useMemo } from 'react';
import { Switch } from '@headlessui/react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { UserGroupIcon, UserIcon, BuildingOfficeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { startOfMonth, subDays } from 'date-fns';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../../authConfig';

import UserTable from './UserTable';

function UserManagementContent({ orgId }) {
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [organizationStatistics, setOrganizationStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [divisionStatistics, setDivisionStatistics] = useState(null);
  const [userStatistics, setUserStatistics] = useState(null);
  const [typeSelected, setTypeSelected] = useState('organization');
  const [selectedUser, setSelectedUser] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoadingDivisions, setIsLoadingDivisions] = useState(false);
  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);
  const [isLoadingActorPatientTotals, setIsLoadingActorPatientTotals] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('All Time');
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const { instance, accounts } = useMsal();
  const [searchQuery, setSearchQuery] = useState('');

  const fetchAggOrgData = useCallback(async (timeOption = 'All Time') => {
    setIsLoading(true);

    if (accounts.length > 0 && orgId) {
      const request = {
        scopes: protectedResources.apiFetchAggOrgData.scopes,
        account: accounts[0],
      };

      try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;

        const { startDate, endDate } = getDateRange(timeOption);

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            org_id: orgId,
            start_date: startDate ? startDate.toISOString() : null,
            end_date: endDate ? endDate.toISOString() : null,
          }),
        };

        const baseUrl = protectedResources.apiFetchAggOrgData.endpoint;

        const fetchResponse = await fetch(baseUrl, requestOptions);

        if (!fetchResponse.ok) {
          throw new Error('Failed to fetch organization data');
        }

        const response = await fetchResponse.json();

        setOrganizationStatistics(response.org_data);

        // Remove the user data fetching from here
      } catch (error) {
        console.error('Error fetching organization data:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log('accounts or orgId not available');
      setIsLoading(false);
    }
  }, [accounts, instance, orgId]);


  // Function to fetch users for the organization
  const getUsersForOrg = useCallback(async (timeOption = selectedTimeFilter) => {
    setIsLoadingUsers(true);

    if (accounts.length > 0 && orgId) {
      const request = {
        scopes: protectedResources.apiGetUsersForOrg.scopes,
        account: accounts[0],
      };

      try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;

        const { startDate, endDate } = getDateRange(timeOption);
        console.log(startDate)
        console.log(endDate)

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            org_id: orgId,
            start_date: startDate ? startDate.toISOString() : null,
            end_date: endDate ? endDate.toISOString() : null,
          }),
        };

        const baseUrl = protectedResources.apiGetUsersForOrg.endpoint;

        const fetchResponse = await fetch(baseUrl, requestOptions);

        if (!fetchResponse.ok) {
          throw new Error('Failed to fetch users');
        }

        const response = await fetchResponse.json();

        setUsers(response.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoadingUsers(false);
      }
    } else {
      console.log('accounts or orgId not available');
      setIsLoadingUsers(false);
    }
  }, [accounts, instance, orgId, selectedTimeFilter]);

  // Function to fetch divisions for the organization
  const getDivisionsForOrg = useCallback(async () => {
    setIsLoadingDivisions(true);

    if (accounts.length > 0 && orgId) {
      const request = {
        scopes: protectedResources.apiGetDivisionsForOrg.scopes,
        account: accounts[0],
      };

      try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ org_id: orgId }),
        };

        const baseUrl = protectedResources.apiGetDivisionsForOrg.endpoint;

        const fetchResponse = await fetch(baseUrl, requestOptions);

        if (!fetchResponse.ok) {
          throw new Error('Failed to fetch divisions');
        }

        const response = await fetchResponse.json();

        setDivisions(response.divisions);
        console.log('divisions', response.divisions);
      } catch (error) {
        console.error('Error fetching divisions:', error);
      } finally {
        setIsLoadingDivisions(false);
      }
    } else {
      console.log('accounts or orgId not available');
      setIsLoadingDivisions(false);
    }
  }, [accounts, instance, orgId]);

  const getDateRange = (timeOption) => {
    const now = new Date();
    switch (timeOption) {
      case 'Last 30 days':
        return {
          startDate: subDays(now, 30),
          endDate: now
        };
      case 'This Month':
        return {
          startDate: startOfMonth(now),
          endDate: now
        };
      case 'All Time':
      default:
        return {
          startDate: null,
          endDate: null
        };
    }
  };

  const fetchAggDivisionData = useCallback(async (divisionId, timeOption = 'All Time') => {
    setIsLoadingStatistics(true);

    if (accounts.length > 0 && orgId && divisionId) {
      const request = {
        scopes: protectedResources.apiFetchAggDivisionData.scopes,
        account: accounts[0],
      };

      try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;

        const { startDate, endDate } = getDateRange(timeOption);

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            org_id: orgId,
            division_id: divisionId,
            start_date: startDate ? startDate.toISOString() : null,
            end_date: endDate ? endDate.toISOString() : null,
          }),
        };

        const baseUrl = protectedResources.apiFetchAggDivisionData.endpoint;

        const fetchResponse = await fetch(baseUrl, requestOptions);

        if (!fetchResponse.ok) {
          throw new Error('Failed to fetch division statistics');
        }

        const response = await fetchResponse.json();

        setDivisionStatistics(response.division_data);
        console.log('divisionStatistics', response.division_data);
        console.log('organizationStatistics', organizationStatistics);
        setTypeSelected('division');

        // Fetch user data for the division
        const userDataResponse = await fetch(protectedResources.apiGetUsersForOrg.endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            org_id: orgId,
            division_id: divisionId,
            start_date: startDate ? startDate.toISOString() : null,
            end_date: endDate ? endDate.toISOString() : null,
          }),
        });

        if (!userDataResponse.ok) {
          throw new Error('Failed to fetch user data for division');
        }

        const userData = await userDataResponse.json();
        setUsers(userData.users);
      } catch (error) {
        console.error('Error fetching division statistics:', error);
      } finally {
        setIsLoadingStatistics(false);
      }
    } else {
      console.log('accounts, orgId, or divisionId not available');
      setIsLoadingStatistics(false);
    }
  }, [accounts, instance, orgId, organizationStatistics]);

  /// Fetch users and divisions when the component mounts or orgId changes
  useEffect(() => {
    if (accounts.length > 0 && orgId) {
      console.log('Triggering getUsersForOrg and getDivisionsForOrg');
      getUsersForOrg();
      getDivisionsForOrg();
      fetchAggOrgData(); // Move this here
    } else {
      console.log('accounts or orgId not ready yet');
    }
  }, [accounts, orgId, getUsersForOrg, getDivisionsForOrg, fetchAggOrgData]);

  // Fetch organization statistics after users have been fetched
  useEffect(() => {
    if (users.length > 0 && accounts.length > 0 && orgId) {
      fetchAggOrgData();
    }
  }, [users, accounts, orgId, fetchAggOrgData]);
  // Memoized filtered users  
  const filteredUsers = useMemo(() => {
    if (!users || users.length === 0) return [];

    let filtered = users;

    // Filter by division  
    if (selectedDivisionId) {
      filtered = filtered.filter((user) => user.division_id === selectedDivisionId);
    }

    // Filter by search query  
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      filtered = filtered.filter((user) => {
        const fullName = user.full_name ? user.full_name.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';
        return (
          fullName.includes(searchLower) ||
          email.includes(searchLower)
        );
      });
    }

    return filtered;
  }, [users, selectedDivisionId, searchQuery]);


  const handleDivisionToggle = (division) => {
    if (division === null) {
      setSelectedDivisionId(null);
      setDivisionStatistics(null);
      setTypeSelected('organization');
      fetchAggOrgData(selectedTimeFilter);
    } else {
      const divisionId = division.division_id;
      if (selectedDivisionId === divisionId) {
        setSelectedDivisionId(null);
        setDivisionStatistics(null);
        setTypeSelected('organization');
        fetchAggOrgData(selectedTimeFilter);
      } else {
        setSelectedDivisionId(divisionId);
        fetchAggDivisionData(divisionId, selectedTimeFilter);
      }
    }
    setSelectedUser(null);
  };

  // Handle time filter changes
  const handleTimeFilter = (timeOption) => {
    setSelectedTimeFilter(timeOption);
    if (typeSelected === 'organization') {
      fetchAggOrgData(timeOption);
    } else if (typeSelected === 'division' && selectedDivisionId) {
      fetchAggDivisionData(selectedDivisionId, timeOption);
    }
  };

  // Filter users by time
  const filterByTime = (users, timeFilter) => {
    const now = new Date();
    let startDate;

    if (timeFilter === 'Last 30 days') {
      startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    } else if (timeFilter === 'This Month') {
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    } else {
      // 'All Time' or any unrecognized filter
      startDate = new Date(0); // Beginning of time
    }

    return users.map((user) => ({
      ...user,
      documents: (user.documents || []).filter((doc) => {
        const docDate = new Date(doc.created_at);
        return docDate >= startDate && docDate <= now;
      }),
    }));
  };

  const handleUpdateUser = async (updatedUser) => {
    // Implement user update logic here
    console.log('Updating user:', updatedUser);
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.actor_id === updatedUser.actor_id ? updatedUser : user))
    );
    setSelectedUser(updatedUser);
  };

  const handleDeleteUser = async (userId) => {
    // Implement user deletion logic here
    console.log('Deleting user:', userId);
  };

  const selectedDivision = selectedDivisionId
    ? divisions.find((div) => div.division_id === selectedDivisionId)
    : null;

  return (
    <div className="flex flex-col w-full bg-gray-100">
      {/* Header */}
      {/* Header */}
      <div className="bg-white">
        {/* Header and Time Filters */}
        <div className="flex items-center justify-between px-4 py-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
            Statistics and User Management
          </h1>

          {/* Time Filter */}
          <div className="flex items-center space-x-2">
            {['Last 30 days', 'This Month', 'All Time'].map((timeOption) => (
              <button
                key={timeOption}
                onClick={() => handleTimeFilter(timeOption)}
                className={`px-3 py-1 rounded-full text-sm font-medium ${selectedTimeFilter === timeOption
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-800'
                  }`}
              >
                {timeOption}
              </button>
            ))}
          </div>
        </div>

        {/* Search and Divisions */}
        <div className="px-4 pb-2 sm:px-6 lg:px-8 flex items-center justify-between space-x-8">

          {/* Search Input */}
          <div className="flex items-center">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                type="search"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Search name/email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
              />
            </div>
          </div>
          {/* Divisions as bubbles */}

          {isLoadingDivisions ? (
            <div className="flex justify-center items-center h-16">
              <div className="w-8 h-8 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
            </div>
          ) : divisions.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {/* Add the "All" option */}
              <span
                onClick={() => handleDivisionToggle(null)}
                className={`cursor-pointer inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${selectedDivisionId === null
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-800'
                  }`}
              >
                All
              </span>
              {divisions.map((division) => (
                <span
                  key={division.division_id}
                  onClick={() => handleDivisionToggle(division)}
                  className={`cursor-pointer inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${selectedDivisionId === division.division_id
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-200 text-gray-800'
                    }`}
                >
                  {division.division_name}
                </span>
              ))}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center h-16 text-gray-500">
              <BuildingOfficeIcon className="mb-2 w-6 h-6 text-gray-400" />
              <p>No divisions found</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex overflow-hidden flex-1">
        {/* Users column */}
        {isLoadingUsers ? (
          <div className="flex flex-col" style={{ width: '25%' }}>
            <div className="flex-1 flex justify-center items-center">
              <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col" style={{ width: '60%' }}>
            <UserTable
              filteredUsers={filteredUsers}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </div>
        )}

        {/* Details column */}
        <div className="flex-1 flex flex-col">
          {isLoading ? (
            <div className="flex flex-1 justify-center items-center bg-white">
              <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
            </div>
          ) : selectedUser ? (
            <UserDetailsColumn
              selectedUser={selectedUser}
              divisions={divisions}
              onUpdateUser={handleUpdateUser}
              onDeleteUser={handleDeleteUser}
            />
          ) : selectedDivisionId && divisionStatistics ? (
            <DivisionDetailsColumn divisionStatistics={divisionStatistics} />
          ) : (
            <OrganizationDetailsColumn statistics={organizationStatistics} />
          )}
        </div>
      </div>
    </div>
  );
}






function UserDetailsColumn({ selectedUser, onUpdateUser, onDeleteUser }) {
  const [userUsageStats, setUserUsageStats] = useState([]);
  const [isLoadingUserStats, setIsLoadingUserStats] = useState(false);
  const [userStatsError, setUserStatsError] = useState(null);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const { instance, accounts } = useMsal();


  useEffect(() => {
    if (selectedUser && selectedUser.actor_id) {
      setIsLoadingUserStats(true);
      fetchUserUsageStats(selectedUser.actor_id);
    } else {
      setUserUsageStats([]);
    }
  }, [selectedUser]);

  const fetchUserUsageStats = async (actorId) => {
    setUserStatsError(null);

    if (accounts.length > 0 && actorId) {
      const request = {
        scopes: protectedResources.apiFetchAggActorStatistics.scopes,
        account: accounts[0],
      };

      try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        const token = tokenResponse.accessToken;

        const requestBody = {
          org_id: selectedUser.org_id,
          actor_id: actorId,
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        };

        const baseUrl = protectedResources.apiFetchAggActorStatistics.endpoint;

        const response = await fetch(baseUrl, requestOptions);

        if (!response.ok) {
          throw new Error('Failed to fetch user statistics');
        }

        const data = await response.json();

        const usageStats = data.actor_data.map((item) => ({
          date: item.date,
          document_count: item.total_documents,
        }));

        // Fill in missing dates with zeros  
        const filledUsageStats = fillMissingDates(usageStats);

        setUserUsageStats(filledUsageStats);
      } catch (error) {
        console.error('Error fetching user statistics:', error);
        setUserStatsError('Failed to fetch user statistics.');
      } finally {
        setIsLoadingUserStats(false);
      }
    } else {
      console.warn('Accounts or actorId not available.');
      setIsLoadingUserStats(false);
    }
  };

  const fillMissingDates = (data) => {
    if (data.length === 0) return [];

    // Parse the input dates and sort them  
    let sortedData = data
      .map((item) => ({
        ...item,
        date: new Date(item.date),
      }))
      .sort((a, b) => a.date - b.date);

    const startDate = sortedData[0].date;
    const endDate = sortedData[sortedData.length - 1].date;

    const dateMap = new Map();
    sortedData.forEach((item) => {
      const dateKey = item.date.toISOString().split('T')[0];
      dateMap.set(dateKey, item.document_count);
    });

    const filledData = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dateKey = currentDate.toISOString().split('T')[0];
      const documentCount = dateMap.get(dateKey) || 0;

      filledData.push({
        date: dateKey,
        document_count: documentCount,
      });

      // Move to the next day  
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return filledData;
  };

  const handleToggleActive = () => {
    if (selectedUser.actor_status === 'ACTIVE') {
      setIsDeactivateDialogOpen(true);
    } else {
      updateUserStatus('ACTIVE');
    }
  };

  const confirmDeactivate = () => {
    updateUserStatus('INACTIVE');
    setIsDeactivateDialogOpen(false);
  };

  const updateUserStatus = (newStatus) => {
    const updatedUser = {
      ...selectedUser,
      actor_status: newStatus
    };
    onUpdateUser(updatedUser);
  };

  const formatRole = (role) => {
    if (!role) return 'N/A';
    return role === 'EMERGENCY_PHYSICIAN' ? 'Physician' : 'APP';
  };

  return (
    <div className="overflow-y-auto flex-1 bg-white">
      <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">User Details</h2>
      {selectedUser ? (
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <span className="mr-2 text-sm font-medium text-gray-500">Active</span>
              <Switch
                checked={selectedUser.actor_status === 'ACTIVE'}
                onChange={handleToggleActive}
                className={`${selectedUser.actor_status === 'ACTIVE' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className={`${selectedUser.actor_status === 'ACTIVE' ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
              </Switch>
            </div>
          </div>
          <dl className="divide-y divide-gray-200">
            {[
              { label: 'Full name', value: selectedUser.full_name },
              { label: 'Email address', value: selectedUser.email },
              { label: 'Role', value: formatRole(selectedUser.actor_specialty) },
              { label: 'Division', value: selectedUser.division },
              { label: 'Total Patients Seen', value: selectedUser.patients_seen || 0 },
              {
                label: 'Last Patient Seen',
                value: selectedUser.last_patient_seen
                  ? new Date(selectedUser.last_patient_seen).toLocaleDateString()
                  : 'N/A',
              },
            ].map((item) => (
              <div key={item.label} className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {item.value}
                </dd>
              </div>
            ))}
          </dl>
          <div className="mt-4">
            <h3 className="mb-4 text-lg font-medium text-gray-900">Charts Created</h3>
            {isLoadingUserStats ? (
              <div className="flex justify-center items-center h-48">
                <div className="w-8 h-8 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
              </div>
            ) : userStatsError ? (
              <div className="flex justify-center items-center h-48">
                <p className="text-sm text-red-500">{userStatsError}</p>
              </div>
            ) : userUsageStats.length === 0 ? (
              <div className="flex justify-center items-center h-48">
                <p className="text-sm text-gray-500">No charts available</p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={userUsageStats}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="document_count" stroke="#4f46e5" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
          <UserIcon className="mb-4 w-12 h-12 text-gray-400" />
          <p>Select a user to view details</p>
        </div>
      )}

      <Dialog open={isDeactivateDialogOpen} onClose={() => setIsDeactivateDialogOpen(false)} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="overflow-y-auto fixed inset-0 z-10 w-screen">
          <div className="flex justify-center items-end p-4 min-h-full text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start">
                <div className="flex flex-shrink-0 justify-center items-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon aria-hidden="true" className="w-6 h-6 text-red-600" />
                </div>
                <div className="text-leading sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Deactivate user
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to deactivate this user? They will no longer be able to access their Cleo account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={confirmDeactivate}
                  className="inline-flex justify-center px-3 py-2 mt-3 w-full text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                >
                  Deactivate
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setIsDeactivateDialogOpen(false)}
                  className="inline-flex justify-center px-3 py-2 mt-3 w-full text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}



function DivisionDetailsColumn({ divisionStatistics }) {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  // Sort the statistics data by date
  const sortedDivisionStats = [...divisionStatistics].sort((a, b) => new Date(a.date) - new Date(b.date));

  // Compute total patients and average patients per day
  const totalPatients = divisionStatistics.reduce((acc, item) => acc + item.total_patients, 0);
  const averagePatientsPerDay = (totalPatients / divisionStatistics.length).toFixed(0);

  // Function to handle CSV export (if needed)
  const handleExportCSV = (month) => {
    // Implement CSV export logic similar to OrganizationDetailsColumn
  };

  return (
    <div className="overflow-y-auto flex-1 bg-white">
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-700">Division Details</h2>
        {/* Add export button if needed */}
      </div>
      <div className="p-4">
        <dl className="divide-y divide-gray-200">
          {[
            { label: 'Total Patients', value: totalPatients },
            { label: 'Average Patients per Day', value: averagePatientsPerDay },
          ].map((item) => (
            <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
              <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item.value}
              </dd>
            </div>
          ))}
        </dl>

        <div className="px-4 py-5 border-t border-gray-200">
          <h4 className="mb-2 font-medium text-gray-900 text-md">Daily Count of Patients and Providers</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={sortedDivisionStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="total_patients" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="right" type="monotone" dataKey="unique_providers" stroke="#82ca9d" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}


function OrganizationDetailsColumn({ statistics }) {
  console.log("statistics", statistics);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  // Sort the statistics data by date
  const sortedOrganizationStats = [...statistics].sort((a, b) => new Date(a.date) - new Date(b.date));

  // Compute total patients and average patients per day
  const totalPatients = statistics.reduce((acc, item) => acc + item.total_patients, 0);
  const averagePatientsPerDay = (totalPatients / statistics.length).toFixed(0);

  // Function to handle CSV export
  const handleExportCSV = (month) => {
    // Convert the selected month to a number (0-based for JS Date)
    const monthIndex = parseInt(month, 10) - 1;

    // Filter the statistics data for the selected month
    const filteredData = statistics.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate.getMonth() === monthIndex && itemDate.getFullYear() === itemDate.getFullYear(); // Adjust the year as needed
    });

    // Create CSV data
    const csvRows = [];
    const headers = ['Date', 'Total Patients', 'Unique Providers'];
    csvRows.push(headers.join(','));

    filteredData.forEach((row) => {
      const values = [row.date, row.total_patients, row.unique_providers];
      csvRows.push(values.join(','));
    });

    // Convert array of rows into CSV string
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'statistics_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle the export modal visibility
  const handleExportClick = () => {
    setIsExportModalOpen(true);
  };

  const handleExportConfirm = () => {
    if (selectedMonth) {
      handleExportCSV(selectedMonth);
      setIsExportModalOpen(false);
    } else {
      alert('Please select a month to export.');
    }
  };

  return (
    <div className="overflow-y-auto flex-1 bg-white">
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-700">Organization Details</h2>
        <button
          className="px-4 py-2 text-white bg-indigo-600 rounded-md"
          onClick={handleExportClick}
        >
          Export CSV
        </button>
      </div>
      {isExportModalOpen && (
        <div className="flex fixed inset-0 justify-center items-center bg-gray-600 bg-opacity-50">
          <div className="p-6 bg-white rounded-md shadow-md">
            <h2 className="mb-4 text-lg font-semibold">Select Month to Export</h2>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="p-2 mb-4 rounded-md border border-gray-300"
            >
              <option value="">Select a month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {new Date(0, index).toLocaleString('default', { month: 'long' })}
                </option>
              ))}
            </select>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 text-white bg-gray-400 rounded-md"
                onClick={() => setIsExportModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-indigo-600 rounded-md"
                onClick={handleExportConfirm}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      )}
      {statistics ? (
        <div className="p-4">
          <dl className="divide-y divide-gray-200">
            {[
              { label: 'Total Patients', value: totalPatients },
              { label: 'Average Patients per Day', value: averagePatientsPerDay },
            ].map((item) => (
              <div key={item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {item.value}
                </dd>
              </div>
            ))}
          </dl>

          <div className="px-4 py-5 border-t border-gray-200">
            <h4 className="mb-2 font-medium text-gray-900 text-md">Daily Count of Patients and Providers</h4>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={sortedOrganizationStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="total_patients"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="unique_providers"
                  stroke="#82ca9d"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
          <BuildingOfficeIcon className="mb-4 w-12 h-12 text-gray-400" />
          <p>Select an organization to view details</p>
        </div>
      )}
    </div>
  );
}




export default UserManagementContent;
