import { NavigationBar } from "./NavigationBar";

export const PageLayout = (props) => {
    return (
        <div className="flex flex-col h-screen">
            <NavigationBar />
            <div className="flex-1 flex flex-col min-h-0">
                {props.children}
            </div>
        </div>
    );
};
