import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

const CustomDocument = Document.extend({
  content: 'paragraph+',
});

const PlainTextEditor = ({ textContent, updateTextContent, saveDocument }) => {
  const editor = useEditor({
    extensions: [CustomDocument, Paragraph, Text],
    content: textContent || '',
    onUpdate: ({ editor }) => {
      const text = editor.getText();
      updateTextContent(text);
    },
    editorProps: {
      handleDOMEvents: {
        blur: (view, event) => {
          saveDocument('textContent', editor.getText());
          return false;
        },
      },
    },
  });

  useEffect(() => {
    if (editor) {
      // Add the decoration plugin to the editor
      const decorationPlugin = new Plugin({
        state: {
          init(_, { doc }) {
            return getDecorations(doc);
          },
          apply(transaction, oldState, oldEditorState, newEditorState) {
            // Only recalculate decorations if the document has changed
            if (transaction.docChanged) {
              return getDecorations(newEditorState.doc);
            }
            return oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      });

      editor.registerPlugin(decorationPlugin);
    }
  }, [editor]);

  // Function to create decorations for red text
  const getDecorations = (doc) => {
    const decorations = [];
    const regex = /MIPS:.*?(?=\n|$)/g;

    doc.descendants((node, pos) => {
      if (node.isText) {
        const text = node.text;
        let match;

        while ((match = regex.exec(text)) !== null) {
          const start = pos + match.index;
          const end = start + match.index + match[0].length;

          decorations.push(
            Decoration.inline(start, end, { class: 'text-indigo-700' })
          );
        }
      }
    });

    return DecorationSet.create(doc, decorations);
  };

  useEffect(() => {
    if (editor && textContent !== editor.getText()) {
      editor.commands.setContent(textContent, false, {
        preserveWhitespace: 'full',
      });
    }
  }, [textContent, editor]);

  return (
    <div id="tiptap-editor-container">
      <EditorContent editor={editor} />
    </div>
  );
};

export default PlainTextEditor;
