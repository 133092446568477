import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import recordingManager from './RecordingManager'; // Adjust the path

const RecordingCleanupProvider = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        return () => {
            // This will run when the component unmounts
            recordingManager.stopAllRecordings();
        };
    }, []);

    useEffect(() => {
        // This effect runs on every route change
        recordingManager.stopAllRecordings();
    }, [location.pathname]);

    return children;
};

export default RecordingCleanupProvider;
