import { protectedResources } from '../authConfig';

export const getMacros = async (instance, accounts) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiGetMacros.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };

    const fetchResponse = await fetch(protectedResources.apiGetMacros.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('getMacros error:', error);
    throw error;
  }
};

export const getGroupMacros = async (instance, accounts, groupId) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiGetGroupMacros.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestBody = {
      group_id: groupId
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    };

    const fetchResponse = await fetch(protectedResources.apiGetGroupMacros.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('getGroupMacros error:', error);
    throw error;
  }
};

export const upsertMacro = async (instance, accounts, macroData) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiUpsertMacro.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(macroData)
    };

    const fetchResponse = await fetch(protectedResources.apiUpsertMacro.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('upsertMacro error:', error);
    throw error;
  }
};

export const upsertGroupMacro = async (instance, accounts, macroData) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiUpsertGroupMacro.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestBody = {
      macro_id: macroData.macro_id,
      group_id: macroData.group_id,
      title: macroData.title,
      command: macroData.command,
      content: macroData.content,
      section: macroData.section
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    };

    const fetchResponse = await fetch(protectedResources.apiUpsertGroupMacro.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('upsertGroupMacro error:', error);
    throw error;
  }
};

export const deleteMacro = async (instance, accounts, macroId) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiDeleteMacro.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ macro_id: macroId })
    };

    const fetchResponse = await fetch(protectedResources.apiDeleteMacro.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('deleteMacro error:', error);
    throw error;
  }
};

export const getSharedMacros = async (instance, accounts) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiGetSharedMacros.scopes,
      account: accounts[0]
    };

    const response = await instance.acquireTokenSilent(request);
    const token = response.accessToken;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    const fetchResponse = await fetch(protectedResources.apiGetSharedMacros.endpoint, requestOptions);
    const data = await fetchResponse.json();
    return data;
  } catch (error) {
    console.error('getSharedMacros error:', error);
    throw error;
  }
}; 