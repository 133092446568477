// components/VisibilityIcon.jsx  

import React from 'react';
import {
    EyeSlashIcon,
    EyeIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/24/outline';

const VisibilityIcon = ({ visibility }) => {
    let icon;
    let colorClass;

    switch (visibility) {
        case 'do_not_auto_show':
            icon = <EyeSlashIcon className="h-5 w-5" />;
            colorClass = 'text-gray-500';
            break;
        case 'auto_show':
            icon = <EyeIcon className="h-5 w-5" />;
            colorClass = 'text-green-500';
            break;
        case 'force_resolve':
            icon = <ExclamationCircleIcon className="h-5 w-5" />;
            colorClass = 'text-red-500';
            break;
        default:
            icon = <EyeIcon className="h-5 w-5" />;
            colorClass = 'text-gray-500';
    }

    return <div className={`${colorClass}`}>{icon}</div>;
};

export default VisibilityIcon;  