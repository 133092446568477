import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../authConfig';
import { OrganizationChart } from 'primereact/organizationchart';
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/OrganizationStructure.css';
import { useNavigate } from 'react-router-dom';
import GroupMacrosPage from '../pages/macros/GroupMacros';
import MdmSettings from './MdmSettings';
import { Dialog } from '@headlessui/react';
import { CubeIcon, CreditCardIcon } from '@heroicons/react/24/outline';

const OrganizationStructure = ({ orgId }) => {
    const { instance, accounts } = useMsal();
    const [organizationData, setOrganizationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selection, setSelection] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [mdmTemplate, setMdmTemplate] = useState(null);
    const [inheritanceSource, setInheritanceSource] = useState(null);
    const [groupDetails, setGroupDetails] = useState({
        group_name: '',
        group_description: ''
    });
    const navigate = useNavigate();
    const [isMacrosModalOpen, setIsMacrosModalOpen] = useState(false);
    const [isMdmModalOpen, setIsMdmModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    // State variables for Edit Group Modal
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editGroupData, setEditGroupData] = useState({
        name: '',
        description: '',
        group_type_id: ''
    });

    const fetchOrganizationData = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0 && orgId) {
            const request = {
                scopes: protectedResources.apiGetGroupHierarchy.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const endpoint = `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`;
                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error('Failed to fetch organization data');
                }

                const data = await response.json();
                setOrganizationData(data.hierarchy);
            } catch (error) {
                console.error('Error fetching organization data:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.log('accounts or orgId not available');
            setIsLoading(false);
        }
    }, [accounts, instance, orgId]);

    useEffect(() => {
        fetchOrganizationData();
    }, [fetchOrganizationData]);

    const transformDataForChart = (data) => {
        const map = {};
        const roots = [];

        data.forEach((node) => {
            map[node.group_id] = {
                key: node.group_id.toString(),
                type: 'person',
                expanded: true,
                className: 'bg-white',
                style: {
                    borderRadius: '8px',
                    border: '1px solid #e2e8f0'
                },
                data: {
                    name: node.name,
                    description: node.description,
                    group_id: node.group_id,
                    group_type_id: node.group_type_id,
                    path: node.path
                },
                children: []
            };
        });

        data.forEach((node) => {
            if (node.parent_group_id !== null) {
                map[node.parent_group_id].children.push(map[node.group_id]);
            } else {
                roots.push(map[node.group_id]);
            }
        });

        return roots;
    };

    const nodeTemplate = (node) => {
        return (
            <div className="p-2">
                <div className="font-bold">{node.data.name}</div>
                <div className="text-sm text-gray-600">{node.data.description}</div>
            </div>
        );
    };

    const checkAdminAccess = useCallback(async (groupId) => {
        // Stub function that always returns true for now
        // TODO: Implement actual admin check logic
        return true;
    }, []);

    const fetchMdmTemplate = useCallback(async (groupId, settingName) => {
        if (accounts.length > 0 && groupId) {
            const request = {
                scopes: protectedResources.apiGroupSettings.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const endpoint = `${protectedResources.apiGroupSettings.endpoint}${groupId}/${settingName}`;
                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error('Failed to fetch MDM template');
                }

                const data = await response.json();
                if (data.setting && data.setting.value) {
                    setMdmTemplate(data.setting.value);
                    setInheritanceSource(data.setting.value.inheritance_source);
                    setGroupDetails({
                        group_name: data.setting.value.group_name,
                        group_description: data.setting.value.group_description
                    });
                } else {
                    console.error('Invalid MDM template structure:', data);
                    setMdmTemplate(null);
                    setInheritanceSource(null);
                    setGroupDetails({
                        group_name: '',
                        group_description: ''
                    });
                }
            } catch (error) {
                console.error('Error fetching MDM template:', error);
                setMdmTemplate(null);
                setInheritanceSource(null);
                setGroupDetails({
                    group_name: '',
                    group_description: ''
                });
            }
        }
    }, [accounts, instance]);

    useEffect(() => {
        if (selection.length > 0) {
            setIsAdmin(true); // Assuming all selected groups are admin for demonstration
            fetchMdmTemplate(selection[0].data.group_id, 'custom_mdm_template');
        } else {
            setMdmTemplate(null);
            setInheritanceSource(null);
            setGroupDetails({
                group_name: '',
                group_description: ''
            });
        }
    }, [selection, fetchMdmTemplate]);

    // Handler to open Edit Group Modal
    const handleEditGroup = () => {
        if (selection.length === 1) {
            const selectedGroup = selection[0].data;
            setEditGroupData({
                name: selectedGroup.name,
                description: selectedGroup.description,
                group_type_id: selectedGroup.group_type_id
            });
            setIsEditModalOpen(true);
        }
    };

    // Handler to save edited group
    const handleSaveEditGroup = async () => {
        const updatedGroup = {
            group_id: selection[0].data.group_id,
            name: editGroupData.name,
            description: editGroupData.description,
            group_type_id: editGroupData.group_type_id,
            org_id: orgId
        };

        const request = {
            scopes: protectedResources.apiGroupSettings.scopes,
            account: accounts[0],
        };

        try {
            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const response = await fetch(protectedResources.apiCreateOrUpdateGroup.endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedGroup)
            });

            if (!response.ok) {
                throw new Error('Failed to save group');
            }

            const result = await response.json();
            console.log('Group updated:', result);
            setIsEditModalOpen(false);
            fetchOrganizationData(); // Refresh data
        } catch (error) {
            console.error('Error saving group:', error);
            // Optionally, add user-facing error handling here
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full w-full">
                <div className="text-xl text-gray-700">Loading...</div>
            </div>
        );
    }

    if (!organizationData) {
        return (
            <div className="flex items-center justify-center h-full w-full">
                <div className="text-xl text-red-500">Error: Organization data not available.</div>
            </div>
        );
    }

    const chartData = transformDataForChart(organizationData);

    return (
        <div className="organization-structure flex flex-col w-full h-full">
            <header className="bg-white shadow">
                <div className="flex items-center justify-between px-4 py-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">Organization Structure</h1>
                </div>
            </header>
            <main className="flex-1 overflow-auto bg-gray-100 p-4 rounded-md">
                {/* Outer Scrollable Container */}
                <div className="w-full h-full overflow-auto">
                    <div className="p-4 w-max h-max">
                        <OrganizationChart
                            value={chartData}
                            selectionMode="single"
                            selection={selection}
                            onSelectionChange={(e) => setSelection(e.data ? [e.data] : [])}
                            nodeTemplate={nodeTemplate}
                        />
                    </div>
                </div>

                {selection.length > 0 && (
                    <>
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                            onClick={() => setSelection([])}
                        />
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-xl border border-gray-200 w-[90%] max-w-md animate-fade-in z-50 overflow-y-auto max-h-[90vh]">
                            <div className="flex justify-between items-center mb-6">
                                <h3 className="text-xl font-semibold text-gray-800">Selected Group Details</h3>
                                <button
                                    onClick={() => setSelection([])}
                                    className="text-gray-500 hover:text-gray-700 transition-colors"
                                >
                                    <i className="pi pi-times" />
                                </button>
                            </div>
                            <div className="space-y-4">
                                <p className="flex flex-col">
                                    <span className="text-sm font-medium text-gray-600 mb-1">Name</span>
                                    <span className="text-gray-800">{selection[0].data.name}</span>
                                </p>
                                <p className="flex flex-col">
                                    <span className="text-sm font-medium text-gray-600 mb-1">Description</span>
                                    <span className="text-gray-800">{selection[0].data.description}</span>
                                </p>
                                <p className="flex flex-col">
                                    <span className="text-sm font-medium text-gray-600 mb-1">Path</span>
                                    <span className="text-gray-800">{selection[0].data.path}</span>
                                </p>

                                {/* Display MDM Information as an Accordion */}
                                {mdmTemplate && (
                                    <Accordion>
                                        <AccordionTab header="MDM Information">
                                            <div className="space-y-2 mt-2">
                                                <p className="flex flex-col">
                                                    <span className="text-sm font-medium text-gray-600 mb-1">Inheritance Source</span>
                                                    <span className="text-gray-800">
                                                        {(() => {
                                                            const [type, id] = inheritanceSource.split(':');
                                                            if (type === 'group' && parseInt(id, 10) === selection[0].data.group_id) {
                                                                return 'Self';
                                                            }
                                                            return inheritanceSource;
                                                        })()}
                                                    </span>
                                                </p>

                                                {inheritanceSource && inheritanceSource.startsWith("group:") && (() => {
                                                    const [type, id] = inheritanceSource.split(':');
                                                    if (type === 'group' && parseInt(id, 10) !== selection[0].data.group_id) {
                                                        return (
                                                            <>
                                                                <p className="flex flex-col">
                                                                    <span className="text-sm font-medium text-gray-600 mb-1">Inherited From Group Name</span>
                                                                    <span className="text-gray-800">{groupDetails.group_name}</span>
                                                                </p>
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })()}
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                )}

                                {isAdmin && (
                                    <div className="pt-4 border-t space-y-2">
                                        <button
                                            onClick={() => {
                                                setSelectedGroupId(selection[0].data.group_id);
                                                setIsMacrosModalOpen(true);
                                            }}
                                            className="w-full bg-indigo-800 text-white px-4 py-2 rounded-md hover:bg-indigo-900 transition-colors flex items-center gap-2"
                                        >
                                            <CubeIcon className="h-5 w-5" />
                                            View Macros
                                        </button>
                                        <button
                                            onClick={() => {
                                                setSelectedGroupId(selection[0].data.group_id);
                                                setIsMdmModalOpen(true);
                                            }}
                                            className="w-full bg-indigo-800 text-white px-4 py-2 rounded-md hover:bg-indigo-900 transition-colors flex items-center gap-2"
                                        >
                                            <CreditCardIcon className="h-5 w-5" />
                                            View MDM Template
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Edit Group Modal */}
                        {isEditModalOpen && (
                            <>
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                                    onClick={() => setIsEditModalOpen(false)}
                                />
                                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-xl border border-gray-200 w-[90%] max-w-md animate-fade-in z-50 overflow-y-auto max-h-[90vh]">
                                    <div className="flex justify-between items-center mb-6">
                                        <h3 className="text-xl font-semibold text-gray-800">Edit Group</h3>
                                        <button
                                            onClick={() => setIsEditModalOpen(false)}
                                            className="text-gray-500 hover:text-gray-700 transition-colors"
                                        >
                                            <i className="pi pi-times" />
                                        </button>
                                    </div>
                                    <div className="space-y-4">
                                        <div className="flex flex-col">
                                            <label className="text-sm font-medium text-gray-600">Name</label>
                                            <input
                                                type="text"
                                                value={editGroupData.name}
                                                onChange={(e) => setEditGroupData({ ...editGroupData, name: e.target.value })}
                                                className="mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-sm font-medium text-gray-600">Description</label>
                                            <textarea
                                                value={editGroupData.description}
                                                onChange={(e) => setEditGroupData({ ...editGroupData, description: e.target.value })}
                                                className="mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-sm font-medium text-gray-600">Group Type ID</label>
                                            <input
                                                type="number"
                                                value={editGroupData.group_type_id}
                                                onChange={(e) => setEditGroupData({ ...editGroupData, group_type_id: e.target.value })}
                                                className="mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        <div className="flex justify-end space-x-2">
                                            <button
                                                onClick={() => setIsEditModalOpen(false)}
                                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleSaveEditGroup}
                                                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </main>

            {/* Group Macros Modal */}
            <Dialog open={isMacrosModalOpen} onClose={() => setIsMacrosModalOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black bg-opacity-50" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto w-full max-w-6xl bg-white rounded shadow-lg p-6">
                        <Dialog.Title className="text-lg font-bold mb-4">Group Macros</Dialog.Title>
                        <GroupMacrosPage
                            groupId={selectedGroupId}
                            onSuccessfulSave={() => {
                                // Optionally handle success  
                            }}
                            isModal={true} // Pass a prop to adjust styling for modal  
                        />
                        <div className="mt-4">
                            <button
                                onClick={() => setIsMacrosModalOpen(false)}
                                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700"
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* MDM Settings Modal */}
            <Dialog open={isMdmModalOpen} onClose={() => setIsMdmModalOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black bg-opacity-50" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto w-full max-w-6xl h-[90vh] bg-white rounded shadow-lg p-6 overflow-y-auto">
                        {/* <Dialog.Title className="text-lg font-bold mb-4">MDM Configuration</Dialog.Title> */}
                        <MdmSettings
                            groupId={selectedGroupId}
                            onSuccessfulSave={() => {
                                // Optionally handle success (e.g., show a success message)  
                            }}
                            isModal={true} // Pass a prop to adjust styling for modal  
                        />
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={() => setIsMdmModalOpen(false)}
                                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700"
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>

    );
};

export default OrganizationStructure; 