// GlobalUserManagement.jsx

import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../authConfig';
import { Dialog } from '@headlessui/react';

const GlobalUserManagement = ({ isOpen, onClose, setNotification }) => {
    const { instance, accounts } = useMsal();

    const [users, setUsers] = useState([]);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [usersSearchQuery, setUsersSearchQuery] = useState('');
    const [usersSortConfig, setUsersSortConfig] = useState({ key: 'full_name', direction: 'ascending' });
    const [selectedUser, setSelectedUser] = useState(null);

    const [isMoveUserModalOpen, setIsMoveUserModalOpen] = useState(false);

    const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    // Fetch data when the modal opens
    useEffect(() => {
        if (isOpen) {
            fetchAllUsers();
            fetchAllOrganizations();
        }
    }, [isOpen]);

    // Fetch groups when a new organization is selected
    useEffect(() => {
        if (selectedOrg) {
            fetchGroupsForOrganization(selectedOrg);
        } else {
            setGroups([]);
            setSelectedGroup(null);
        }
    }, [selectedOrg]);

    // Function to fetch all users
    const fetchAllUsers = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiActorsWithDetails.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const response = await fetch(protectedResources.apiActorsWithDetails.endpoint, requestOptions);
                const data = await response.json();
                console.log(data);

                // Map the actors to include necessary fields
                const mappedUsers = data.map((actor) => ({
                    actor_id: actor.actor_id,
                    full_name: actor.full_name,
                    email: actor.email,
                    org_name: actor.org_name || 'N/A',
                    group_name: actor.group_name || 'Unassigned',
                }));

                setUsers(mappedUsers);
                console.log(mappedUsers);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        }
    };


    // Function to fetch all organizations
    const fetchAllOrganizations = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiListOrgs.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const response = await fetch(protectedResources.apiListOrgs.endpoint, requestOptions);
                const data = await response.json();
                setOrganizations(data.orgs);
            } catch (error) {
                console.error('Failed to fetch organizations:', error);
            }
        }
    };

    // Function to fetch groups for an organization
    const fetchGroupsForOrganization = async (orgId) => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiGetGroupHierarchy.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                };

                const endpoint = `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`;
                const response = await fetch(endpoint, requestOptions);
                const data = await response.json();

                if (!response.ok) {
                    throw new Error('Failed to fetch group hierarchy');
                }

                // Flatten the hierarchy into a list of groups
                // The data is already in the format we need
                setGroups(data.hierarchy || []);
            } catch (error) {
                console.error('Failed to fetch groups:', error);
            }
        }
    };

    // Utility function to flatten the group hierarchy
    // Utility function to flatten the group hierarchy
    const flattenGroupHierarchy = (hierarchy) => {
        const groups = [];

        const traverse = (node) => {
            // Skip if node is null or undefined
            if (!node) return;

            // Extract group_id and other properties safely
            const group_id = node.group_id || (node.attributes && node.attributes.group_id);
            const name = node.name;
            const description = node.description || (node.attributes && node.attributes.description);

            // Only add to groups if we have a valid group_id and name
            if (group_id && name) {
                groups.push({
                    group_id,
                    name,
                    description: description || '',
                });
            }

            // Recursively process children if they exist
            if (Array.isArray(node.children)) {
                node.children.forEach(child => traverse(child));
            }
        };

        // Only traverse if hierarchy exists
        if (hierarchy) {
            traverse(hierarchy);
        }

        return groups;
    };


    // Sorting and filtering users
    useEffect(() => {
        let sortableUsers = [...users];

        // Search filter
        if (usersSearchQuery) {
            sortableUsers = sortableUsers.filter((user) =>
                user.full_name.toLowerCase().includes(usersSearchQuery.toLowerCase()) ||
                user.email.toLowerCase().includes(usersSearchQuery.toLowerCase())
            );
        }

        // Sorting
        sortableUsers.sort((a, b) => {
            if (a[usersSortConfig.key] < b[usersSortConfig.key]) {
                return usersSortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[usersSortConfig.key] > b[usersSortConfig.key]) {
                return usersSortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setSortedUsers(sortableUsers);
    }, [users, usersSearchQuery, usersSortConfig]);

    // Handle sorting
    const handleUsersSort = (key) => {
        let direction = 'ascending';
        if (usersSortConfig.key === key && usersSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setUsersSortConfig({ key, direction });
    };

    // Handle user selection
    const handleUserSelection = (user) => {
        setSelectedUser(user);
    };

    // Handle moving user
    const handleMoveUserClick = () => {
        if (selectedUser) {
            setIsMoveUserModalOpen(true);
            // Reset selected organization and group
            setSelectedOrg(null);
            setSelectedGroup(null);
            setGroups([]);
        }
    };

    // Function to move user
    const moveUser = async () => {
        const request = {
            scopes: protectedResources.apiActorsBase.scopes,  // Updated to use apiActorsBase
            account: accounts[0],
        };

        try {
            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const response = await fetch(
                `${protectedResources.apiActorsBase.endpoint}/move/`,  // Updated endpoint
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        actor_id: selectedUser.actor_id,
                        group_id: selectedGroup ? parseInt(selectedGroup, 10) : null,
                        org_id: parseInt(selectedOrg, 10)
                    })
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to move user');
            }

            const data = await response.json();
            console.log('User moved successfully:', data);

            // Refresh users list
            fetchAllUsers();
            // Close modal
            setIsMoveUserModalOpen(false);
            // Reset selections
            setSelectedUser(null);
            setSelectedOrg(null);
            setSelectedGroup(null);

            if (setNotification) {
                setNotification({ type: 'success', message: 'User moved successfully.' });
            }
        } catch (error) {
            console.error('Error moving user:', error);
            if (setNotification) {
                setNotification({ type: 'error', message: error.message || 'Failed to move user. Please try again later.' });
            }
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4">
                    <Dialog.Panel className="mx-auto w-full max-w-4xl bg-white rounded shadow-lg p-6">
                        <Dialog.Title className="text-lg font-bold mb-4">
                            Global User Management
                        </Dialog.Title>
                        {/* Users List */}
                        <div className="bg-white">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">All Users</h3>
                                <button
                                    onClick={handleMoveUserClick}
                                    disabled={!selectedUser}
                                    className={`px-4 py-2 rounded-md text-white ${selectedUser ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 cursor-not-allowed'
                                        } transition-colors`}
                                >
                                    Move User
                                </button>
                            </div>

                            {/* Search Input */}
                            <div className="mb-2">
                                <input
                                    type="text"
                                    placeholder="Search by name or email..."
                                    value={usersSearchQuery}
                                    onChange={(e) => setUsersSearchQuery(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                />
                            </div>

                            {/* Users Table */}
                            {sortedUsers.length > 0 ? (
                                <div className="max-h-96 overflow-y-auto border border-gray-200 rounded-md">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                                                    onClick={() => handleUsersSort('full_name')}
                                                >
                                                    Name
                                                    {usersSortConfig.key === 'full_name' && (
                                                        <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                                                    onClick={() => handleUsersSort('email')}
                                                >
                                                    Email
                                                    {usersSortConfig.key === 'email' && (
                                                        <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                                                    onClick={() => handleUsersSort('org_name')}
                                                >
                                                    Organization
                                                    {usersSortConfig.key === 'org_name' && (
                                                        <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                                                    onClick={() => handleUsersSort('group_name')}
                                                >
                                                    Group
                                                    {usersSortConfig.key === 'group_name' && (
                                                        <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {sortedUsers.map((user) => (
                                                <tr
                                                    key={user.actor_id}
                                                    className={`
                            cursor-pointer
                            hover:bg-indigo-50
                            ${selectedUser && selectedUser.actor_id === user.actor_id
                                                            ? 'bg-indigo-100'
                                                            : ''
                                                        }
                          `}
                                                    onClick={() => handleUserSelection(user)}
                                                >
                                                    <td className="px-4 py-2 text-sm text-gray-700">{user.full_name}</td>
                                                    <td className="px-4 py-2 text-sm text-gray-700">{user.email}</td>
                                                    <td className="px-4 py-2 text-sm text-gray-700">{user.org_name || 'N/A'}</td>
                                                    <td className="px-4 py-2 text-sm text-gray-700">{user.group_name || 'Unassigned'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div>No users found.</div>
                            )}
                        </div>
                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                            >
                                Close
                            </button>
                        </div>

                        {/* Move User Modal */}
                        <Dialog
                            open={isMoveUserModalOpen}
                            onClose={() => setIsMoveUserModalOpen(false)}
                            className="relative z-50"
                        >
                            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                            <div className="fixed inset-0 flex items-center justify-center p-4">
                                <Dialog.Panel className="mx-auto max-w-lg rounded bg-white p-6">
                                    <Dialog.Title className="text-lg font-semibold mb-4">
                                        Move User
                                    </Dialog.Title>
                                    <p>
                                        Moving user <span className="font-medium">{selectedUser?.full_name}</span>
                                    </p>
                                    <div className="mt-4 space-y-4">
                                        {/* Current Organization */}
                                        <div>
                                            <label className="block text-sm font-medium mb-1">Current Organization</label>
                                            <input
                                                type="text"
                                                value={selectedUser?.org_name || 'N/A'}
                                                disabled
                                                className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                                            />
                                        </div>
                                        {/* Current Group */}
                                        <div>
                                            <label className="block text-sm font-medium mb-1">Current Group</label>
                                            <input
                                                type="text"
                                                value={selectedUser?.group_name || 'Unassigned'}
                                                disabled
                                                className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                                            />
                                        </div>
                                        {/* Select New Organization */}
                                        <div>
                                            <label className="block text-sm font-medium mb-1">Select New Organization</label>
                                            <select
                                                value={selectedOrg || ''}
                                                onChange={(e) => setSelectedOrg(e.target.value)}
                                                className="w-full p-2 border border-gray-300 rounded-md"
                                            >
                                                <option value="" disabled>Select an organization</option>
                                                {organizations.map((org) => (
                                                    <option key={org.org_id} value={org.org_id}>
                                                        {org.org_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* Select New Group */}
                                        <div>
                                            <label className="block text-sm font-medium mb-1">Select New Group (Optional)</label>
                                            <select
                                                value={selectedGroup || ''}
                                                onChange={(e) => setSelectedGroup(e.target.value)}
                                                disabled={!selectedOrg}
                                                className={`w-full p-2 border border-gray-300 rounded-md ${!selectedOrg ? 'bg-gray-100 cursor-not-allowed' : ''
                                                    }`}
                                            >
                                                <option value="">No group</option>
                                                {groups.map((group) => (
                                                    <option key={group.group_id} value={group.group_id}>
                                                        {group.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex justify-end space-x-3">
                                        <button
                                            onClick={() => setIsMoveUserModalOpen(false)}
                                            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={moveUser}
                                            disabled={!selectedOrg}
                                            className={`px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 ${!selectedOrg ? 'opacity-50 cursor-not-allowed' : ''
                                                }`}
                                        >
                                            Move User
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Dialog>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default GlobalUserManagement;
