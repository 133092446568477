// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Editor.css */

.mdm-editor-container {
    position: relative;
}

/* Style the ReactQuill editor */
.mdm-editor .ql-editor {
    white-space: pre-wrap;
    /* Preserves whitespace and line breaks */
    min-height: 150px;
    padding: 8px;
    font-size: 1rem;
    color: #4a5568;
    /* text-slate-600 */
}

/* Placeholder styling */
.mdm-editor .ql-editor::before {
    color: #a0aec0;
    /* text-slate-400 */
    font-style: italic;
}

/* Show border on focus */
.mdm-editor .ql-container.ql-snow.ql-focused {
    border: 1px solid #cbd5e0;
    /* border-gray-300 */
    box-shadow: 0 0 0 1px #cbd5e0;
}

/* Ensure unordered lists have bullets */
.mdm-editor .ql-editor ul {
    list-style-type: disc;
    /* Default bullet style */
    padding-left: 20px;
    /* Indent the list */
    margin: 0;
    /* Remove default margins if needed */
}

/* Ensure list items are displayed as block elements */
.mdm-editor .ql-editor li {
    display: list-item;
    margin-bottom: 4px;
    /* Optional: Add spacing between items */
}`, "",{"version":3,"sources":["webpack://./src/styles/Editor.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,kBAAkB;AACtB;;AAEA,gCAAgC;AAChC;IACI,qBAAqB;IACrB,yCAAyC;IACzC,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA,wBAAwB;AACxB;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA,yBAAyB;AACzB;IACI,yBAAyB;IACzB,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA,wCAAwC;AACxC;IACI,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;IACpB,SAAS;IACT,qCAAqC;AACzC;;AAEA,sDAAsD;AACtD;IACI,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;AAC5C","sourcesContent":["/* Editor.css */\n\n.mdm-editor-container {\n    position: relative;\n}\n\n/* Style the ReactQuill editor */\n.mdm-editor .ql-editor {\n    white-space: pre-wrap;\n    /* Preserves whitespace and line breaks */\n    min-height: 150px;\n    padding: 8px;\n    font-size: 1rem;\n    color: #4a5568;\n    /* text-slate-600 */\n}\n\n/* Placeholder styling */\n.mdm-editor .ql-editor::before {\n    color: #a0aec0;\n    /* text-slate-400 */\n    font-style: italic;\n}\n\n/* Show border on focus */\n.mdm-editor .ql-container.ql-snow.ql-focused {\n    border: 1px solid #cbd5e0;\n    /* border-gray-300 */\n    box-shadow: 0 0 0 1px #cbd5e0;\n}\n\n/* Ensure unordered lists have bullets */\n.mdm-editor .ql-editor ul {\n    list-style-type: disc;\n    /* Default bullet style */\n    padding-left: 20px;\n    /* Indent the list */\n    margin: 0;\n    /* Remove default margins if needed */\n}\n\n/* Ensure list items are displayed as block elements */\n.mdm-editor .ql-editor li {\n    display: list-item;\n    margin-bottom: 4px;\n    /* Optional: Add spacing between items */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
