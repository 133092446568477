import React, { useState, useMemo } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';

function UserTable({ filteredUsers, selectedUser, setSelectedUser }) {
  const [sortConfig, setSortConfig] = useState({ key: 'full_name', direction: 'ascending' });
  const [error, setError] = useState(null);

  const sortedUsers = useMemo(() => {
    if (!filteredUsers || filteredUsers.length === 0) return [];

    const sortableItems = [...filteredUsers];

    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Special handling for sorting by last name
        if (sortConfig.key === 'full_name') {
          aValue = getLastName(aValue).toLowerCase();
          bValue = getLastName(bValue).toLowerCase();
        } else if (sortConfig.key === 'signed_on_at' || sortConfig.key === 'last_patient_seen') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        } else if (sortConfig.key === 'patients_seen') {
          aValue = aValue || 0;
          bValue = bValue || 0;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue || aValue === null) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue || bValue === null) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredUsers, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleUserSelection = (user) => {
    if (typeof setSelectedUser === 'function') {
      setSelectedUser(user);
    } else {
      setError('Unable to select user. Please try again later.');
      console.error('setSelectedUser is not a function');
    }
  };

  return (
    <div className="flex flex-col h-full">
      {error && (
        <div className="p-2 mb-4 text-red-700 bg-red-100 rounded border border-red-400">
          {error}
        </div>
      )}
      {filteredUsers && filteredUsers.length > 0 ? (
        <div className="flex-1 overflow-y-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                {[
                  { label: 'User Name', key: 'full_name' },
                  { label: 'Specialty', key: 'actor_specialty' },
                  { label: 'Division', key: 'division' },
                  { label: 'Patients Seen', key: 'patients_seen' },
                  { label: 'Signed Up', key: 'signed_on_at' },
                  { label: 'Last Patient Seen', key: 'last_patient_seen' },
                  { label: 'Email', key: 'email' },
                ].map((column) => (
                  <th
                    key={column.key}
                    scope="col"
                    className="px-4 py-2 text-sm font-semibold text-left text-gray-700 cursor-pointer bg-gray-50"
                    onClick={() => requestSort(column.key)}
                  >
                    <div className="flex items-center">
                      {column.label}
                      {sortConfig.key === column.key ? (
                        sortConfig.direction === 'ascending' ? (
                          <svg
                            className="ml-1 w-4 h-4 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                          </svg>
                        ) : (
                          <svg
                            className="ml-1 w-4 h-4 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {sortedUsers.map((user) => (
                <tr
                  key={user.actor_id}
                  className={`cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'
                    }`}
                  onClick={() => handleUserSelection(user)}
                >
                  <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">{user.full_name}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{formatSpecialty(user.actor_specialty)}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{user.division}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{user.patients_seen || 0}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{formatDate(user.signed_on_at)}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{formatDate(user.last_patient_seen)}</td>
                  <td className="px-4 py-2 text-sm whitespace-nowrap">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
          <UserGroupIcon className="mb-4 w-12 h-12 text-gray-400" />
          <p>No users found</p>
        </div>
      )}
    </div>
  );
}

// Helper functions
const formatSpecialty = (specialty) => {
  if (!specialty) return 'N/A';
  return specialty === 'EMERGENCY_PHYSICIAN' ? 'Physician' : 'APP';
};

const formatDate = (dateString) => {
  if (!dateString || dateString === 'N/A') return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const getLastName = (fullName) => {
  if (!fullName) return '';
  const names = fullName.trim().split(' ');
  return names[names.length - 1];
};

export default UserTable;
