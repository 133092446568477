// GroupInterventionsPage.jsx  

import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../contexts/AuthProvider';
import SelectGroupDropdown from '../components/SelectGroupDropdown';
import {
    getGroupInterventions,
    upsertGroupIntervention,
    deleteIntervention,
} from '../api/interventionApi';
import { getAvailableGroups } from '../api/groupApi';
import {
    TrashIcon,
    PencilIcon,
    PlusIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/24/outline';
import ActionIcon from '../components/ActionIcon'; // Custom component for action icons  
import WhenToApplyIcon from '../components/WhenToApplyIcon'; // Component for 'when_to_apply' icons  
import ActionSelect from '../components/ActionSelect'; // New custom select component with icons  
import VisibilityIcon from '../components/VisibilityIcon'; // New custom component for visibility icons  
import VisibilitySelect from '../components/VisibilitySelect'; // New custom select component with icons  
import { getGroupMacros } from '../api/macroApi'; // Adjust the import path as needed  
import { getRiskScores } from '../api/riskScoreApi'; // You need to implement this API call 
import MacroSelect from '../components/MacroSelect';
import RiskScoreSelect from '../components/RiskScoreSelect';

const GroupInterventionsPage = ({ groupId, onSuccessfulSave, isModal }) => {
    const { instance, accounts } = useMsal();
    const { actorInfo } = useAuth();

    const [interventionId, setInterventionId] = useState(null);
    const [title, setTitle] = useState('');
    const [whenToApply, setWhenToApply] = useState('');
    const [userInput, setUserInput] = useState('');
    const [trigger, setTrigger] = useState('');
    const [message, setMessage] = useState('');
    const [action, setAction] = useState('');
    const [visibility, setVisibility] = useState('');
    const [macroId, setMacroId] = useState(null);
    const [riskScoreId, setRiskScoreId] = useState(null);
    const [interventionList, setInterventionList] = useState([]);
    const [groupedInterventions, setGroupedInterventions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedInterventionId, setSelectedInterventionId] = useState(null);
    const [groupIdState, setGroupIdState] = useState(groupId || null);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [expandedGroups, setExpandedGroups] = useState({});
    const [macros, setMacros] = useState([]);
    const [riskScores, setRiskScores] = useState([]);
    const [useCleoModel, setUseCleoModel] = useState(false);
    const [triggerCleoModel, setTriggerCleoModel] = useState('');
    const [applyToAllCases, setApplyToAllCases] = useState(false);
    const [whenToApplyChiefComplaint, setWhenToApplyChiefComplaint] = useState('');

    // Fetch interventions  
    const getInterventions = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getGroupInterventions(instance, accounts, groupIdState);
            if (data.interventions) {
                const interventions = Object.values(data.interventions);
                setInterventionList(interventions);
                groupInterventions(interventions);
            } else {
                setInterventionList([]);
                setGroupedInterventions({});
            }
        } catch (error) {
            console.error('Error fetching interventions:', error);
        }
        setIsLoading(false);
    }, [instance, accounts, groupIdState]);

    // Group interventions by 'when_to_apply'  
    const groupInterventions = (interventions) => {
        const groups = {};
        interventions.forEach((intervention) => {
            const key = intervention.when_to_apply || 'Uncategorized';
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(intervention);
        });
        setGroupedInterventions(groups);
    };

    useEffect(() => {
        const fetchMacros = async () => {
            try {
                const data = await getGroupMacros(instance, accounts, groupIdState);
                const macrosArray = data.macros ? Object.values(data.macros) : [];
                setMacros(macrosArray);
            } catch (error) {
                console.error('Error fetching macros:', error);
                setMacros([]); // Set macros to empty array on error  
            }
        };

        if (groupIdState) {
            fetchMacros();
        }
    }, [instance, accounts, groupIdState]);

    useEffect(() => {
        const fetchRiskScores = async () => {
            try {
                const data = await getRiskScores(instance, accounts);
                if (data.risk_scores) {
                    setRiskScores(data.risk_scores);
                }
            } catch (error) {
                console.error('Error fetching risk scores:', error);
            }
        };

        fetchRiskScores();
    }, [instance, accounts]);

    // Handle saving interventions  
    const upsertInterventionHandler = async () => {
        setIsLoading(true);
        try {
            const interventionData = {
                intervention_id: interventionId,
                title,
                when_to_apply: whenToApply,
                trigger: useCleoModel ? triggerCleoModel : trigger,
                message: useCleoModel ? '' : message,
                action,
                visibility,
                group_id: groupIdState,
                actor_id: null,
                organization_id: null,
                macro_id: macroId,
                risk_score_id: riskScoreId,
            };
            const data = await upsertGroupIntervention(instance, accounts, interventionData);
            if (data.success) {
                await getInterventions();
                setSelectedInterventionId(data.intervention_id);
                if (onSuccessfulSave) {
                    onSuccessfulSave(`Intervention saved successfully`);
                }
                resetForm();
            } else {
                console.error('Error upserting intervention:', data.error);
            }
        } catch (error) {
            console.error('Error upserting intervention:', error);
        }
        setIsLoading(false);
    };

    // Handle deleting interventions  
    const deleteInterventionHandler = async () => {
        if (!window.confirm('Are you sure you want to delete this intervention?')) {
            return;
        }
        setIsLoading(true);
        try {
            const data = await deleteIntervention(instance, accounts, interventionId);
            if (data.success) {
                resetForm();
                await getInterventions();
                if (onSuccessfulSave) {
                    onSuccessfulSave('Intervention deleted successfully');
                }
            } else {
                console.error('Error deleting intervention:', data.error);
            }
        } catch (error) {
            console.error('Error deleting intervention:', error);
        }
        setIsLoading(false);
    };

    // Handle clicking on an intervention  
    const handleInterventionClick = (intervention) => {
        setInterventionId(intervention.intervention_id);
        setTitle(intervention.title);
        setWhenToApply(intervention.when_to_apply);
        setTrigger(intervention.trigger);
        setMessage(intervention.message);
        setAction(intervention.action);
        setVisibility(intervention.visibility);
        setMacroId(intervention.macro_id);
        setRiskScoreId(intervention.risk_score_id);
        setSelectedInterventionId(intervention.intervention_id);

        // Check if the trigger is one of the Cleo models  
        const cleoModels = ['cleo_mips_engine', 'cleo_critical_care_engine', 'cleo_sepsis_engine'];
        if (cleoModels.includes(intervention.trigger)) {
            setUseCleoModel(true);
            setTriggerCleoModel(intervention.trigger);
            setTrigger('');
            setMessage(''); // Clear message as it's not used  
        } else {
            setUseCleoModel(false);
            setTrigger(intervention.trigger);
            setMessage(intervention.message);
            setTriggerCleoModel('');
        }

        if (intervention.when_to_apply === 'Global') {
            setApplyToAllCases(true);
            setWhenToApply('Global');
            setWhenToApplyChiefComplaint('');
        } else {
            setApplyToAllCases(false);
            setWhenToApply(intervention.when_to_apply);
            setWhenToApplyChiefComplaint(intervention.when_to_apply);
        }
    };

    const resetForm = () => {
        setInterventionId(null);
        setTitle('');
        setWhenToApply('');
        setWhenToApplyChiefComplaint('');
        setApplyToAllCases(false);
        setTrigger('');
        setMessage('');
        setAction('');
        setVisibility('');
        setMacroId(null);
        setRiskScoreId(null);
        setSelectedInterventionId(null);
        setUseCleoModel(false);
        setTriggerCleoModel('');
    };

    // Toggle group expansion  
    const toggleGroup = (group) => {
        setExpandedGroups({
            ...expandedGroups,
            [group]: !expandedGroups[group],
        });
    };

    const isFormValid = () => {
        if (!title || !action) {
            return false;
        }

        if (applyToAllCases) {
            // 'whenToApply' is already set to 'Global' or 'ALL'  
        } else {
            if (!whenToApplyChiefComplaint) {
                return false;
            }
        }

        if (useCleoModel) {
            if (!triggerCleoModel) {
                return false;
            }
        } else {
            if (!trigger) {
                return false;
            }
        }

        return true;
    };
    // Define button classes based on form validity  
    const saveButtonClass = isFormValid()
        ? 'inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700'
        : 'inline-flex items-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm cursor-not-allowed';

    useEffect(() => {
        if (groupIdState) {
            getInterventions();
        }
    }, [getInterventions, groupIdState]);

    useEffect(() => {
        async function fetchGroups() {
            try {
                const groups = await getAvailableGroups(instance, accounts);
                setAvailableGroups(groups);

                if (groupId) {
                    const matchedGroup = groups.find((group) => group.group_id === groupId);
                    if (matchedGroup) {
                        setSelectedGroup(matchedGroup);
                    } else {
                        alert('You do not have access to this group.');
                    }
                } else if (groups.length > 0) {
                    setSelectedGroup(groups[0]);
                    setGroupIdState(groups[0].group_id);
                } else {
                    setSelectedGroup(null);
                    setGroupIdState(null);
                }
            } catch (error) {
                console.error('Failed to fetch groups:', error);
            }
        }
        fetchGroups();
    }, [instance, accounts, groupId]);

    // Prepare the interventions list: separate global and others  
    const globalInterventions = [];
    const chiefComplaintInterventions = [];
    Object.keys(groupedInterventions).forEach((group) => {
        if (group.toLowerCase() === 'global') {
            globalInterventions.push({ group, interventions: groupedInterventions[group] });
        } else {
            chiefComplaintInterventions.push({ group, interventions: groupedInterventions[group] });
        }
    });

    // Sort the chief complaint interventions alphabetically  
    chiefComplaintInterventions.sort((a, b) => a.group.localeCompare(b.group));

    return (
        <div className={isModal ? '' : 'container mx-auto p-6'}>
            <div className="flex">
                {/* Sidebar */}
                <div className="w-1/4 pr-6">
                    {/* Group Selection */}
                    <SelectGroupDropdown
                        selectedGroup={selectedGroup}
                        setSelectedGroup={(group) => {
                            setSelectedGroup(group);
                            setGroupIdState(group?.group_id);
                            resetForm(); // Reset form when changing groups  
                        }}
                        availableGroups={availableGroups}
                    />
                    {/* New Intervention Button */}
                    <button
                        onClick={resetForm}
                        className="mt-4 w-full inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700"
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        New Intervention
                    </button>
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search interventions"
                        className="mt-4 p-2 border border-gray-300 rounded w-full"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {/* Interventions List */}
                    <div className="mt-4">
                        {isLoading ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="spinner"></div>
                            </div>
                        ) : (
                            <div>
                                {/* Global Interventions */}
                                {globalInterventions.length > 0 && (
                                    <div>
                                        <h3 className="mb-1 text-sm font-semibold text-gray-700">Global Interventions</h3>
                                        {globalInterventions.map(({ group, interventions }) => (
                                            <div key={group} className="mb-2">
                                                {/* Group Header */}
                                                <div
                                                    className="flex items-center justify-between cursor-pointer bg-gray-200 px-3 py-2 rounded"
                                                    onClick={() => toggleGroup(group)}
                                                >
                                                    <div className="flex items-center">
                                                        <WhenToApplyIcon whenToApply={group} />
                                                        <span className="ml-2 font-semibold text-gray-700">
                                                            {group}
                                                        </span>
                                                    </div>
                                                    {expandedGroups[group] ? (
                                                        <ChevronUpIcon className="h-5 w-5" />
                                                    ) : (
                                                        <ChevronDownIcon className="h-5 w-5" />
                                                    )}
                                                </div>
                                                {/* Interventions in Group */}
                                                {expandedGroups[group] && (
                                                    <ul className="mt-1 ml-4">
                                                        {interventions
                                                            .filter((intervention) =>
                                                                intervention.title
                                                                    .toLowerCase()
                                                                    .includes(searchQuery.toLowerCase())
                                                            )
                                                            .map((intervention) => (
                                                                <li
                                                                    key={intervention.intervention_id}
                                                                    className={`flex items-center cursor-pointer p-2 hover:bg-gray-100 rounded ${intervention.intervention_id === selectedInterventionId
                                                                        ? 'bg-indigo-100'
                                                                        : ''
                                                                        }`}
                                                                    onClick={() => handleInterventionClick(intervention)}
                                                                >
                                                                    <ActionIcon action={intervention.action} />
                                                                    <span className="ml-2 text-sm text-gray-700">
                                                                        {intervention.title}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {/* Divider */}
                                {globalInterventions.length > 0 && chiefComplaintInterventions.length > 0 && (
                                    <hr className="my-4 border-gray-300" />
                                )}
                                {/* Chief Complaint-Based Interventions */}
                                {chiefComplaintInterventions.length > 0 && (
                                    <div>
                                        <h3 className="mb-1 text-sm font-semibold text-gray-700">
                                            Chief Complaint-Based Interventions
                                        </h3>
                                        {chiefComplaintInterventions.map(({ group, interventions }) => (
                                            <div key={group} className="mb-2">
                                                {/* Group Header */}
                                                <div
                                                    className="flex items-center justify-between cursor-pointer bg-gray-200 px-3 py-2 rounded"
                                                    onClick={() => toggleGroup(group)}
                                                >
                                                    <div className="flex items-center">
                                                        <WhenToApplyIcon whenToApply={group} />
                                                        <span className="ml-2 font-semibold text-gray-700">
                                                            {group}
                                                        </span>
                                                    </div>
                                                    {expandedGroups[group] ? (
                                                        <ChevronUpIcon className="h-5 w-5" />
                                                    ) : (
                                                        <ChevronDownIcon className="h-5 w-5" />
                                                    )}
                                                </div>
                                                {/* Interventions in Group */}
                                                {expandedGroups[group] && (
                                                    <ul className="mt-1 ml-4">
                                                        {interventions
                                                            .filter((intervention) =>
                                                                intervention.title
                                                                    .toLowerCase()
                                                                    .includes(searchQuery.toLowerCase())
                                                            )
                                                            .map((intervention) => (
                                                                <li
                                                                    key={intervention.intervention_id}
                                                                    className={`flex items-center cursor-pointer p-2 hover:bg-gray-100 rounded ${intervention.intervention_id === selectedInterventionId
                                                                        ? 'bg-indigo-100'
                                                                        : ''
                                                                        }`}
                                                                    onClick={() => handleInterventionClick(intervention)}
                                                                >
                                                                    <ActionIcon action={intervention.action} />
                                                                    <span className="ml-2 text-sm text-gray-700">
                                                                        {intervention.title}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {/* No Interventions Found */}
                                {globalInterventions.length === 0 && chiefComplaintInterventions.length === 0 && (
                                    <p className="text-center text-gray-500 py-4">
                                        No interventions found
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {/* Main Content */}
                <div className="w-3/4 bg-white shadow rounded p-6">
                    {/* Form Fields */}
                    <div className="space-y-6">
                        {/* When to Apply */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                When to Apply<span className="text-red-500">*</span>
                            </label>
                            <div className="mt-2">
                                {/* Option: All Cases */}
                                <div className="flex items-center">
                                    <input
                                        id="applyToAllCases"
                                        name="whenToApplyOption"
                                        type="radio"
                                        checked={applyToAllCases}
                                        onChange={() => {
                                            setApplyToAllCases(true);
                                            setWhenToApply('Global'); // Or 'ALL' for housekeeping  
                                            setWhenToApplyChiefComplaint('');
                                        }}
                                        className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    />
                                    <label htmlFor="applyToAllCases" className="ml-2 block text-sm text-gray-700">
                                        All Cases
                                    </label>
                                </div>
                                {/* Option: Chief Complaint */}
                                <div className="flex items-center mt-2">
                                    <input
                                        id="applyToChiefComplaint"
                                        name="whenToApplyOption"
                                        type="radio"
                                        checked={!applyToAllCases}
                                        onChange={() => {
                                            setApplyToAllCases(false);
                                            setWhenToApply('');
                                        }}
                                        className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    />
                                    <label htmlFor="applyToChiefComplaint" className="ml-2 block text-sm text-gray-700">
                                        Chief Complaint
                                    </label>
                                </div>
                            </div>
                            {/* Chief Complaint Input */}
                            {!applyToAllCases && (
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="whenToApplyChiefComplaint"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        placeholder="Enter Chief Complaint"
                                        value={whenToApplyChiefComplaint}
                                        onChange={(e) => {
                                            setWhenToApplyChiefComplaint(e.target.value);
                                            setWhenToApply(e.target.value);
                                        }}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                        {/* Title */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Title<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="title"
                                placeholder="Enter Title"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </div>
                        {/* Trigger */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 flex items-center">
                                Trigger<span className="text-red-500">*</span>
                                {/* Toggle Switch */}
                                <div className="flex items-center ml-4">
                                    <input
                                        type="checkbox"
                                        id="useCleoModel"
                                        checked={useCleoModel}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setUseCleoModel(isChecked);
                                            // Reset trigger and message when toggling  
                                            if (isChecked) {
                                                setTrigger('');
                                                setMessage('');
                                            } else {
                                                setTriggerCleoModel('');
                                            }
                                        }}
                                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                    />
                                    <label htmlFor="useCleoModel" className="ml-2 block text-sm text-gray-700">
                                        Use Cleo Model
                                    </label>
                                </div>
                            </label>
                            {useCleoModel ? (
                                // Show the Cleo Model select dropdown  
                                <div className="mt-2">
                                    <select
                                        className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={triggerCleoModel}
                                        onChange={(e) => setTriggerCleoModel(e.target.value)}
                                        required
                                    >
                                        <option value="">-- Select Cleo Model --</option>
                                        {/* <option value="cleo_mips_engine">Cleo MIPS Engine</option> */}
                                        <option value="cleo_critical_care_engine">Cleo Critical Care Engine</option>
                                        <option value="cleo_sepsis_engine">Cleo Sepsis Engine</option>
                                    </select>
                                </div>
                            ) : (
                                // Show the trigger textarea  
                                <div className="mt-2">
                                    <textarea
                                        name="trigger"
                                        placeholder="Enter Trigger"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        rows="3"
                                        value={trigger}
                                        onChange={(e) => setTrigger(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                        </div>

                        {/* Action (with Icons) */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Action<span className="text-red-500">*</span>
                            </label>
                            <ActionSelect action={action} setAction={setAction} />
                        </div>
                        {/* Conditionally render MacroSelect when action is 'macro' or 'macro_with_user_input' */}
                        {(action === 'macro' || action === 'macro_with_user_input') && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Select Macro
                                </label>
                                <MacroSelect
                                    macros={macros}
                                    macroId={macroId}
                                    setMacroId={setMacroId}
                                />
                            </div>
                        )}

                        {(action === 'macro_with_user_input') && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    User Input
                                </label>
                                <textarea
                                    name="userInput"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                    rows="1"
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                />
                            </div>
                        )}

                        {/* Conditionally render RiskScoreSelect when action is 'risk_score' */}
                        {action === 'risk_score' && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Select Risk Score
                                </label>
                                <RiskScoreSelect
                                    riskScores={riskScores}
                                    riskScoreId={riskScoreId}
                                    setRiskScoreId={setRiskScoreId}
                                />
                            </div>
                        )}

                        {/* Visibility (with Icons) */}
                        {action !== 'push_notification' && action !== 'highlighted_insertion' && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Visibility
                                </label>
                                <VisibilitySelect visibility={visibility} setVisibility={setVisibility} />
                            </div>
                        )}
                        {/* Message */}
                        {!useCleoModel && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Message
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        name="message"
                                        placeholder="Enter Message"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        rows="3"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        )}
                        {/* Buttons */}
                        <div className="flex justify-between mt-6">
                            {interventionId && (
                                <button
                                    className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600"
                                    onClick={deleteInterventionHandler}
                                >
                                    <TrashIcon className="h-5 w-5 mr-2" />
                                    Delete
                                </button>
                            )}
                            <button
                                className={saveButtonClass}
                                onClick={upsertInterventionHandler}
                                disabled={!isFormValid()}
                            >
                                <PencilIcon className="h-5 w-5 mr-2" />
                                {interventionId ? 'Update Intervention' : 'Save Intervention'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupInterventionsPage;  