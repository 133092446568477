'use client'

import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { subDays, parseISO } from 'date-fns';
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, Switch, Listbox, Transition, DialogBackdrop, DialogTitle } from '@headlessui/react'
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    Bars3Icon,
    EllipsisHorizontalIcon,
    PlusSmallIcon,
    ChartBarIcon,
    ExclamationTriangleIcon,
    CogIcon,
    DocumentTextIcon,
    ChartPieIcon,
    PresentationChartLineIcon,
    ChartBarSquareIcon,
    TableCellsIcon,
    CurrencyDollarIcon,
    ReceiptPercentIcon,
    BanknotesIcon,
    CalculatorIcon,
    FaceSmileIcon,
    PencilIcon,
    TrashIcon,
    MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import { UserGroupIcon, UserIcon, BuildingOfficeIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { protectedResources } from '../authConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon, ChevronRightIcon, EllipsisVerticalIcon, PaperClipIcon } from '@heroicons/react/20/solid';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import { useAuth } from '../contexts/AuthProvider';

import DivisionAdminContent from '../components/DivisionAdminContent';
import UserManagementContent from '../pages/admin/UserManagementContent';
import OrganizationStructure from '../components/OrganizationStructure';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrganizationAdminDashboard() {
    const { state } = useLocation();
    const { actorInfo } = useAuth();
    const org_id = state?.org_id || actorInfo?.org_id; // allow for global admins to access any org
    const org_name = state?.org_name || ""
    const [isLoading, setIsLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [activeTab, setActiveTab] = useState('userManagement')
    const [aggOrgData, setAggOrgData] = useState(null);
    const [aggActorData, setAggActorData] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const navigate = useNavigate();

    const fetchAggOrgData = async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiFetchAggOrgData.scopes,
                account: accounts[0],
            };

            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ org_id: org_id }),
            };

            // Assuming protectedResources.apiFetchAggOrgData.endpoint is the base URL
            const endpoint = protectedResources.apiFetchAggOrgData.endpoint;

            if (org_id) {
                console.log(org_id);
            }

            const fetchResponse = await fetch(
                endpoint,
                requestOptions
            );
            const response = await fetchResponse.json();

            setAggOrgData(response.org_data);
            setAggActorData(response.actor_data);
            setOrgId(org_id);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAggOrgData();
    }, []);

    const tabs = [
        { name: 'Stats & Users', value: 'userManagement', icon: ChartPieIcon },
        { name: 'Division Admin', value: 'divisionAdmin', icon: UserGroupIcon },
        { name: 'Patient Experience', value: 'patientExperience', icon: ClipboardDocumentListIcon },
        { name: 'Organization Structure', value: 'organizationStructure', icon: BuildingOfficeIcon },
    ]

    return (
        isLoading ? (
            <div className="flex flex-1 overflow-hidden min-h-0">
                <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
            </div>
        ) : (
            <div className="flex flex-1 overflow-hidden min-h-0">
                <aside
                    className={`flex-shrink-0 bg-white border-r border-gray-200 transition-all duration-300 ${isSidebarCollapsed ? 'w-16' : 'w-64'
                        }`}
                >
                    <div className="flex items-center px-4 h-16 border-b border-gray-200">
                        {!isSidebarCollapsed && (
                            <h1
                                className="text-lg font-semibold text-gray-900 transition-opacity duration-300"
                            >
                                {org_name || 'Organization Dashboard'}
                            </h1>
                        )}
                        <button
                            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                            className="ml-auto focus:outline-none"
                            aria-label={isSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                        >
                            {isSidebarCollapsed ? (
                                <svg
                                    className="w-6 h-6 text-gray-700"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    {/* Icon for expand */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18M3 6h18M3 18h18" /> */}
                                </svg>
                            ) : (
                                <svg
                                    className="w-6 h-6 text-gray-700"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    {/* Icon for collapse */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /> */}
                                </svg>
                            )}
                        </button>
                    </div>
                    <nav
                        className={`overflow-y-auto ${isSidebarCollapsed ? 'px-2' : 'px-4'} py-6 h-full`}
                    >
                        <ul role="list" className="space-y-1">
                            {tabs.map((tab) => (
                                <li key={tab.value}>
                                    <button
                                        onClick={() => setActiveTab(tab.value)}
                                        className={classNames(
                                            activeTab === tab.value
                                                ? 'bg-gray-50 text-indigo-800'
                                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                            'group flex items-center w-full gap-x-3 rounded-md py-2 px-2 text-sm font-semibold leading-6',
                                            isSidebarCollapsed ? 'justify-center' : ''
                                        )}
                                        title={isSidebarCollapsed ? tab.name : ''}
                                    >
                                        <tab.icon
                                            className={classNames(
                                                activeTab === tab.value
                                                    ? 'text-indigo-800'
                                                    : 'text-gray-400 group-hover:text-indigo-800',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {!isSidebarCollapsed && (
                                            <span className="transition-opacity duration-300">{tab.name}</span>
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {/* Back to Global Dashboard Button */}
                        <div className="mt-4">
                            <button
                                onClick={() => navigate('/global-admin-dashboard')}
                                className={classNames(
                                    'group flex items-center w-full gap-x-3 rounded-md py-2 px-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                    isSidebarCollapsed ? 'justify-center' : 'justify-start'
                                )}
                                title="Back to Global Dashboard"
                            >
                                <svg
                                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-800"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                >
                                    {/* Icon for 'Back' */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18M9 6l-6 6 6 6" />
                                </svg>
                                {!isSidebarCollapsed && <span className="text-left">Back to Global Dashboard</span>}
                            </button>
                        </div>
                    </nav>
                </aside>

                <main className="flex flex-grow justify-center transition-all duration-300">
                    {activeTab === 'userManagement' && <UserManagementContent orgStatistics={aggOrgData} actorStatistics={aggActorData} orgId={orgId} />}
                    {activeTab === 'divisionAdmin' && (
                        <DivisionAdminContent organizationData={aggOrgData} orgId={orgId} />
                    )}
                    {activeTab === 'organizationStructure' && <div className="h-full w-full"><OrganizationStructure orgId={orgId} /></div>}
                    {activeTab === 'patientExperience' && (
                        <PatientExperienceContent organizationData={aggOrgData} orgId={orgId} />
                    )}
                </main>
            </div>
        )
    );
}






function MDMOptionsContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);

    // Fetch users and divisions from the mock data
    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            // Flatten the mock data and extract users
            const data = organizationData.flatMap((division) =>
                division.users.map((user) => ({
                    division: division.division,
                    actor_id: user.actor_id,
                    full_name: user.full_name,
                    email: user.email,
                    documents: user.documents,
                }))
            );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from the user data
            const uniqueDivisions = Array.from(
                new Set(data.map((user) => user.division))
            ).map((division) => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [organizationData]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(
                (user) =>
                    user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter((user) =>
                selectedDivisions.includes(user.division)
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };

    const handleDivisionToggle = (division) => {
        setSelectedDivisions((prevSelected) =>
            prevSelected.includes(division.id)
                ? prevSelected.filter((d) => d !== division.id)
                : [...prevSelected, division.id]
        );
        setSearchTerm('');
        setSelectedUser(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties((prevSelected) =>
            prevSelected.includes(specialty.id)
                ? prevSelected.filter((s) => s !== specialty)
                : [...prevSelected, specialty]
        );
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    // MDM Options and descriptions
    const mdmOptions = [
        'Full DDx',
        'Verbalized and conservative DDx',
        'Only verbalized DDx',
        'No DDx',
    ];

    const mdmDescriptions = {
        'Full DDx':
            "Cleo will generate a comprehensive differential diagnosis based on the patient's presentation and provider's assessment.",
        'Verbalized and conservative DDx':
            'Cleo will include mentioned diagnoses and a few closely related conditions.',
        'Only verbalized DDx':
            'Cleo will only include diagnoses explicitly mentioned by the provider.',
        'No DDx': 'Cleo will not generate any differential diagnosis.',
    };

    const [mdmSettings, setMdmSettings] = useState({
        'Full DDx': false,
        'Verbalized and conservative DDx': false,
        'Only verbalized DDx': true,
        'No DDx': false,
    });

    const handleMdmSettingChange = (option) => {
        setMdmSettings((prevSettings) => ({
            ...prevSettings,
            [option]: !prevSettings[option],
        }));
    };

    return (
        <div className="flex flex-col w-full h-screen bg-gray-100">
            <div className="bg-white">
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                        MDM Configuration
                    </h1>
                </div>
            </div>
            <div className="px-4 pb-2 bg-white border-b border-gray-200">
                <div className="w-full max-w-md lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block py-2 pr-3 pl-10 w-full leading-5 placeholder-gray-500 bg-white rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden flex-1">
                {/* Specialties column */}
                {/* <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Specialties
                    </h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className="p-4 text-gray-700 cursor-pointer hover:bg-gray-50"
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        // onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div> */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/*Example*/}
                {/* <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div> */}

                {/* Divisions column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Divisions
                    </h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className="p-4 text-gray-700 cursor-pointer hover:bg-gray-50"
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={classNames(
                                                selectedDivisions.includes(division.id)
                                                    ? 'bg-indigo-600'
                                                    : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 items-center rounded-full'
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    selectedDivisions.includes(division.id)
                                                        ? 'translate-x-6'
                                                        : 'translate-x-1',
                                                    'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                                                )}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="ml-3 w-5 h-5 text-gray-400" />
                                        <span className="ml-3 text-sm font-medium">
                                            {division.name}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="mb-4 w-12 h-12 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users/Devices column */}
                <div className="overflow-y-auto w-80 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Users
                    </h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={classNames(
                                            'flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50',
                                            selectedUser?.actor_id === user.actor_id
                                                ? 'bg-indigo-50 text-indigo-600'
                                                : 'text-gray-700'
                                        )}
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div className="flex gap-x-3 items-center">
                                            <UserIcon className="w-5 h-5 text-gray-400" />
                                            <div>
                                                <p className="text-sm font-medium">{user.full_name}</p>
                                                <p className="text-xs text-gray-500">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                                <UserGroupIcon className="mb-4 w-12 h-12 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={classNames(
                                            'flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50',
                                            selectedUser?.actor_id === user.actor_id
                                                ? 'bg-indigo-50 text-indigo-600'
                                                : 'text-gray-700'
                                        )}
                                        onClick={() => handleUserSelect(user)}
                                    >
                                        <div className="flex gap-x-3 items-center">
                                            <UserIcon className="w-5 h-5 text-gray-400" />
                                            <div>
                                                <p className="text-sm font-medium">{user.full_name}</p>
                                                <p className="text-xs text-gray-500">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                                <UserGroupIcon className="mb-4 w-12 h-12 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="mb-4 w-12 h-12 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* MDM Settings Details column */}
                <div className="overflow-y-auto flex-1 bg-white">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        MDM Configuration
                    </h2>
                    <div className="p-6 space-y-6">
                        {mdmOptions.map((option) => (
                            <div key={option} className="flex items-start">
                                <Switch
                                    checked={mdmSettings[option]}
                                    onChange={() => handleMdmSettingChange(option)}
                                    className={classNames(
                                        mdmSettings[option] ? 'bg-indigo-600' : 'bg-gray-200',
                                        'inline-flex relative items-center w-11 h-6 rounded-full'
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            mdmSettings[option] ? 'translate-x-6' : 'translate-x-1',
                                            'inline-block w-4 h-4 bg-white rounded-full transition-transform transform'
                                        )}
                                    />
                                </Switch>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">{option}</p>
                                    <p className="text-sm text-gray-500">
                                        {mdmDescriptions[option]}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}













function RiskNotificationsContent({ organizationData }) {
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedSpecialties, setSelectedSpecialties] = useState(['EMERGENCY_PHYSICIAN', 'ADVANCED_PRACTICE_PROVIDER']);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoadingDivisions, setIsLoadingDivisions] = useState(true);
    const [selectedTab, setSelectedTab] = useState('chest_pain'); // State for selected switchFlag tab


    const fetchUsers = useCallback(async () => {
        setIsLoadingDivisions(true);
        try {
            // Simulate fetching data by using the mock data
            const data = organizationData
                .flatMap(division =>
                    division.users
                        .filter(user => selectedSpecialties.includes(user.actor_specialty))
                        .map(user => ({
                            division: division.division,
                            actor_id: user.actor_id,
                            full_name: user.full_name,
                            email: user.email,
                            documents: user.documents,
                        }))
                );

            setUsers(data);
            setFilteredUsers(data);

            // Extract unique divisions from user data
            const uniqueDivisions = Array.from(new Set(data.map(user => user.division)))
                .map(division => ({ id: division, name: division }));
            setDivisions(uniqueDivisions);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingDivisions(false);
        }
    }, [selectedSpecialties, selectedDivisions]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter(user =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
            setSelectedDivisions([]); // Clear selected divisions when searching
        } else if (selectedDivisions.length > 0) {
            const filtered = users.filter(user => selectedDivisions.includes(user.division));
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchTerm, selectedDivisions, users]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };


    const handleDivisionToggle = (division) => {
        setSelectedDivisions(prevSelected => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected
                return [];
            } else {
                // Select only the clicked division
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties(prevSelected => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter(s => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedUser(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];


    const tabs = [
        { id: 'sepsis', label: 'Sepsis' },
        { id: 'critical_care', label: 'Critical Care' },
        { id: 'chest_pain', label: 'Chest Pain' },
        { id: 'back_pain', label: 'Back Pain' },
        { id: 'dizziness', label: 'Dizziness' },
        { id: 'abdominal_pain', label: 'Abdominal Pain' },
    ];

    const selectedDivision = selectedDivisions.length === 1
        ? organizationData.find(div => div.division === selectedDivisions[0])
        : null;

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">Risk Analyst Configuration</h1>
                </div>
            </div>
            {/* Tab Navigation */}
            <div className="bg-white">
                <div className="px-4 mb-4 sm:px-6 lg:px-8">
                    <div className="flex space-x-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`px-3 py-2 font-medium text-sm rounded-md ${selectedTab === tab.id
                                    ? 'bg-indigo-100 text-indigo-700'
                                    : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'
                                    }`}
                                onClick={() => setSelectedTab(tab.id)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="px-4 pb-2 bg-white border-b border-gray-200">
                <div className="w-full max-w-md lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block py-2 pr-3 pl-10 w-full leading-5 placeholder-gray-500 bg-white rounded-md border border-gray-300 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for users"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden flex-1">
                {/* Specialties column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Specialties</h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                        className={`${selectedSpecialties.includes(specialty.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id) ? 'translate-x-5' : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Divisions column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Divisions</h2>
                    {isLoadingDivisions ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li
                                            className={`${selectedDivisions.includes(division.id) ? 'bg-indigo-600' : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id) ? 'translate-x-5' : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon className="ml-2 w-5 h-5 text-gray-400" />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="mb-4 w-12 h-12 text-gray-400" />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Users column */}
                <div className="overflow-y-auto w-80 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Users</h2>
                    {searchTerm ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="flex-grow min-w-0">
                                            <div className="flex gap-x-3 items-start">
                                                <UserIcon className="w-5 h-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="flex gap-x-2 items-center mt-1 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"w-5 h-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                                <UserGroupIcon className="mb-4 w-12 h-12 text-gray-400" />
                                <p>No users found matching your search</p>
                            </div>
                        )
                    ) : selectedDivisions.length > 0 ? (
                        filteredUsers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {filteredUsers.map((user) => (
                                    <li
                                        key={user.actor_id}
                                        className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedUser?.actor_id === user.actor_id ? 'bg-indigo-50 text-indigo-600' : 'text-gray-700'}`}
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        <div className="flex-grow min-w-0">
                                            <div className="flex gap-x-3 items-start">
                                                <UserIcon className="w-5 h-5 text-gray-400" />
                                                <p className="text-sm font-semibold leading-6">{user.full_name}</p>
                                            </div>
                                            <div className="flex gap-x-2 items-center mt-1 text-xs leading-5 text-gray-500">
                                                <p className="truncate">{user.email}</p>
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={"w-5 h-5 text-gray-400"}
                                            aria-hidden="true"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                                <UserGroupIcon className="mb-4 w-12 h-12 text-gray-400" />
                                <p>No users found in the selected divisions</p>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon className="mb-4 w-12 h-12 text-gray-400" />
                            <p>Select divisions to view users</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedUser ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={selectedUser}
                        entityType={"user"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : selectedDivision ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={selectedDivision}
                        entityType={"division"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : selectedSpecialties.length === 1 ? (
                    <RiskNotificationDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"specialty"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                ) : (
                    <RiskNotificationDetailsColumn
                        selectedEntity={organizationData}
                        entityType={"organization"}
                        switchFlag={selectedTab}
                        organizationData={organizationData}
                    />
                )}
            </div>
        </div>
    );
}




function RiskNotificationDetailsColumn({ selectedEntity, switchFlag, entityType, organizationData }) {
    const [entityUsageStats, setEntityUsageStats] = useState([]);
    const [showWarnings, setShowWarnings] = useState(true);
    const [forceResolve, setForceResolve] = useState(false);
    const [autoAudit, setAutoAudit] = useState(true);
    const [allDocs, setAllDocs] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [maternalAgedFemaleDocs, setMaternalAgedFemaleDocs] = useState([]);
    const [specialty, setSpecialty] = useState(null);
    const [division, setDivision] = useState(null);
    const [specialtyType, setSpecialtyType] = useState("Specialty");
    const [recentStats, setRecentStats] = useState([]);

    const [alertingStats, setAlertingStats] = useState([]);
    const beginningOfStructure = new Date("2024-08-23");
    const beginningOfAlerting = new Date("2024-09-04");

    const chartData = useMemo(() => {
        return switchFlag === 'sepsis' || switchFlag === 'critical_care' ? alertingStats : recentStats;
    }, [switchFlag, alertingStats, recentStats]);

    const mapSwitchFlagToName = (switchFlag) => {
        const flagMap = {
            sepsis: "Sepsis",
            critical_care: "Critical Care",
            chest_pain: "Chest Pain",
            back_pain: "Back Pain",
            dizziness: "Dizziness",
            abdominal_pain: "Abdominal Pain",
        };
        return flagMap[switchFlag] || "Unknown";
    };

    const calculateDocs = () => {
        let allDocs;
        if (entityType === 'user') {
            allDocs = selectedEntity.documents;
        } else if (entityType === 'division') {
            allDocs = selectedEntity.users.flatMap(user => user.documents);
        } else if (entityType === 'specialty') {
            allDocs = selectedEntity.flatMap(division =>
                division.users
                    .filter(user => user.actor_specialty.toLowerCase() === specialty.toLowerCase())
                    .flatMap(user => user.documents)
            );
        } else if (entityType === 'organization') {
            allDocs = selectedEntity.flatMap(division =>
                division.users.flatMap(user => user.documents)
            );
        } else {
            console.error('Unknown entity type:', entityType);
            allDocs = [];
        }
        setAllDocs(allDocs);
        return allDocs;
    };

    const filterDocs = (docs, switchFlag) => {
        const filterMap = {
            sepsis: (doc) => doc.has_sepsis_alert,
            critical_care: (doc) => doc.has_critical_care_alert,
            chest_pain: (doc) => doc.chief_complaint?.toLowerCase() === "chest pain",
            back_pain: (doc) => doc.chief_complaint?.toLowerCase() === "back pain",
            dizziness: (doc) => doc.chief_complaint?.toLowerCase() === "dizziness",
            abdominal_pain: (doc) => doc.chief_complaint?.toLowerCase() === "abdominal pain",
        };
        return docs.filter(filterMap[switchFlag]);
    };


    useEffect(() => {
        if (selectedEntity && switchFlag) {
            setAlertingStats([]);
            setFilteredDocs([]);
            setEntityUsageStats([]);
            setRecentStats([]);
            const allDocsResponse = calculateDocs();

            const docs = filterDocs(allDocsResponse, switchFlag);
            setFilteredDocs(docs);

            if (switchFlag === 'abdominal_pain') {
                const maternalDocs = docs.filter(doc => doc.patient_sex === "FEMALE" && parseInt(doc.patient_age) >= 12 && parseInt(doc.patient_age) <= 50);
                setMaternalAgedFemaleDocs(maternalDocs);
            }

            const usageStats = docs.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                if (existingEntry) {
                    existingEntry.document_count += 1;
                    if (doc.has_ekg) existingEntry.ekg_count += 1;
                    if (doc.has_heart_score) existingEntry.heart_score_count += 1;
                    if (doc.has_nihss) existingEntry.nihss_count += 1;
                } else {
                    acc.push({
                        date,
                        document_count: 1,
                        ekg_count: doc.has_ekg ? 1 : 0,
                        heart_score_count: doc.has_heart_score ? 1 : 0,
                        nihss_count: doc.has_nihss ? 1 : 0,
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));


            const alertingStats = allDocs.reduce((acc, doc) => {
                const date = doc.created_at;
                const existingEntry = acc.find(entry => entry.date === date);
                if (existingEntry) {
                    existingEntry.document_count += 1;
                    existingEntry.sepsis_count += doc.has_sepsis_alert ? 1 : 0;
                    existingEntry.critical_care_count += doc.has_critical_care_alert ? 1 : 0;
                } else {
                    acc.push({
                        date,
                        document_count: 1,
                        sepsis_count: doc.has_sepsis_alert ? 1 : 0,
                        critical_care_count: doc.has_critical_care_alert ? 1 : 0
                    });
                }
                return acc;
            }, []).sort((a, b) => new Date(a.date) - new Date(b.date));

            // add sepsis and critical care alert percentages to the alerting stats
            const alertingStatsWithPercentages = alertingStats.map(stat => {
                const totalDocuments = stat.document_count;
                const sepsisCount = stat.sepsis_count || 0;
                const criticalCareCount = stat.critical_care_count || 0;

                const sepsisAlertPercentage = totalDocuments > 0 ? (sepsisCount / totalDocuments) * 100 : 0;
                const ccAlertPercentage = totalDocuments > 0 ? (criticalCareCount / totalDocuments) * 100 : 0;

                return {
                    ...stat,
                    sepsis_alert_percentage: parseFloat(sepsisAlertPercentage.toFixed(2)),
                    critical_care_alert_percentage: parseFloat(ccAlertPercentage.toFixed(2))
                };
            });

            const sevenDaysAgo = subDays(new Date(), 7);

            const recentStats = usageStats.filter(stat =>
                parseISO(stat.date) >= sevenDaysAgo
            );
            const recentAlertingStats = alertingStatsWithPercentages.filter(stat =>
                parseISO(stat.date) >= beginningOfAlerting
            );

            setEntityUsageStats(usageStats);
            setRecentStats(recentStats);
            setAlertingStats(recentAlertingStats);
        }
    }, [selectedEntity, switchFlag]);

    const sortByDate = (data) => {
        return [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    const sortedRecentStats = sortByDate(recentStats);


    return (
        <div className="overflow-y-auto flex-1 bg-white">
            <h1 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                {entityType === 'user' && selectedEntity.full_name}
                {entityType === 'division' && selectedEntity.division}
                {entityType === 'specialty' && specialtyType}
                {entityType === 'organization' && 'Organization'}
                {' '}{mapSwitchFlagToName(switchFlag)} Analysis
            </h1>
            <div className="p-4">
                {switchFlag === 'sepsis' && (
                    <div className="px-4 py-2 sm:px-6">
                        <p className="text-sm text-gray-500">
                            Our AI analyzes encounter data for potential sepsis cases based on two main criteria:
                        </p>
                        <ol className="mt-2 text-sm list-decimal list-inside text-gray-500">
                            <li className="mb-2">
                                <strong>SIRS indicators secondary to suspected infection:</strong>
                                <ul className="mt-1 ml-4 list-disc list-inside">
                                    <li>At least 2 SIRS criteria: temperature &gt;100.4°F or &lt;96.8°F, heart rate &gt;90 bpm, respiratory rate &gt;20/min</li>
                                    <li>AND suspected infection (e.g., pneumonia, UTI, cellulitis)</li>
                                </ul>
                            </li>
                            <li>
                                <strong>High-risk patients with high-risk conditions:</strong>
                                <ul className="mt-1 ml-4 list-disc list-inside">
                                    <li>High-risk factors: elderly, dialysis, active cancer, immunocompromised, nursing home resident, cirrhosis</li>
                                    <li>AND presenting with: altered mental status, shortness of breath, weakness, or abdominal pain</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                )}
                {switchFlag === 'critical_care' && (
                    <div className="px-4 py-2 sm:px-6">
                        <p className="text-sm text-gray-500">
                            Our AI analyzes encounter data for potential critical care cases based on two essential criteria:
                        </p>
                        <ol className="mt-2 text-sm list-decimal list-inside text-gray-500">
                            <li className="mb-2">
                                <strong>Clinical Condition:</strong> High probability of sudden, clinically significant, or life-threatening deterioration requiring urgent intervention.
                                <ul className="mt-1 ml-4 list-disc list-inside">
                                    <li>Examples: Shock, acute circulatory failure, severe CNS deterioration, acute respiratory failure, metabolic crises</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Treatment Requirements:</strong> Need for direct physician management and life/organ supporting interventions.
                                <ul className="mt-1 ml-4 list-disc list-inside">
                                    <li>Examples: Administration of critical medications, advanced airway management, defibrillation, cardioversion</li>
                                </ul>
                            </li>
                        </ol>
                        <p className="mt-3 text-sm text-gray-500">
                            The AI considers various indicators such as vital signs, EKG changes, neurological status, and required interventions to determine if both criteria are met.
                        </p>
                    </div>
                )}

                <h2 className="py-4 text-lg font-semibold text-gray-700 border-b border-gray-200">Risk Analyst Configuration</h2>
                <div className="flex justify-start mt-4 mb-4 space-x-6 items-left">
                    {/* Show warnings toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={showWarnings}
                            onChange={setShowWarnings}
                            className={`${showWarnings ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${showWarnings ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Show Warnings</span>
                    </div>

                    {/* Force resolve toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={forceResolve}
                            onChange={setForceResolve}
                            className={`${forceResolve ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${forceResolve ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Force Resolve</span>
                    </div>

                    {/* Auto-audit toggle */}
                    <div className="flex items-center space-x-4">
                        <Switch
                            checked={autoAudit}
                            onChange={setAutoAudit}
                            className={`${autoAudit ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-8 w-16 items-center rounded-full`}
                        >
                            <span className={`${autoAudit ? 'translate-x-8' : 'translate-x-1'} inline-block h-6 w-6 transform rounded-full bg-white transition`} />
                        </Switch>
                        <span className="text-base font-semibold text-gray-700">Push Notify</span>
                    </div>
                </div>

                <h2 className="py-4 mt-8 mb-2 text-lg font-semibold text-gray-700 border-b border-gray-200">{mapSwitchFlagToName(switchFlag)} Statistics</h2>
                <dl>
                    {[
                        {
                            label: `Total ${mapSwitchFlagToName(switchFlag)} Encounters`,
                            value: filteredDocs.length,
                        },
                        ...(
                            switchFlag === 'chest_pain'
                                ? [
                                    {
                                        label: `% of Encounters with EKG`,
                                        value: (() => {
                                            const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                                            const docsWithEKG = validDocs.filter(doc => doc.has_ekg);
                                            const percentage = validDocs.length > 0
                                                ? (docsWithEKG.length / validDocs.length) * 100
                                                : 0;
                                            return `${percentage.toFixed(0)}%`;
                                        })(),
                                    },
                                    // {
                                    //     label: `% of Encounters with HEART Score`,
                                    //     value: (() => {
                                    //         const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                                    //         const docsWithHeartScore = validDocs.filter(doc => doc.has_heart_score);
                                    //         const percentage = validDocs.length > 0
                                    //             ? (docsWithHeartScore.length / validDocs.length) * 100
                                    //             : 0;
                                    //         return `${percentage.toFixed(0)}%`;
                                    //     })(),
                                    // },
                                ]
                                : switchFlag === 'abdominal_pain'
                                    ? [
                                        // {
                                        //     label: `% of Encounters Maternal-Aged Female and Pregnancy Test Ordered`,
                                        //     value: (() => {
                                        //         const validDocs = maternalAgedFemaleDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                                        //         const docsWithPregnancyTest = validDocs.filter(doc => doc.has_pregnancy_test);
                                        //         const percentage = validDocs.length > 0
                                        //             ? (docsWithPregnancyTest.length / validDocs.length) * 100
                                        //             : 0;
                                        //         return `${percentage.toFixed(0)}%`;
                                        //     })(),
                                        // },
                                    ]
                                    : switchFlag === 'dizziness'
                                        ? [
                                            // {
                                            //     label: `% of Encounters with NIHSS`,
                                            //     value: (() => {
                                            //         const validDocs = filteredDocs.filter(doc => new Date(doc.created_at) >= beginningOfStructure);
                                            //         const docsWithNIHSS = validDocs.filter(doc => doc.has_nihss);
                                            //         const percentage = validDocs.length > 0
                                            //             ? (docsWithNIHSS.length / validDocs.length) * 100
                                            //             : 0;
                                            //         return `${percentage.toFixed(0)}%`;
                                            //     })(),
                                            // },
                                        ]
                                        : switchFlag === 'sepsis'
                                            ? [
                                                {
                                                    label: `% of Encounters with Sepsis Alert`,
                                                    value: (() => {
                                                        const recentDocs = allDocs.filter(doc => new Date(doc.created_at) >= beginningOfAlerting);
                                                        const docsWithSepsis = recentDocs.filter(doc => doc.has_sepsis_alert);
                                                        const percentage = recentDocs.length > 0
                                                            ? (docsWithSepsis.length / recentDocs.length) * 100
                                                            : 0;
                                                        return `${percentage.toFixed(0)}%`;
                                                    })(),
                                                },
                                            ]
                                            : switchFlag === 'critical_care'
                                                ? [
                                                    {
                                                        label: `% of Encounters with Critical Care Alert`,
                                                        value: (() => {
                                                            const recentDocs = allDocs.filter(doc =>
                                                                new Date(doc.created_at) >= beginningOfAlerting);
                                                            const docsWithCriticalCare = recentDocs.filter(doc => doc.has_critical_care_alert);
                                                            const percentage = recentDocs.length > 0
                                                                ? (docsWithCriticalCare.length / recentDocs.length) * 100
                                                                : 0;
                                                            return `${percentage.toFixed(0)}%`;
                                                        })(),
                                                    },
                                                ]
                                                : []
                        )
                    ].map(item => (
                        <div key={item.label} className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                            <dd className="text-sm font-bold text-gray-900 sm:col-span-2 sm:mt-0">
                                {item.value}
                            </dd>
                        </div>
                    ))}
                </dl>
                {/* CHART SHOWING PERCENTAGES */}
                <div className="mt-8">
                    <h2 className="mb-4 text-lg font-medium text-gray-900">
                        {mapSwitchFlagToName(switchFlag)} Recent Activity
                    </h2>
                    {((switchFlag === 'sepsis' || switchFlag === 'critical_care') ? alertingStats : recentStats).length === 0 ? (
                        <div className="flex justify-center items-center h-48">
                            <p className="text-sm text-gray-500">No documents available</p>
                        </div>
                    ) : (
                        <ResponsiveContainer width="100%" height={200}>
                            <LineChart data={chartData}>
                                <XAxis dataKey="date" />
                                {/* Primary Y-Axis for document counts and similar metrics */}
                                <YAxis yAxisId="left" />
                                {/* Secondary Y-Axis for percentages (right side) */}
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    tickFormatter={(tick) => `${tick.toFixed(0)}%`}
                                    domain={[0, 50]} // Dynamically set domain based on data
                                    allowDecimals={false}
                                />

                                <Tooltip />
                                <Legend
                                    verticalAlign="bottom"
                                    align="right"
                                    wrapperStyle={{ paddingTop: '20px' }}
                                />
                                {/* Line components for metrics aligned to the left Y-axis */}
                                <Line
                                    yAxisId="left"
                                    type="monotone"
                                    dataKey="document_count"
                                    stroke="#4f46e5"
                                    strokeWidth={2}
                                    name="Total Encounters"
                                />
                                {switchFlag === 'chest_pain' && (
                                    <>
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="ekg_count"
                                            stroke="#82ca9d"
                                            strokeWidth={2}
                                            name="Encounters with EKG"
                                        />
                                        {/* <Line 
                                    yAxisId="left"
                                    type="monotone" 
                                    dataKey="heart_score_count" 
                                    stroke="#33cfff" 
                                    strokeWidth={2} 
                                    name="Encounters with HEART Score" 
                                /> */}
                                    </>
                                )}
                                {/* {switchFlag === 'dizziness' && (
                            <Line 
                                yAxisId="left"
                                type="monotone" 
                                dataKey="nihss_count" 
                                stroke="#82ca9d" 
                                strokeWidth={2} 
                                name="Encounters with NIHSS" 
                            />
                        )} */}
                                {/* {switchFlag === 'abdominal_pain' && (
                            <Line 
                                yAxisId="left"
                                type="monotone" 
                                dataKey="pregnancy_test_count" 
                                stroke="#82ca9d" 
                                strokeWidth={2} 
                                name="Encounters with Pregnancy Test" 
                            />
                        )} */}
                                {/* Line components for percentages aligned to the right Y-axis */}
                                {switchFlag === 'sepsis' && (
                                    <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="sepsis_alert_percentage"
                                        stroke="#33cfff"
                                        strokeWidth={2}
                                        name="Sepsis Alert %"
                                    />
                                )}
                                {switchFlag === 'critical_care' && (
                                    <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="critical_care_alert_percentage"
                                        stroke="#33cfff"
                                        strokeWidth={2}
                                        name="Critical Care Alert %"
                                    />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
        </div>
    );
}









































function PatientExperienceContent({ orgId }) {
    const [actorsData, setActorsData] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState([
        'EMERGENCY_PHYSICIAN',
        'ADVANCED_PRACTICE_PROVIDER',
    ]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredActors, setFilteredActors] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedActor, setSelectedActor] = useState(null);

    const { instance, accounts } = useMsal();

    // Function to fetch patient experience data from the backend  
    const fetchPatientExperienceData = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiGetPatientExperienceData.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ org_id: orgId }),
                };

                const endpoint = protectedResources.apiGetPatientExperienceData.endpoint;

                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                setActorsData(data.actors);

                // Extract unique divisions from actorsData  
                const uniqueDivisions = Array.from(
                    new Set(data.actors.map((actor) => actor.division))
                )
                    .filter((division) => division !== null && division !== undefined)
                    .map((division) => ({ id: division, name: division }));

                setDivisions(uniqueDivisions);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching patient experience data:', error);
                setIsLoading(false);
            }
        }
    }, [accounts, instance, orgId]);

    useEffect(() => {
        fetchPatientExperienceData();
    }, [fetchPatientExperienceData]);

    // Filter actors based on specialties, divisions, and searchTerm  
    useEffect(() => {
        let filtered = actorsData;

        if (selectedSpecialties.length > 0) {
            filtered = filtered.filter((actor) =>
                selectedSpecialties.includes(actor.actor_specialty)
            );
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (actor) =>
                    actor.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (actor.email &&
                        actor.email.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setSelectedDivisions([]); // Clear selected divisions when searching  
        } else if (selectedDivisions.length > 0) {
            filtered = filtered.filter((actor) =>
                selectedDivisions.includes(actor.division)
            );
        }

        setFilteredActors(filtered);
    }, [actorsData, selectedSpecialties, selectedDivisions, searchTerm]);

    // Handlers for toggling specialties and divisions  
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };

    const handleDivisionToggle = (division) => {
        setSelectedDivisions((prevSelected) => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected  
                return [];
            } else {
                // Select only the clicked division  
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedActor(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties((prevSelected) => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter((s) => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedActor(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];

    // Function to handle CSV export  
    const handleExportCSV = () => {
        const csvRows = [];
        const headers = [
            'Provider Name',
            'Number of Documents',
            'AIDET Score',
            'Patient Experience Score',
            'Average Acknowledged Patient',
            'Average Introduced Themselves',
            'Average Provided Duration',
            'Average Provided Explanation',
            'Average Thanked Patient',
            'Average Asked Open Ended Questions',
            'Average Asked Patient If Questions',
            'Average Asked How Else Can Be Helped',
            'Average Asked Patient Opinion',
            'Average Managed Up Care Team',
            'Average Used Whiteboard',
            'Average Reflectively Listened',
        ];
        csvRows.push(headers.join(','));

        const escapeCSV = (field) => {
            if (field === null || field === undefined) return '""';
            const stringField = String(field);
            // If the field contains commas, quotes, or newlines, wrap it in quotes and escape any existing quotes
            if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
                return `"${stringField.replace(/"/g, '""')}"`;
            }
            return stringField;
        };

        filteredActors.forEach((actor) => {
            const values = [
                escapeCSV(actor.full_name),
                actor.document_count,
                actor.aidet_score.toFixed(2),
                actor.patient_experience_score.toFixed(2),
                actor.average_acknowledged_patient.toFixed(2),
                actor.average_introduced_themselves.toFixed(2),
                actor.average_provided_duration.toFixed(2),
                actor.average_provided_explanation.toFixed(2),
                actor.average_thanked_patient.toFixed(2),
                actor.average_asked_open_ended_questions.toFixed(2),
                actor.average_asked_patient_if_questions.toFixed(2),
                actor.average_asked_how_else_can_be_helped.toFixed(2),
                actor.average_asked_patient_opinion.toFixed(2),
                actor.average_managed_up_care_team.toFixed(2),
                actor.average_used_whiteboard.toFixed(2),
                actor.average_reflectively_listened.toFixed(2),
            ];
            csvRows.push(values.join(','));
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'provider_patient_experience.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="flex justify-between items-center px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                        Patient Experience Dashboard
                    </h1>
                    <button
                        className="px-4 py-2 text-white bg-indigo-600 rounded-md"
                        onClick={handleExportCSV}
                    >
                        Export CSV
                    </button>
                </div>
            </div>
            <div className="px-4 pb-2 bg-white border-b border-gray-200">
                <div className="w-full max-w-md lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block py-2 pr-3 pl-10 w-full leading-5 placeholder-gray-500 bg-white rounded-md border border-gray-300 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for providers"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden flex-1">
                {/* Specialties column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Specialties
                    </h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li  
                                        className={`${selectedSpecialties.includes(specialty.id)
                                            ? 'bg-indigo-600'
                                            : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id)
                                                ? 'translate-x-5'
                                                : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Divisions column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Divisions
                    </h2>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li  
                                            className={`${selectedDivisions.includes(division.id)
                                                ? 'bg-indigo-600'
                                                : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id)
                                                    ? 'translate-x-5'
                                                    : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon
                                            className="ml-2 w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon
                                className="mb-4 w-12 h-12 text-gray-400"
                                aria-hidden="true"
                            />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Providers column */}
                <div className="overflow-y-auto w-80 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Providers
                    </h2>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : filteredActors.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {filteredActors.map((actor) => (
                                <li
                                    key={actor.actor_id}
                                    className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedActor?.actor_id === actor.actor_id
                                        ? 'bg-indigo-50 text-indigo-600'
                                        : 'text-gray-700'
                                        }`}
                                    onClick={() => setSelectedActor(actor)}
                                >
                                    <div className="flex-grow min-w-0">
                                        <div className="flex gap-x-3 items-start">
                                            <UserIcon
                                                className="w-5 h-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <p className="text-sm font-semibold leading-6">
                                                {actor.full_name}
                                            </p>
                                        </div>
                                        <div className="flex gap-x-2 items-center mt-1 text-xs leading-5 text-gray-500">
                                            <p className="truncate">{actor.email}</p>
                                        </div>
                                    </div>
                                    <ChevronRightIcon
                                        className={'w-5 h-5 text-gray-400'}
                                        aria-hidden="true"
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <UserGroupIcon
                                className="mb-4 w-12 h-12 text-gray-400"
                                aria-hidden="true"
                            />
                            <p>No providers found</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedActor ? (
                    <PatientExperienceDetailsColumn
                        selectedEntity={selectedActor}
                        entityType={'user'}
                    />
                ) : (
                    <div className="overflow-y-auto flex-1 bg-white">
                        <div className="flex justify-center items-center h-full">
                            <p className="text-gray-500">Select a provider to view details</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}


function PatientExperienceDetailsColumn({ selectedEntity, entityType }) {
    if (!selectedEntity) {
        return (
            <div className="overflow-y-auto flex-1 bg-white">
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">Select a provider to view details</p>
                </div>
            </div>
        );
    }

    const {
        full_name,
        document_count,
        aidet_score,
        patient_experience_score,
        average_acknowledged_patient,
        average_introduced_themselves,
        average_provided_duration,
        average_provided_explanation,
        average_thanked_patient,
        average_asked_open_ended_questions,
        average_asked_patient_if_questions,
        average_asked_how_else_can_be_helped,
        average_asked_patient_opinion,
        average_managed_up_care_team,
        average_used_whiteboard,
        average_reflectively_listened,
    } = selectedEntity;

    return (
        <div className="overflow-y-auto flex-1 bg-white">
            <h1 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                {full_name} Patient Experience Analysis
            </h1>
            <div className="p-4">
                <div className="my-8 grid grid-cols-2 gap-8">
                    {/* AIDET Score */}
                    <div className="text-center">
                        <h2 className="mb-2 text-2xl font-semibold text-gray-700">AIDET Score</h2>
                        <div className="text-6xl font-bold text-indigo-600">
                            {aidet_score.toFixed(2)}
                        </div>
                        <p className="mt-2 text-gray-500">Communication Framework</p>
                    </div>

                    {/* Patient Satisfaction Score */}
                    <div className="text-center">
                        <h2 className="mb-2 text-2xl font-semibold text-gray-700">Patient Satisfaction Score</h2>
                        <div className="text-6xl font-bold text-green-500">
                            {patient_experience_score.toFixed(2)}
                        </div>
                        <p className="mt-2 text-gray-500">Based on {document_count} documents</p>
                    </div>
                </div>

                <h3 className="py-4 mt-8 mb-2 text-xl font-semibold text-gray-700">
                    Patient Experience Score Breakdown
                </h3>

                <dl className="grid grid-cols-2 gap-x-4 gap-y-8 pt-8 mb-8 border-t border-gray-300 sm:grid-cols-3 md:grid-cols-4">
                    {[
                        {
                            label: "Acknowledged Patient",
                            value: `${(average_acknowledged_patient * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Introduced Themselves",
                            value: `${(average_introduced_themselves * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Provided Duration",
                            value: `${(average_provided_duration * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Provided Explanation",
                            value: `${(average_provided_explanation * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Thanked Patient",
                            value: `${(average_thanked_patient * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked Open Ended Questions",
                            value: `${(average_asked_open_ended_questions * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked If Questions",
                            value: `${(average_asked_patient_if_questions * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked How Else Can Be Helped",
                            value: `${(average_asked_how_else_can_be_helped * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked Patient Opinion",
                            value: `${(average_asked_patient_opinion * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Managed Up Care Team",
                            value: `${(average_managed_up_care_team * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Used Whiteboard",
                            value: `${(average_used_whiteboard * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Reflectively Listened",
                            value: `${(average_reflectively_listened * 100).toFixed(0)}%`,
                        },
                    ].map((item) => (
                        <div
                            key={item.label}
                            className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.label}</dt>
                            <dd className="mt-1 text-2xl font-semibold text-indigo-600">{item.value}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
}



