// api/riskScoreApi.js  
import { protectedResources } from '../authConfig';

export const getRiskScores = async (instance, accounts) => {
    try {
        if (!accounts || accounts.length === 0) {
            throw new Error('No accounts found');
        }

        const request = {
            scopes: protectedResources.apiFetchRiskScores.scopes,
            account: accounts[0]
        };

        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };

        const fetchResponse = await fetch(protectedResources.apiFetchRiskScores.endpoint, requestOptions);
        const data = await fetchResponse.json();
        return data;
    } catch (error) {
        console.error('Error fetching risk scores:', error);
        throw error;
    }
};  