// GlobalKPIs.jsx

import React, { useState, useEffect } from 'react';
import TimeSelector from './TimeSelector';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../authConfig';
import {
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

function GlobalKPIs({ isOpen, onClose }) {
    const { instance, accounts } = useMsal();
    const [selectedRange, setSelectedRange] = useState('Last Year');
    const [granularity, setGranularity] = useState('weekly');
    const [smoothing, setSmoothing] = useState(true);
    const [kpiData, setKpiData] = useState({
        activeUsers: null,
        carepointUsers: null,
        encounterVolume: null,
        numberOfFacilities: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('activeUsers');

    const [showTotal, setShowTotal] = useState(true);
    const [showCarePoint, setShowCarePoint] = useState(false);
    const [showNonCarePoint, setShowNonCarePoint] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    // Fetch data when selectedRange changes
    useEffect(() => {
        if (isOpen) {
            fetchAllKPIData();
        }
    }, [selectedRange, granularity, smoothing, isOpen]);

    const fetchAllKPIData = async () => {
        setIsLoading(true);
        await Promise.all([
            fetchActiveUsers(),
            fetchEncounterVolume(),
        ]);
        setIsLoading(false);
    };

    // Implement individual data fetching functions here
    const fetchActiveUsers = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiFetchActiveUsers.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const { startDate: start, endDate: end } = getDateRange();

                const response = await fetch(protectedResources.apiFetchActiveUsers.endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        start_date: start ? new Date(start).toISOString() : null,
                        end_date: end ? new Date(end).toISOString() : null,
                        granularity: granularity,
                        // Include smoothing if supported
                        // smoothing: smoothing,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch active users');
                }

                const data = await response.json();
                const transformedData = data.data.map(item => ({
                    period: item.period,
                    totalActiveUsers: item.total_active_users,
                    carepointActiveUsers: item.carepoint_active_users,
                    nonCarepointActiveUsers: item.non_carepoint_active_users,
                }));
                setKpiData((prevState) => ({ ...prevState, activeUsers: transformedData }));
            } catch (error) {
                console.error('Error fetching active users:', error);
            }
        }
    };


    const fetchEncounterVolume = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiFetchEncounterVolume.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const { startDate: start, endDate: end } = getDateRange();

                const response = await fetch(protectedResources.apiFetchEncounterVolume.endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        start_date: start ? new Date(start).toISOString() : null,
                        end_date: end ? new Date(end).toISOString() : null,
                        granularity: granularity,
                        smoothing: smoothing,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch encounter volume');
                }

                const data = await response.json();
                const transformedData = data.data.map(item => ({
                    period: item.period,
                    totalEncounters: item.total_encounters,
                    carepointEncounters: item.carepoint_encounters,
                    nonCarepointEncounters: item.non_carepoint_encounters,
                }));
                setKpiData((prevState) => ({ ...prevState, encounterVolume: transformedData }));
            } catch (error) {
                console.error('Error fetching encounter volume:', error);
            }
        }
    };

    // Helper function to get date range
    const getDateRange = () => {
        const now = new Date();
        switch (selectedRange) {
            case 'Last Week':
                return {
                    startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
                    endDate: now,
                };
            case 'Last Month':
                return {
                    startDate: new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
                    endDate: now,
                };
            case 'Last Year':
                return {
                    startDate: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
                    endDate: now,
                };
            case 'Custom':
                return {
                    startDate: startDate || null,
                    endDate: endDate || null,
                };
            default:
                return {
                    startDate: null,
                    endDate: null,
                };
        }
    };

    const exportToPDF = () => {
        // Implement PDF export functionality here
    };

    return (
        <div className={`fixed inset-0 z-50 ${isOpen ? '' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen bg-gray-800 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-xl w-full max-w-7xl">
                    <div className="flex justify-between items-center p-4 border-b">
                        <h2 className="text-xl font-semibold">Global KPIs</h2>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            Close
                        </button>
                    </div>
                    <div className="p-4">
                        <div className="flex justify-between items-center">
                            <TimeSelector
                                selectedRange={selectedRange}
                                onChange={setSelectedRange}
                                granularity={granularity}
                                onGranularityChange={setGranularity}
                                smoothing={smoothing}
                                onSmoothingChange={setSmoothing}
                                startDate={startDate}
                                endDate={endDate}
                                onStartDateChange={setStartDate}
                                onEndDateChange={setEndDate}
                            />
                            <button
                                onClick={exportToPDF}
                                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                            >
                                Export to PDF
                            </button>
                        </div>

                        <div className="border-b border-gray-200 mt-4">
                            <nav className="-mb-px flex space-x-8">
                                <button
                                    onClick={() => setActiveTab('activeUsers')}
                                    className={`${activeTab === 'activeUsers'
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Active Users
                                </button>
                                <button
                                    onClick={() => setActiveTab('encounters')}
                                    className={`${activeTab === 'encounters'
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Encounter Volume
                                </button>
                            </nav>
                        </div>

                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                            </div>
                        ) : (
                            <div id="kpi-content" className="mt-4">
                                {activeTab === 'activeUsers' && (
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold mb-2">Active Users</h3>

                                        {/* Toggle Controls */}
                                        <div className="flex items-center space-x-4 mb-2">
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showTotal}
                                                    onChange={(e) => setShowTotal(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                Total
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showCarePoint}
                                                    onChange={(e) => setShowCarePoint(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                CarePoint
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showNonCarePoint}
                                                    onChange={(e) => setShowNonCarePoint(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                Non-CarePoint
                                            </label>
                                        </div>

                                        {kpiData.activeUsers ? (
                                            <ResponsiveContainer width="100%" height={300}>
                                                <LineChart data={kpiData.activeUsers}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis
                                                        dataKey="period"
                                                        tickFormatter={(value) => new Date(value).toLocaleDateString()}
                                                    />
                                                    <YAxis />
                                                    <Tooltip
                                                        labelFormatter={(value) => new Date(value).toLocaleDateString()}
                                                    />
                                                    <Legend />
                                                    {showTotal && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="totalActiveUsers"
                                                            stroke="#8884d8"
                                                            strokeWidth={2}
                                                            name="Total Active Users"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                    {showCarePoint && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="carepointActiveUsers"
                                                            stroke="#82ca9d"
                                                            strokeWidth={2}
                                                            name="CarePoint Active Users"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                    {showNonCarePoint && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="nonCarepointActiveUsers"
                                                            stroke="#ff7300"
                                                            strokeWidth={2}
                                                            name="Non-CarePoint Active Users"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                )}

                                {activeTab === 'encounters' && (
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold mb-2">Encounter Volume</h3>

                                        {/* Toggle Controls */}
                                        <div className="flex items-center space-x-4 mb-2">
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showTotal}
                                                    onChange={(e) => setShowTotal(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                Total
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showCarePoint}
                                                    onChange={(e) => setShowCarePoint(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                CarePoint
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={showNonCarePoint}
                                                    onChange={(e) => setShowNonCarePoint(e.target.checked)}
                                                    className="mr-2"
                                                />
                                                Non-CarePoint
                                            </label>
                                        </div>

                                        {kpiData.encounterVolume ? (
                                            <ResponsiveContainer width="100%" height={300}>
                                                <LineChart data={kpiData.encounterVolume}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis
                                                        dataKey="period"
                                                        tickFormatter={(value) => new Date(value).toLocaleDateString()}
                                                    />
                                                    <YAxis />
                                                    <Tooltip
                                                        labelFormatter={(value) => new Date(value).toLocaleDateString()}
                                                    />
                                                    <Legend />
                                                    {showTotal && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="totalEncounters"
                                                            stroke="#8884d8"
                                                            strokeWidth={2}
                                                            name="Total Encounters"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                    {showCarePoint && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="carepointEncounters"
                                                            stroke="#82ca9d"
                                                            strokeWidth={2}
                                                            name="CarePoint Encounters"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                    {showNonCarePoint && (
                                                        <Line
                                                            type="monotone"
                                                            dataKey="nonCarepointEncounters"
                                                            stroke="#ff7300"
                                                            strokeWidth={2}
                                                            name="Non-CarePoint Encounters"
                                                            isAnimationActive={false}
                                                        />
                                                    )}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GlobalKPIs;
