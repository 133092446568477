import React from 'react';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MdmToggles({
  includeSuggestionsInBrackets,
  setIncludeSuggestionsInBrackets,
  onlyIncludeVerbalized,
  setOnlyIncludeVerbalized,
}) {
  return (
    <div className="mb-6">
      <Switch.Group>
        <div className="flex items-center justify-between">
          <Switch.Label className="mr-4">Include suggestions in brackets</Switch.Label>
          <Switch
            checked={includeSuggestionsInBrackets}
            onChange={setIncludeSuggestionsInBrackets}
            className={classNames(
              includeSuggestionsInBrackets ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 items-center rounded-full transition-colors'
            )}
          >
            <span
              className={classNames(
                includeSuggestionsInBrackets ? 'translate-x-6' : 'translate-x-1',
                'inline-block h-4 w-4 transform bg-white rounded-full transition-transform'
              )}
            />
          </Switch>
        </div>
      </Switch.Group>

      <Switch.Group className="mt-4">
        <div className="flex items-center justify-between">
          <Switch.Label className="mr-4">Only include verbalized content</Switch.Label>
          <Switch
            checked={onlyIncludeVerbalized}
            onChange={setOnlyIncludeVerbalized}
            className={classNames(
              onlyIncludeVerbalized ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 items-center rounded-full transition-colors'
            )}
          >
            <span
              className={classNames(
                onlyIncludeVerbalized ? 'translate-x-6' : 'translate-x-1',
                'inline-block h-4 w-4 transform bg-white rounded-full transition-transform'
              )}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  );
} 