// components/VisibilitySelect.jsx  

import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import VisibilityIcon from './VisibilityIcon';
import {
    CheckIcon,
    ChevronUpDownIcon,
} from '@heroicons/react/20/solid';

const visibilityOptions = [
    { value: 'do_not_auto_show', label: 'Do Not Auto Show' },
    { value: 'auto_show', label: 'Auto Show' },
    { value: 'force_resolve', label: 'Force Resolve' },
];

const VisibilitySelect = ({ visibility, setVisibility }) => {
    const selectedVisibility = visibilityOptions.find((opt) => opt.value === visibility) || {
        value: '',
        label: 'Select visibility',
    };

    return (
        <Listbox value={visibility} onChange={setVisibility}>
            <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                        {visibility && <VisibilityIcon visibility={visibility} />}
                        <span className="ml-2 block truncate">
                            {selectedVisibility.label}
                        </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {visibilityOptions.map((option) => (
                            <Listbox.Option
                                key={option.value}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    }`
                                }
                                value={option.value}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <div className="flex items-center">
                                            <VisibilityIcon visibility={option.value} />
                                            <span
                                                className={`ml-2 block truncate ${selected ? 'font-semibold' : 'font-normal'
                                                    }`}
                                            >
                                                {option.label}
                                            </span>
                                        </div>
                                        {selected ? (
                                            <span
                                                className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-indigo-600'
                                                    }`}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default VisibilitySelect;  