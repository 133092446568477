import { protectedResources } from '../authConfig';

// Utility function to safely convert any value to a string
const safeString = (value) => {
  return value ? String(value) : '';
};

export const getAvailableGroups = async (instance, accounts) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const request = {
      scopes: protectedResources.apiGetAvailableGroups.scopes,
      account: accounts[0],
    };

    const response = await instance.acquireTokenSilent(request);
    const bearer = `Bearer ${response.accessToken}`;

    const options = {
      method: 'GET',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
    };

    const resp = await fetch(protectedResources.apiGetAvailableGroups.endpoint, options);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.error('getAvailableGroups error:', error);
    throw error;
  }
};

export const getGroupMdmTemplate = async (instance, accounts, selectedGroup) => {
  try {
    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    if (!selectedGroup) {
      return '';
    }

    // Ensure groupId is always an integer
    const groupId = parseInt(
      typeof selectedGroup === 'object' ? safeString(selectedGroup.group_id) : safeString(selectedGroup), 
      10
    );

    if (!Number.isInteger(groupId)) {
      throw new Error('Invalid group ID: must be an integer');
    }

    const request = {
      scopes: protectedResources.apiGetGroupMdmTemplate.scopes,
      account: accounts[0],
    };

    const response = await instance.acquireTokenSilent(request);
    const bearer = `Bearer ${response.accessToken}`;

    const options = {
      method: 'GET',
      headers: {
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
    };

    // Ensure proper URL formatting with a forward slash
    const endpoint = `${protectedResources.apiGetGroupMdmTemplate.endpoint}${groupId}/mdm_template/`;
    const resp = await fetch(endpoint, options);
    
    if (!resp.ok) {
      throw new Error(`HTTP error! status: ${resp.status}`);
    }

    const data = await resp.json();
    return data.template_content;
  } catch (error) {
    console.error('getGroupMdmTemplate error:', error);
    throw error;
  }
};