
import React, { useEffect } from 'react';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'

import '../styles/TipTap.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faCopy, faList, faListOl } from '@fortawesome/free-solid-svg-icons';



const TipTapEditor = ({ mdm, updateMDM, saveDocument }) => {

    const editor = useEditor({
        extensions: [
            StarterKit,
            BulletList,
            OrderedList,
            // Include any other extensions
        ],
        content: mdm || '<p></p>',
        onUpdate: ({ editor }) => {
            const html = editor.getHTML();
            updateMDM(html);
        },
    });

    const handleCopy = () => {
        if (editor) {
            const { state } = editor;
            const { from, to } = state.selection;
            const selectedText = state.doc.textBetween(from, to, '\n');

            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(selectedText).then(
                    () => {
                        console.log('Text copied to clipboard');
                    },
                    (err) => {
                        console.error('Could not copy text: ', err);
                    }
                );
            } else {
                // Fallback method using a temporary textarea
                const textarea = document.createElement('textarea');
                textarea.value = selectedText;
                textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.focus();
                textarea.select();

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        console.log('Text copied to clipboard');
                    } else {
                        console.error('Failed to copy text');
                    }
                } catch (err) {
                    console.error('Could not copy text: ', err);
                }

                document.body.removeChild(textarea);
            }
        }
    };



    // Save the document when the editor loses focus
    useEffect(() => {
        if (editor) {
            const handleBlur = () => {
                saveDocument('mdm', editor.getHTML());
            };

            editor.on('blur', handleBlur);

            return () => {
                editor.off('blur', handleBlur);
            };
        }
    }, [editor, saveDocument]);

    useEffect(() => {
        if (editor && mdm !== editor.getHTML()) {
            editor.commands.setContent(mdm);
        }
    }, [mdm, editor]);

    return (
        <div id="tiptap-editor-container">
            <EditorContent editor={editor} />
            {editor && (
                <BubbleMenu
                    editor={editor}
                    className="tiptap-bubble-menu"
                    tippyOptions={{
                        duration: 100,
                        placement: 'top',
                    }}
                    shouldShow={({ editor }) => {
                        const { from, to } = editor.state.selection;
                        return to > from; // Show when text is selected
                    }}
                >
                    <button
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        className={editor.isActive('bold') ? 'is-active' : ''}
                        aria-label="Bold"
                        title="Bold"
                    >
                        <FontAwesomeIcon icon={faBold} />
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        className={editor.isActive('italic') ? 'is-active' : ''}
                        aria-label="Italic"
                        title="Italic"
                    >
                        <FontAwesomeIcon icon={faItalic} />
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={editor.isActive('bulletList') ? 'is-active' : ''}
                        aria-label="Bullet List"
                        title="Bullet List"
                    >
                        <FontAwesomeIcon icon={faList} />
                    </button>
                    <button
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={editor.isActive('orderedList') ? 'is-active' : ''}
                        aria-label="Numbered List"
                        title="Numbered List"
                    >
                        <FontAwesomeIcon icon={faListOl} />
                    </button>
                    {/* Copy button */}
                    <button onClick={handleCopy} aria-label="Copy" title="Copy">
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </BubbleMenu>
            )}
        </div>
    );
};

export default TipTapEditor;


