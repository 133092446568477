import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/NoteDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH, faCalendarAlt, faComments, faClipboardList, faSpinner, faSync, faBell, faUserPlus, faArchive, faFileAlt, faLightbulb, faXmark, faTrash, faPlus, faExclamationTriangle, faHourglass } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

import { Fieldset, Legend } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';




function ERDashboard() {
    const { instance, accounts, inProgress } = useMsal();
    const [documents, setDocuments] = useState([]);
    const location = useLocation();
    const providedDate = location.state?.providedDate;
    const [actorInfo, setActorInfo] = useState(location.state?.actorInfo || {});
    const [selectedDate, setSelectedDate] = useState(providedDate ? new Date(providedDate) : new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const navigate = useNavigate();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
    const [patients, setPatients] = useState([{ name: '', complaint: '', age: '', gender: '', nameError: false, complaintError: false }]);
    const MAX_PATIENTS = 5;
    const [formError, setFormError] = useState(false);
    const [isGeneratingMDMs, setIsGeneratingMDMs] = useState(false);
    const [showArchivedNotes, setShowArchivedNotes] = useState(false);
    const [errorDocument, setErrorDocument] = useState(null);
    const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);


    // Function to handle header click
    const handleSort = (field) => {
        if (sortField === field) {
            // Toggle sort direction if the same field is clicked again
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set the new sort field and reset to ascending order
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedDocuments = [...documents].sort((a, b) => {
        let fieldA, fieldB;
        
        if (sortField === 'complaint') {
            fieldA = a.procedure_description.toLowerCase();
            fieldB = b.procedure_description.toLowerCase();
        } else if (sortField === 'time') {
            fieldA = new Date(a.created_at);
            fieldB = new Date(b.created_at);
        }

        if (fieldA < fieldB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (fieldA > fieldB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const [selectedDateRange, setSelectedDateRange] = useState(() => {
        // Start date at the beginning of today in UTC
        console.log("Provided date:", providedDate);
        console.log("Selected date:", selectedDate);
        const startUtc = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));

        // End date at the end of tomorrow in UTC
        const tomorrow = new Date(startUtc);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const endUtc = new Date(Date.UTC(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 23, 59, 59, 999));

        // Calculate the user's local time zone offset from UTC in hours
        const localTimeZoneOffsetHours = new Date().getTimezoneOffset() / 60;

        // Adjust the UTC time range by the local time zone offset
        startUtc.setHours(startUtc.getHours() + localTimeZoneOffsetHours);
        endUtc.setHours(endUtc.getHours() + localTimeZoneOffsetHours);

        return { start: startUtc, end: endUtc };
    });

    const [selectedDocumentsForMDM, setSelectedDocumentsForMDM] = useState([]);
    const [selectedForArchive, setSelectedForArchive] = useState([]);
    const [isArchiving, setIsArchiving] = useState(false);

    const handleDocumentSelectionForMDM = (docId) => {
        setSelectedDocumentsForMDM(prev =>
            prev.includes(docId)
                ? prev.filter(id => id !== docId)
                : [...prev, docId]
        );
    };

    const handleDocumentSelectionForArchive = (docId) => {
        setSelectedForArchive(prev =>
            prev.includes(docId)
                ? prev.filter(id => id !== docId)
                : [...prev, docId]
        );
    };

    const handleGenerateMDMs = async () => {
        if (selectedDocumentsForMDM.length === 0) return;

        setIsGeneratingMDMs(true);
        try {
            const request = {
                scopes: protectedResources.apiCalculateMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const generateMDMForDocument = async (docId) => {
                // Update the document's mdm_status to "IN_PROGRESS"
                setDocuments(prevDocuments => prevDocuments.map(doc =>
                    doc.document_id === docId
                        ? { ...doc, mdm_status: "IN_PROGRESS" }
                        : doc
                ));
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ "document_id": docId })
                };

                const fetchResponse = await fetch(protectedResources.apiCalculateMDM.endpoint, requestOptions);
                const result = await fetchResponse.json();

                // Update the specific document in the state
                setDocuments(prevDocuments => prevDocuments.map(doc =>
                    doc.document_id === docId
                        ? { ...doc, mdm: result.mdm_content, mdm_coding: result.mdm_coding, mdm_status: "GENERATED" }
                        : doc
                ));

            };

            // Process all selected documents in parallel
            await Promise.all(selectedDocumentsForMDM.map(generateMDMForDocument));

            // Clear selection after all MDMs have been generated
            setSelectedDocumentsForMDM([]);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error generating MDMs:', error);
            // Handle any errors here, maybe show an error message to the user
        }
        setIsGeneratingMDMs(false);
    };

    const handleBulkArchive = async () => {
        if (selectedForArchive.length === 0) return;

        setIsArchiving(true);
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const archiveDocument = async (docId) => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "document_id": docId,
                        "field_to_update": "document_status",
                        "new_value": "FINALIZED"
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
                if (fetchResponse.ok) {
                    return docId; // Return the successfully archived document ID
                } else {
                    throw new Error(`Failed to archive document ${docId}`);
                }
            };

            // Process all selected documents in parallel
            const archivedDocIds = await Promise.all(selectedForArchive.map(archiveDocument));

            // Update the documents state
            setDocuments(prevDocuments => {
                if (showArchivedNotes) {
                    // If showing archived notes, update the status of archived documents
                    return prevDocuments.map(doc =>
                        archivedDocIds.includes(doc.document_id)
                            ? { ...doc, document_status: "FINALIZED" }
                            : doc
                    );
                } else {
                    // If not showing archived notes, remove the archived documents
                    return prevDocuments.filter(doc => !archivedDocIds.includes(doc.document_id));
                }
            });

            // Clear selection after all documents have been archived
            setSelectedForArchive([]);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error archiving documents:', error);
            // Handle any errors here, maybe show an error message to the user
        }
        setIsArchiving(false);
    };

    const handleRetryGeneration = async () => {
        if (!errorDocument) return;

        // Close the modal immediately
        setIsRetryModalOpen(false);


        // Wait for 500ms, then fetch all reports
        setTimeout(() => {
            fetchAllReports();
        }, 500);

        try {
            const request = {
                scopes: protectedResources.apiRetryGeneratingDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: parseInt(errorDocument.document_id, 10),
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                })
            };

            const fetchResponse = await fetch(protectedResources.apiRetryGeneratingDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.success) {
                // Update the document status in the local state
                // setDocuments(prevDocuments => prevDocuments.map(doc =>
                //     doc.document_id === errorDocument.document_id
                //         ? { ...doc, document_status: "ENCOUNTER_COMPLETE" }
                //         : doc
                // ));
                setErrorDocument(null);

            } else {
                throw new Error(data.message || 'Failed to retry document generation');
            }
        } catch (error) {
            //console.error('Error retrying document generation:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleDateChange = (date) => {
        // Set the UTC time range as a full day from midnight to midnight
        const startUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        const endUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));

        // Calculate the user's local time zone offset from UTC in hours
        const localTimeZoneOffsetHours = new Date().getTimezoneOffset() / 60;

        // Adjust the UTC time range by the local time zone offset
        startUtc.setHours(startUtc.getHours() + localTimeZoneOffsetHours);
        endUtc.setHours(endUtc.getHours() + localTimeZoneOffsetHours);

        console.log("Adjusted UTC Start date being sent to backend:", startUtc.toISOString());
        console.log("Adjusted UTC End date being sent to backend:", endUtc.toISOString());
        setIsDatePickerOpen(false);

        setSelectedDateRange({ start: startUtc, end: endUtc });
        setSelectedDate(date); // Keep the original date for DatePicker

    };

    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const fetchAllReports = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetDocumentsForDay.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const now = new Date();
                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                let startDate = new Date(selectedDateRange.start);
                let endDate = new Date(selectedDateRange.end);

                // Check if selected date is today, If so, set start date to 12 hours ago from now
                if (startDate.toDateString() === today.toDateString()) {
                    startDate.setDate(startDate.getDate() - 1);
                }

                const formattedStartDate = startDate.toISOString();
                const formattedEndDate = endDate.toISOString();
                console.log("UTC Start date being sent to backend:", formattedStartDate);
                console.log("UTC End date being sent to backend:", formattedEndDate);

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        start_date: formattedStartDate,
                        end_date: formattedEndDate,
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiGetDocumentsForDay.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.documents && data.documents.length > 0) {
                    // Filter documents based on showArchivedNotes state
                    const filteredDocuments = showArchivedNotes
                        ? data.documents
                        : data.documents.filter(doc => doc.document_status !== "FINALIZED");
                    const sortedDocuments = filteredDocuments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setDocuments(sortedDocuments);
                } else {
                    setDocuments([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        }
        setIsLoading(false);
    }, [instance, accounts, selectedDateRange, handleTokenExpiration, showArchivedNotes]);

    const fetchUserInfo = useCallback(async () => {
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetMyInfo.scopes,
                    account: accounts[0],
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };

                const fetchResponse = await fetch(
                    protectedResources.apiGetMyInfo.endpoint,
                    requestOptions
                );
                const actorInfo = await fetchResponse.json();
                setActorInfo(actorInfo);
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        } else {
        }
    }, []);

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        fetchAllReports();
        fetchUserInfo();
    }, [fetchAllReports, selectedDate, inProgress]);



    const handleDocumentClick = (doc) => {
        if (doc.document_status === "ERROR") {
            setErrorDocument(doc);
            setIsRetryModalOpen(true);
        } else {
            navigate('/view-er-patient', { state: { document: doc, providedDate: selectedDate, actorInfo: actorInfo } });
        }
    };

    const handleMDMIconClick = (doc, event) => {
        event.stopPropagation(); // Prevent the click from bubbling up to the parent
        navigate('/view-er-patient', {
            state: {
                document: doc,
                providedDate: selectedDate,
                showMDM: true,
                actorInfo: actorInfo
            }
        });
    };

    const formatTime = (utcString, selectedDate) => {
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        const formattedTime = formatter.format(utcDate);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        // Check if the selected date is today
        const isSelectedDateToday = selectedDate.toDateString() === today.toDateString();

        if (isSelectedDateToday && utcDate >= yesterday && utcDate < today) {
            return `${formattedTime} (Yesterday)`;
        }

        return formattedTime;
    };

    const addPatientField = () => {
        if (patients.length < MAX_PATIENTS) {
            setPatients([...patients, { name: '', complaint: '', age: '', gender: '', nameError: false, complaintError: false }]);
        }
    };

    const updatePatient = (index, field, value) => {
        const updatedPatients = [...patients];
        updatedPatients[index] = { ...updatedPatients[index], [field]: value, [`${field}Error`]: false };
        setPatients(updatedPatients);
        setFormError(false);
    };

    const removePatient = (index) => {
        const updatedPatients = patients.filter((_, i) => i !== index);
        setPatients(updatedPatients);
    };

    const validateForm = () => {
        let isValid = true;
        const updatedPatients = patients.map(patient => ({
            ...patient,
            nameError: patient.name.trim() === '',
            complaintError: patient.complaint.trim() === ''
        }));

        setPatients(updatedPatients);

        if (updatedPatients.some(patient => patient.nameError || patient.complaintError)) {
            isValid = false;
            setFormError(true);
        }

        return isValid;
    };

    const handleAddPatients = async () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        setIsAddPatientModalOpen(false);
        const createdDocumentIds = [];

        try {
            const request = {
                scopes: protectedResources.apiCreatePreEncounterDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            for (const patient of patients) {
                const payload = {
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    patient_name: patient.name,
                    procedure_description: patient.complaint,
                };

                if (patient.gender) {
                    payload.sex = patient.gender.toUpperCase();
                }

                if (patient.age) {
                    const age = parseInt(patient.age);
                    if (age >= 0 && age <= 120) {
                        payload.patient_age = patient.age;
                    }
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(payload)
                };

                const fetchResponse = await fetch(protectedResources.apiCreatePreEncounterDocument.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.document_id) {
                    createdDocumentIds.push(data.document_id);
                } else {
                    throw new Error('Failed to create document');
                }
            }

            console.log('Created document IDs:', createdDocumentIds);
            // TODO: Handle successful creation (e.g., show success message, update UI)

            setPatients([{ name: '', complaint: '', age: '', gender: '', nameError: false, complaintError: false }]);
            // Optionally, refresh the documents list
            fetchAllReports();
        } catch (error) {
            console.error('Error creating patients:', error);
            // TODO: Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard-container">
            <div className="w-full py-4 mb-1 rounded-md">
                <Fieldset className="flex flex-row justify-between items-center">
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faClipboardList} className="text-indigo-900 text-3xl" />
                        <Fieldset>
                            <h1 className="text-2xl font-bold text-indigo-900">Chart Dashboard</h1>
                            <p className="text-md text-indigo-900">Track and manage your documentation efficiently.</p>
                        </Fieldset>
                        <button
                            onClick={fetchAllReports}
                            className="bg-indigo-900 text-white p-2 rounded-full hover:bg-indigo-800 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                            aria-label="Refresh documents"
                        >
                            <FontAwesomeIcon icon={faSync} className="text-xl" />
                        </button>
                        <Fieldset className="flex items-center space-x-2">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={showArchivedNotes}
                                    onChange={() => setShowArchivedNotes(!showArchivedNotes)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <Text className="text-sm text-indigo-900">Show Archived Notes</Text>
                        </Fieldset>
                    </Fieldset>
                    {actorInfo &&
                        actorInfo.sign_up_wave === 'D2C' &&
                        (actorInfo.subscription_type === 'FREE_TRIAL' || actorInfo.subscription_type === 'NO_SUBSCRIPTION') ? (
                        <div>
                            <button
                                type="button"
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                                onClick={() => navigate("/subscribe", { state: { actorInfo: actorInfo } })}
                            >
                                Upgrade your Cleo Health Subscription
                                <FontAwesomeIcon icon={faBell} className="ml-2 mt-1" />
                            </button>
                        </div>

                    ) : null}
                    <Fieldset className="flex items-center space-x-4">
                        <button
                            onClick={() => setIsAddPatientModalOpen(true)}
                            className="bg-indigo-900 text-white px-4 py-2 rounded-md flex items-center"
                        >
                            <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                            Add Patients
                        </button>
                        <Fieldset
                            className="flex flex-col justify-center items-center py-3 rounded-lg bg-white shadow w-1/10 cursor-pointer"
                            onClick={() => setIsDatePickerOpen(true)}
                        >
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-indigo-900 text-md mb-2" />
                            Select Date
                            <DatePicker
                                id="datePicker"
                                selected={selectedDate}
                                onChange={handleDateChange}
                                open={isDatePickerOpen}
                                onSelect={() => setIsDatePickerOpen(false)} // Close on date select
                                onClickOutside={() => setIsDatePickerOpen(false)} // Close on outside click
                                className="w-full text-sm text-center font-semibold text-gray-700 outline-none cursor-pointer border-none"
                            />
                        </Fieldset>
                    </Fieldset>



                </Fieldset>

            </div>

            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading charts...</div>
                </div>
            ) : (
                documents.length > 0 ? (
                    <Fieldset className="w-full bg-white rounded-lg">
                        <Fieldset className="bg-gray-100 rounded-lg shadow-lg mb-4 hover:bg-gray-100 transition duration-300 ease-in-out grid grid-cols-10 items-center">
                            <Text className="col-span-6 font-bold cursor-pointer py-2" onClick={() => handleSort('complaint')}>
                                Patient Info {sortField === 'complaint' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Text>
                            <Text className="col-span-1 font-bold text-center cursor-pointer py-2" onClick={() => handleSort('time')}>
                                Encounter Time {sortField === 'time' && (sortDirection === 'asc' ? '↑' : '↓')}
                            </Text>
                            <Text className="col-span-1 font-bold text-center cursor-default py-2">Status</Text>
                            <div className="col-span-1 flex items-center justify-center py-2">
                                <button
                                    onClick={handleGenerateMDMs}
                                    disabled={selectedDocumentsForMDM.length === 0 || isGeneratingMDMs || isArchiving}
                                    className={`px-1 py-1 rounded-md text-sm ${selectedDocumentsForMDM.length > 0 && !isGeneratingMDMs && !isArchiving
                                        ? 'bg-indigo-900 text-white hover:bg-indigo-800 cursor-pointer'
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                        } transition duration-200`}
                                >
                                    {isGeneratingMDMs ? 'Generating...' : `Generate MDMs ${selectedDocumentsForMDM.length > 0 ? `(${selectedDocumentsForMDM.length})` : ''}`}
                                </button>
                            </div>
                            <div className="col-span-1 flex items-center justify-center">
                                <button
                                    onClick={handleBulkArchive}
                                    disabled={selectedForArchive.length === 0 || isArchiving || isGeneratingMDMs}
                                    className={`px-1 py-1 rounded-md text-sm ${selectedForArchive.length > 0 && !isArchiving && !isGeneratingMDMs
                                        ? 'bg-indigo-900 text-white hover:bg-indigo-800 cursor-pointer'
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                        } transition duration-200`}
                                >
                                    {isArchiving ? 'Archiving...' : `Archive ${selectedForArchive.length > 0 ? `(${selectedForArchive.length})` : ''}`}
                                </button>
                            </div>
                        </Fieldset>
                        {sortedDocuments.map((doc, index) => (
                            <Fieldset
                                key={index}
                                className="bg-white rounded-lg shadow-lg mb-2 grid grid-cols-10 items-center"
                            >
                                <Fieldset className='col-span-6 p-4 cursor-pointer' onClick={() => handleDocumentClick(doc)}>
                                    <Fieldset className="flex flex-row items-center">
                                        {doc.document_status === "FINALIZED" && (
                                            <Fieldset className="col-span-1 flex justify-center">
                                                <FontAwesomeIcon icon={faArchive} className="text-gray-500 mr-2" title="Archived" />
                                            </Fieldset>
                                        )}
                                        <Fieldset className="flex flex-col">
                                            <Text className="font-bold text-black">{doc.patient_name}</Text>
                                            <Fieldset className="flex flex-row items-center">
                                                <FontAwesomeIcon icon={faComments} className="mr-1 mt-1 text-indigo-800 text-sm" />
                                                <Text className="text-black">{doc.procedure_description}</Text>
                                            </Fieldset>
                                        </Fieldset>
                                    </Fieldset>
                                </Fieldset>
                                <Text className="col-span-1 text-center text-black items-center cursor-pointer p-4" onClick={() => handleDocumentClick(doc)}>{formatTime(doc.created_at, selectedDate)}</Text>
                                <Fieldset className="col-span-1 flex justify-center items-center cursor-pointer p-4" onClick={() => handleDocumentClick(doc)}>
                                    {doc.document_status === "ENCOUNTER_COMPLETE" ? (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                            <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                        </Fieldset>
                                    ) : doc.document_status === "IN_PROGRESS" ? (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 rounded-lg text-xl">
                                            <FontAwesomeIcon
                                                icon={faHourglass}
                                                className="text-gray-500"
                                                style={{ opacity: 0.8 }}
                                            />
                                        </Fieldset>
                                    ) : doc.document_status === "ERROR" ? (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 bg-red-200 rounded-lg text-xl">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-600" />
                                        </Fieldset>
                                    ) : (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 bg-gray-200 rounded-lg text-xl">
                                            <FontAwesomeIcon icon={faEllipsisH} className="text-gray-500" />
                                        </Fieldset>
                                    )}
                                </Fieldset>
                                <Fieldset
                                    className="col-span-1 flex justify-center items-center p-4 border-l-2 "
                                    onClick={(e) => e.stopPropagation()}  // Stop propagation for the entire column
                                >
                                    {doc.document_status === "ENCOUNTER_COMPLETE" && doc.mdm_status === "NOT_GENERATED" ? (
                                        <div className="p-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedDocumentsForMDM.includes(doc.document_id)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleDocumentSelectionForMDM(doc.document_id);
                                                }}
                                                className="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                                            />
                                        </div>
                                    ) : doc.document_status === "ENCOUNTER_COMPLETE" && doc.mdm_status === "GENERATED" ? (
                                        <div
                                            className="p-2 cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleMDMIconClick(doc, e);
                                            }}
                                        >
                                            <Fieldset
                                                className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-2xl"
                                            >
                                                <FontAwesomeIcon icon={faFileAlt} className="text-green-600" />
                                            </Fieldset>
                                        </div>
                                    ) : doc.document_status === "ENCOUNTER_COMPLETE" && doc.mdm_status === "IN_PROGRESS" ? (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 rounded-lg text-xl">
                                            <FontAwesomeIcon
                                                icon={faHourglass}
                                                className="text-gray-500"
                                                style={{ opacity: 0.8 }}
                                            />
                                        </Fieldset>
                                    ) : doc.document_status === "ENCOUNTER_COMPLETE" && doc.mdm_status === "ERROR" ? (
                                        <Fieldset className="flex justify-center items-center w-8 h-8 bg-red-200 rounded-lg text-xl">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-600" />
                                        </Fieldset>
                                    ) : (
                                        <div className="p-2">
                                            {/* No content */}
                                        </div>
                                    )}
                                </Fieldset>
                                <Fieldset
                                    className="col-span-1 flex justify-center items-center p-4"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {doc.document_status === "FINALIZED" ? (
                                        <div className="p-2">
                                            <FontAwesomeIcon icon={faArchive} className="text-gray-500 text-2xl" title="Archived" />
                                        </div>
                                    ) : (
                                        <div className="p-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedForArchive.includes(doc.document_id)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleDocumentSelectionForArchive(doc.document_id);
                                                }}
                                                className="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                                            />
                                        </div>
                                    )}
                                </Fieldset>
                            </Fieldset>
                        ))}
                    </Fieldset>
                ) : (
                    <Fieldset className="w-full flex flex-col items-center justify-center h-full">
                        <Legend>No Documents Available</Legend>
                        <Text className="mt-4 text-center">
                            There are no documents available for the selected date. Please select a different date or check back later.
                        </Text>
                    </Fieldset>
                )
            )}

            <div className="feedback-link">
                <a
                    data-canny-link
                    href="https://cleo-health.canny.io"
                    target="_blank"
                    className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                >
                    <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                    Request a Feature
                </a>
            </div>

            {isAddPatientModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-xl w-[32rem] relative">
                        <button
                            onClick={() => setIsAddPatientModalOpen(false)}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            <FontAwesomeIcon icon={faXmark} className="text-xl" />
                        </button>
                        <h2 className="text-2xl font-bold mb-6 text-indigo-900">Add New Patients</h2>
                        {patients.map((patient, index) => (
                            <div key={index} className="mb-4 relative">
                                <input
                                    type="text"
                                    placeholder="Name or Room Number"
                                    className={`w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${patient.nameError ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    value={patient.name}
                                    onChange={(e) => updatePatient(index, 'name', e.target.value)}
                                />
                                {patient.nameError && (
                                    <p className="text-red-500 text-xs italic mb-2">Name or Room Number is required</p>
                                )}
                                <input
                                    type="text"
                                    placeholder="Chief Complaint"
                                    className={`w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${patient.complaintError ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    value={patient.complaint}
                                    onChange={(e) => updatePatient(index, 'complaint', e.target.value)}
                                />
                                {patient.complaintError && (
                                    <p className="text-red-500 text-xs italic mb-2">Chief complaint is required</p>
                                )}
                                <div className="flex space-x-2 mb-2">
                                    <input
                                        type="number"
                                        placeholder="Age (optional)"
                                        className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={patient.age}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || (value.length <= 3 && /^\d+$/.test(value))) {
                                                updatePatient(index, 'age', value);
                                            }
                                        }}
                                        min="0"
                                        max="999"
                                    />
                                    <select
                                        className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={patient.gender}
                                        onChange={(e) => updatePatient(index, 'gender', e.target.value)}
                                    >
                                        <option value="">Gender (optional)</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {patients.length > 1 && (
                                    <button
                                        onClick={() => removePatient(index)}
                                        className="absolute top-0 right-0 mt-2 mr-2 text-red-500 hover:text-red-700"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                )}
                            </div>
                        ))}
                        {patients.length < MAX_PATIENTS && (
                            <button
                                onClick={addPatientField}
                                className="w-full mb-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200 flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                Add Another Patient
                            </button>
                        )}
                        <div className="flex justify-between space-x-4">
                            <button
                                onClick={() => setIsAddPatientModalOpen(false)}
                                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddPatients}
                                className="px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition duration-200"
                            >
                                Add Patients
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Dialog open={isRetryModalOpen} onClose={() => setIsRetryModalOpen(false)} className="relative z-10">
                <DialogBackdrop
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                        >
                            <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Document Generation Error
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            There was an error generating this document. Would you like to retry?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    onClick={() => setIsRetryModalOpen(false)}
                                    className="w-full inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleRetryGeneration}
                                    className="w-full inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Retry Generation
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </div>
    );





}

export default ERDashboard;
